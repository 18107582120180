import React from 'react';
import styled from 'styled-components';
import {Container, H1, H3, Row, Col, P} from '@bootstrap-styled/v4';
import {navigate} from '@reach/router';
import {FacebookShareButton, WhatsappShareButton} from 'react-share';
import {connect} from 'react-redux';
import SizeIcon from '../icons/SizeIcon';
import HomeIcon from '../icons/HomeIcon';
import PointIcon from '../icons/PointIcon';
import LayerIcon from '../icons/LayerIcon';
import LoveIcon from '../icons/LoveIcon';
import ButtonOutline from '../ButtonOutline';
import BarsIcon from '../icons/BarsIcon';
import AvatarHeadlineSkeleton from '../skeletons/AvatarHeadlineSkeleton';
import FacebookFlatIcon from '../icons/FacebookFlatIcon';
import WppIcon from '../icons/WppIcon';
import {BASE_BUCKET_URL} from '../../constants/global';
import {addNotification} from '../../actions/notifications';
import {toggleFavorite} from '../../actions/projects';
import Api from '../../services/api';

import {money} from '../../utils/Helper';

const Wrapper = styled(Container)`
  /* padding: 1.92625rem 0; */
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 4px -1px 10px rgba(0, 0, 0, 0.1);
  height: 117px !important;
  background-color: #fff !important;
  /* padding: 1.5rem !important; */

  @media (max-width: 1199px) {
    flex-direction: column;
    height: 94% !important;
  }

  @media (max-width: 576px) {
    height: 376px !important;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 83px;
  height: 83px;
  background-color: gray;
  object-fit: contain !important;
  background: white !important;
  /* box-shadow: 3px 3px 3px #cccccc75; */
  border: 1px solid #cccccc40;

 /*
  @media (min-width: 1200px) {
    margin-left: 4rem !important ;
  } */
`;

const Info = styled(Col)``;

const Attrs = styled(Col)`
  // padding-left: 4.208125rem !important;
  flex-grow: 2 !important;

  @media (max-width: 768px) {
    margin-top: 2rem !important;
  }
`;

const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

  .wrapper {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     gap: 0.7rem;
     


    .size_project, 
    .poin_project,
    .project_use,
    .project_stratum{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px; 
    }


    .title-project_text, 
    .size_project_text, 
    .project_use_text,
    .project_price_text,
    .project_stratum_text,
    .project_poin_text {
      margin: 0;
      font-weight: 500;
    }

    
    

    .title-project_text{
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
    }

    @media(max-width: 900px){
      align-items: center; 
      justify-content: center;

      
    }
  }
`;

const Line = styled.div`
      border-left: 2px solid #d8d5d5; 
      height: 70px; 
      margin: 0 10px;
`; 

const MyInfo = styled(Info)`
  /* .title-project {
    width: max-content;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    line-height: 185%;
    font-size: 19px;
    text-transform: capitalize;
  }

  .sub-title {
    margin: 0;
    font-size: 16px;
  }

  .shared {

    display: flex !important;
    flex-direction: row !important;
  }

  @media (max-width: 576px) {
    .title-project {
      width: 100%;
      font-size: 22px !important;
      text-align: center;
      line-height: 124%;
    }

    .sub-title {
      width: 100%;
      text-align: center;
    }

    .shared {
      display: flex !important;
      justify-content: flex-end;
      width: 84%;
    }
  } */
`;

const MyAttrs = styled(Attrs)`
  @media (max-width: 1199px) {
    align-self: flex-end !important;
  }
  @media (max-width: 576px) {
    position: relative;
    top: 5rem;
  }
`;

const DEFAULT_IMAGE = 'assets/utils/image-not-found/logo-company.png';

const ProjectHeadline = ({
  title,
  stratus,
  generalUse,
  neighborhood,
  city,
  types = [],
  logo,
  min_area_by_type,
  loading,
  location,
  addNotification,
  projectId,
  toggleFavorite,
  isAuthenticate,
  favoriteProjects,
  min_last_price_in_thousands,
}) => {
  // let typesSort = [...types].sort((a, b) => a.area_by_type - b.area_by_type);
  // let lastType = typesSort.shift() || {};
  // const [loadingFavorite, setLoadingFavorite] = useState(false);

  logo = logo != null ? logo : DEFAULT_IMAGE;
  const handleCompareProject = async () => {
    if (!isAuthenticate) {
      addNotification('Inicia sesíon para comparar proyectos.', 'error');
      return;
    }
    Api.projectComparison
      .create({
        project_id: projectId,
      })
      .subscribe({
        next: async () => {
          await navigate('/proyectos/comparar');
          addNotification('Proyecto añadido con exito !!', 'success');
        },
        error: err => {
          if (err.code === 406) {
            addNotification(err.message, 'info');
            navigate('/proyectos/comparar');
          }
          addNotification(err.message, 'error');
        },
        complete: () => {},
      });
  };

  const handleToggleFavorite = async () => {
    if (!isAuthenticate) {
      addNotification('Inicia sesíon para añadir a favoritos', 'error');
      return;
    }
    // setLoadingFavorite(true);
    try {
      await toggleFavorite(projectId);
    } catch (e) {
      // console.log(e);
    } finally {
      // setLoadingFavorite(false);
    }
  };

  return (
    <Wrapper className="project-head-line">
      <Row className="align-items-center justify-content-center">
        {loading ? (
          <AvatarHeadlineSkeleton />
        ) : (
          <ProjectHeader>
        
            <Image src={`${BASE_BUCKET_URL}${logo}`} />
            {/* GRupo */}
            <div className="wrapper">
              <div className="title_project">
                <h1 style={{textAlign:"center"}} className="title-project_text" >{title}</h1>
              </div>
              <div className='project_price'>
                <p className="project_price_text">
                  Desde {money(min_last_price_in_thousands)}
                </p>
              </div>
            </div>
            {/* -------------------------------- */}
           
              <div className="wrapper">
                <div  className="size_project">
                  <SizeIcon />
                  <p className="size_project_text">Desde {min_area_by_type} m2</p>
                </div>
                <div className="project_use">
                  <HomeIcon />
                  <p className="project_use_text">Proyecto {generalUse}</p>
                </div>
              </div>
              {/* ---------------------------------------- */}
              <div className="wrapper">
                <div className="poin_project">
                  <PointIcon
                    style={{
                      width: 18,
                    }}
                  />
                  <p className="project_poin_text">
                    {neighborhood}, {city}
                  </p>
                </div>
                <div className="project_stratum">
                  <LayerIcon />
                  <p className="project_stratum_text">
                    Estrato {stratus}
                  </p>
                </div>
              </div>
            
            {/* <Line/> */}
            {/* ----------------------------------- */}
            <div className='d-flex'>
              <div className="">
                <ButtonOutline onClick={handleToggleFavorite}>
                  <LoveIcon isActive={favoriteProjects.includes(projectId)} />
                </ButtonOutline>
              </div>
              <div style={{marginRight:'2rem'}} onClick={handleCompareProject}>
                <BarsIcon />
              </div>
            </div>
          </ProjectHeader>
        )}
      </Row>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticate: state.auth.isAuthenticate,
    favoriteProjects: state.auth.user.favorite_projects,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (text, type) => dispatch(addNotification(text, type)),
    toggleFavorite: id => dispatch(toggleFavorite(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHeadline);
