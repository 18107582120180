import React from 'react';
import {Field, FieldLabel} from './InputText';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {FormGroup, Row, Col} from '@bootstrap-styled/v4';
import styled from 'styled-components';

const Wrapper = styled(FormGroup)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  
`;

const InputRangeField = styled(Field)`
  max-width: 120px !important;
  height: 40px;
`;

const RangeField = ({
  label,
  min,
  max,
  minValue,
  maxValue,
  onChangeMin,
  onChangeMax,
}) => {
  return (
    <Wrapper className="mb-2">
      <FieldLabel>{label}</FieldLabel>
      <Row className="d-flex align-items-center">
        <Col className="pr-1">
          <InputRangeField
            min={min}
            max={max}
            type="number"
            value={minValue}
            onChange={onChangeMin}
          />
        </Col>
        <KeyboardArrowRight color="secondary" />
        <Col className="pl-1">
          <InputRangeField
            min={min}
            max={max}
            type="number"
            value={maxValue}
            onChange={onChangeMax}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default RangeField;
