import React from 'react';
import Headline from '../Headline';
import { Container } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import AgProjectsHits from '../algoliaRefinements/AgProjectsHits';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { searchClient } from '../../config';

const ProjectsWrapper = styled(Container)`
  //margin-top: 7rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    //padding-top: 6.375rem;
  }
`;

const TitleProyects = styled.p`
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 2rem;
`;

const ProjectContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  //grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  grid-template-areas:
    '. . .'
    '. . .';

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
    grid-auto-flow: row;
    grid-template-areas:
      '. .'
      '. .'
      '. .';
  }

  @media (max-width: 600px) {
    //margin: 0 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      '.'
      '.'
      '.'
      '.'
      '.'
      '.';
  }
`;

const MostVisitedProjects = () => {
  return (
    <ProjectsWrapper>

      <InstantSearch
        className="intant-search"
        indexName="projects"
        searchClient={searchClient}>

        <Configure offset={0} length={6} />
        <div className='content-project-and-title'>
          <TitleProyects className="mb-3">Lo más visitados este mes</TitleProyects>
          <ProjectContent className="p-0">
            <AgProjectsHits />
          </ProjectContent>
        </div>
      </InstantSearch>
    </ProjectsWrapper>
  );
};

export default MostVisitedProjects;
