import React from 'react';
import styled from 'styled-components';
import {ErrorMessage} from 'formik';

const ErrorText = styled.span`
  height: 20px;
  line-height: 20px;
  color: #b62a40;
  display: block;
`;

const ErrorField = ({name}) => {
  return (
    <ErrorText>
      <ErrorMessage name={name} />
    </ErrorText>
  );
};

export default ErrorField;
