import React from 'react';
import { Tooltip, Button } from 'antd';
import {
  Control,
  GeoSearch,
  GoogleMapsLoader,
  CustomMarker,
} from 'react-instantsearch-dom-maps';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import ErrorBoundary from '../ErrorBoundary';
import { API_KEY_GOOGLE_MAPS } from "../../constants/";
const iconMarker = require('../../sources/images/cc_mapmarker.png');
const Wrapper = styled.div`
  & .ais-GeoSearch,
  & .ais-GeoSearch-map {
    height: 100%;
  }

  & .ais-GeoSearch-control {
    display: none !important;
  }
`;

const Marker = styled(CustomMarker)`
  cursor: pointer !important;
`;

const AgGoogleMaps = () => {
  return (
    <Wrapper className="h-100">
      <ErrorBoundary>
        <GoogleMapsLoader apiKey={API_KEY_GOOGLE_MAPS}>
          {google => (
            <GeoSearch google={google}>
              {({ hits }) => (
                <div>
                  <Control />
                  {hits.map(hit => (
                    <Marker
                      className="my-marker"
                      key={hit.objectID}
                      hit={hit}
                      onMouseOver={({ event, marker }) => {
                        console.log(event);
                        console.log(marker);
                      }}
                      onClick={({ event, marker }) => {
                        navigate(`/proyectos/${hit.title_slug}`);
                      }}>
                      <Tooltip className="my-tooltip" placement="top" title={hit.name}>
                        <img src={iconMarker} width={20} height={30} />
                      </Tooltip>
                    </Marker>
                  ))}
                </div>
              )}
            </GeoSearch>
          )}
        </GoogleMapsLoader>
      </ErrorBoundary>
    </Wrapper>
  );
};

export default AgGoogleMaps;
