import React from 'react';
import styled from 'styled-components';
import {Button, Icon} from 'antd';

const ButtonOutline = props => (
  <Button size={props.size || 'large'} {...props}>
    {props.children}
  </Button>
);
let style = ` border: 2px solid #8dc63f !important;
  color: #8dc63f !important;
  border-radius: 5px;
  opacity: 1;
  padding:20px;
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }`;
const CustomButton = props => {
  const AddButtonOutline = styled(ButtonOutline)`
    ${style}
  `;
  return (
    <AddButtonOutline {...props}>
      {props.icon ? props.icon : <Icon type="save" />}
      <span className="ml-1" size="lg">
        {props.children}
      </span>
    </AddButtonOutline>
  );
};
export default CustomButton;
