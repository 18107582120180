
import feathersClient from '../services/feathers-connection';
import { Pagination } from 'antd';
import React, {useEffect, useState} from 'react';
import ContactTable from '../components/account/ContactTable';
import Headline from '../components/account/Headline';
import styled from 'styled-components';
import { Button } from '@bootstrap-styled/v4';
import { Box } from '@material-ui/core';

const DidPagination = styled.p`
  display:flex;
  justify-content:flex-end;
  align-items:center;
  width:100%;
`;

const MyContactAccount = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadReport, setLoadReport] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    skip: 0
})

  const getService = async (...args) => {
    // console.log(args)
    const res = await feathersClient.service('contact-projects').find(...args)
    return res
  }

  const report = () => {
    setLoadReport(true)
    getService({ query: { $client: { report: 'true', } } })
    .then((res) => { 
      window.open(res.url, '_blank')
      setLoadReport(false)
    })
  }

  const fetchPagination = (skip) => {
    getService({ query: {
        $skip: skip,
        $limit: pagination.limit,
    } }).then(res => {
      setPagination({
        limit: res.limit,
        skip: res.skip || 0,
        total: res.total
    })
    setContacts(res)
    })
  }

useEffect(() => {
  setLoading(true);
  getService({ query: {  $skip: 0, $limit: 10}})
  .then(res => {
    setPagination({
      limit: res.limit,
      skip: res.skip || 0,
      total: res.total
  })
    setLoading(false) 
    setContacts(res)
  })
  .catch(error => console.log(error))
}, []);

  return (
    <React.Fragment>
      <Box display='flex' justifyContent='space-between' mb={2} >
      <Headline title="Contactos" />
      <Button onClick={report}>{ loadReport ? 'generando...' : 'generar reporte'}</Button>
      </Box>
      <ContactTable loading={loading} contacts={contacts.data} />
      <DidPagination>
        <Pagination
          current={currentPage}
          pageSize={pagination.limit} 
          total={pagination.total} 
          onChange={ (page, pageSize) => {
            fetchPagination((page - 1) * pageSize)
            setCurrentPage(page)
          }} 
          />
        </DidPagination>
    </React.Fragment>
  );
};

export default MyContactAccount;

