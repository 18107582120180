import React from 'react';
import {Row, Col, H3} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import ButtonOutline from '../ButtonOutline';
import Add from '@material-ui/icons/Add';

const Title = styled(H3)`
  margin-bottom: 0 !important;
  letter-spacing: -0.68px;
  color: #333333 !important;
  opacity: 0.2 !important;
  font-weight: normal;
  min-width: 200px;
`;

const ColGrow = styled(Col)`
  flex-grow: ${props => (props.full ? '1 !important' : '0 !important')};

  @media (max-width: 768px) {
    flex-grow: 1 !important;
  }
`;

const AddButtonOutline = styled(ButtonOutline)`
  border: 2px solid #8dc63f !important;
  color: #8dc63f !important;
  border-radius: 5px;
  opacity: 1;

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

const Headline = ({title, fullTitle, action, handleClick}) => {
  const _renderAction = () => {
    if (!action) {
      return;
    }
    return (
      <ColGrow>
        <AddButtonOutline onClick={handleClick}>
          <Add />
          <span className="ml-1" size="lg">
            {action}
          </span>
        </AddButtonOutline>
      </ColGrow>
    );
  };

  return (
    <Row className="justify-content-between mb-3">
      <ColGrow full={fullTitle}>
        <Title>{title}</Title>
      </ColGrow>
      {_renderAction()}
    </Row>
  );
};

export default Headline;
