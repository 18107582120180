/* ProjectTypeForm.js
name,
use,
area_by_type,
last_price_in_thousands,
last_price_in_m2,
status,
createdAt,
deletedAt,
id,
image_path,
project_phase_id,
purpose,
ref_type_id,
unit_by_type,
updatedAt
 */

import React, {useState} from 'react';
import Form from '../com/Form';
import InputText from '../InputText';
import {Row, Col} from 'antd';
import Select from '../../components/select/Select';
import './formtype.css';
import S3Uploader from '../com/S3Uploader';
import qs from 'qs';
import {navigate} from '@reach/router';
const flexBox = {
  xxl: 8,
  xl: 8,
  lg: 8,
  mb: 12,
  xs: 12,
};
const uses = [
  {
    value: 'Apartamento',
    label: 'Apartamento',
  },
  {
    value: 'Casa',
    label: 'Casa',
  },
  {
    value: 'Bodega',
    label: 'Bodega',
  },
  {
    value: 'Oficina',
    label: 'Oficina',
  },
  {
    value: 'Habitaciones',
    label: 'Habitaciones',
  },
];
const status = [
  {
    value: 'ACTIVO',
    label: 'Activo',
  },
  {
    value: 'DESACTIVADO',
    label: 'Desactivado',
  },
];
const purposes = [
  {
    value: 'Venta',
    label: 'Venta',
  },
  {
    value: 'Arrendar',
    label: 'Arrendar',
  },
];

const ProjectTypeForm = props => {
  let {data, project_id = 10760} = props;
  let {project_phase_id, image_path} = data;
  const refForm = React.createRef();
  return (
    <Form ref={refForm} className="form-type" {...props}>
      <Row gutter={8}>
        {data['id'] && (
          <Col align="center" span={24}>
            <S3Uploader
              name="image_path"
              label="Subir Archivo"
              hasFormik
              image_path={image_path}
              idComponent="s3Button"
              path={`projects-images/${project_id}/project-phases/${project_phase_id}/project-types/${
                data['id']
              }/`}
              id={data.id}
            />
          </Col>
        )}
        <Col {...flexBox}>
          <InputText hasFormik name="name" label="Nombre" />
        </Col>
        <Col {...flexBox}>
          <InputText
            hasFormik
            name="last_price_in_thousands"
            label="Precio en miles"
          />
        </Col>
        <Col {...flexBox}>
          <InputText hasFormik name="unit_by_type" label="Unidades por tipo" />
        </Col>
        <Col {...flexBox}>
          <InputText hasFormik name="area_by_type" label="Area por tipo" />
        </Col>
        <Col {...flexBox}>
          <InputText hasFormik name="last_price_in_m2" label="Precion en m2" />
        </Col>
        <Col {...flexBox}>
          <Select name="use" hasFormik label="Uso" dataSource={uses} />
        </Col>
        <Col {...flexBox}>
          <Select
            name="purpose"
            hasFormik
            label="Propósito"
            dataSource={purposes}
          />
        </Col>
        <Col {...flexBox}>
          <Select name="status" hasFormik label="Estado" dataSource={status} />
        </Col>
      </Row>
    </Form>
  );
};

export default ProjectTypeForm;
