import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const ProjectSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton variant="rect" height={257} width={340}/>
      <Skeleton width="100%" />
      <Skeleton width="100%" />
      <Skeleton width="100%" style={{marginBottom: 40}} />
    </React.Fragment>
  );
};

export default ProjectSkeleton;
