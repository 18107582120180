import React, { useState, useEffect } from 'react';
import Logo from '../SvgImg/logo.svg';
import Logo_solo from '../SvgImg/logo_solo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import LoginModal from '../components/LoginModal';
import { connect } from 'react-redux';
import { navigate, Link } from '@reach/router';
import styled from 'styled-components';
import Arrow from '../svgComponents/Arrow';
import LoveIcon from '../components/icons/LoveIcon';

const NavLink = props => (

  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: isCurrent ? "#00438D" : "#000",
          fontWeight: isCurrent ? 600 : 500
        }
      };
    }}
  />
);

const LoginButton = styled(Button)`

  box-shadow: 0px 0px 16px;
  background-color: #00438D !important;
  width: 10rem; 
  height: 40px;

  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 1rem; 

  .text {
    margin: 0;
  }

  @media (max-width: 414px) {
    .item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 20px;

    }
  }
`;

const Text = styled.p`
     width: max-content;
     margin: 0;
`;

const ContentHeartButton = styled.div`
      display: flex;
      gap: 1rem; 

      @media(max-width: 1200px){
        margin: 10px 0;
      }


`;

const NavBar = ({ isAuthenticate, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginModal, setLoginModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleAuthButton = () => {
    isAuthenticate
      ? navigate('/admin/mi-cuenta/proyectos')
      : setLoginModal(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => { };
  }, [props.location]);



  const handleNavItemClick = () => {
    if (window.innerWidth < 992) {
      setExpanded(false);
    }
  };

  return (
    <React.Fragment>
      {/* fixed='top' */}
      <Navbar collapseOnSelect expand="xl" bg="white" variant="light" fixed="top" expanded={expanded}>
        <Container fluid className='nav-styles'>
          <Navbar.Brand as={Link} onClick={() => setIsOpen(!isOpen)} tag={Link} to="/">
            {
              screenWidth <= 1000 ? (<img src={Logo_solo} width={'40'} alt="logo.svg" />) :
                (<img src={Logo} width={'240'} alt="logo.svg" />)
            }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" onClick={handleNavItemClick}>
              <Nav.Link className="anchor-navbar" style={{ fontSize: "1rem", fontWeight: "500" }} as={NavLink} to="/"><Text>Inicio</Text></Nav.Link>
              <Nav.Link className="anchor-navbar" style={{ fontSize: "1rem", fontWeight: "500" }} as={NavLink} to="/proyectos"><Text>Busca tu inmueble</Text></Nav.Link>
              <Nav.Link className="anchor-navbar" style={{ fontSize: "1rem", fontWeight: "500" }} as={NavLink} to="/noticias"><Text>Noticias del sector</Text></Nav.Link>
              <Nav.Link className="anchor-navbar" style={{ fontSize: "1rem", fontWeight: "500" }} as={NavLink} to="/revista"><Text>Revista Construcaribe</Text></Nav.Link>
            </Nav>
            <ContentHeartButton>
              {isAuthenticate && (
                <div
                  onClick={() => navigate('/mis-favoritos')}
                  className="d-flex justify-content-center align-items-center">
                  <LoveIcon />
                </div>
              )}

              <LoginButton
                onClick={handleAuthButton}>
                <p className="text">
                  {isAuthenticate ? 'Mi cuenta' : 'Ingresa aquí'}
                </p>
                <Arrow />
              </LoginButton>

            </ContentHeartButton>
            <LoginModal open={showLoginModal} onClose={() => setLoginModal(false)} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticate: state.auth.isAuthenticate,
  };
};

export default connect(mapStateToProps, null)(NavBar);
