import React from 'react';
import styled from 'styled-components';
import {Container, Card,  Button} from '@bootstrap-styled/v4';
import AgGoogleMaps from '../algoliaRefinements/AgGoogleMaps';
import {searchClient} from '../../config';
import {InstantSearch,Configure} from 'react-instantsearch-dom';
import {navigate} from '@reach/router';
import Arrow from '../../svgComponents/Arrow';

const Wrapper = styled.section`
  width: 100%;
  height: 782px;
  background-color: gray;
  margin-top: 6.875rem;
  position: relative;
  /*   margin-bottom: 4.4375rem; */
`;

const MapCard = styled(Card)`
  padding: 3rem;
  border-radius: 20px !important;
  box-shadow: 0px 0px 26px #0000000f;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  box-sizing: border-box;
  /* padding-top: 3.8125rem;
  padding-bottom: 4.5625rem; */
  padding: 2rem 3.2rem 2.75rem 4.85rem !important;
  /* width: 90%; */
  max-width: 24.5rem;
  max-height: 24.5rem;
`;

const Title = styled.p`
  font-size: 25px;
  font-weight: 400 !important;
  margin-bottom:2rem !important;
  line-height: 110% !important;
`;

const MapWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const MyButton = styled(Button)`
  height: 44px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 2rem !important;
  /* width: 75%; */

`;

const HomeMap = props => {
  return (
    <InstantSearch indexName="projects" searchClient={searchClient}>
      <Configure hitsPerPage={50} />
      <Wrapper>
        <MapWrapper>
          <AgGoogleMaps />
        </MapWrapper>
        <Container className="h-100">
          <MapCard>
            <img src="imageMap.jpg" width={150} height={107} style={{marginBottom: "1rem"}} alt="imgMap.jpg"/>
            <Title className="font-weight-bold">
              Busca con el mapa y encuentra los <br /> proyectos en la zona{' '}
              <br /> que más te gusta.
            </Title>
            <MyButton size="lg" onClick={() => navigate('/proyectos')}>
              <span>Busca ahora</span>
              <Arrow/>
            </MyButton>
          </MapCard>
        </Container>
      </Wrapper>
    </InstantSearch>
  );
};

HomeMap.propTypes = {};

export default HomeMap;
