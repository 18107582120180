import React, { useState } from "react";
import { Formik, Form } from 'formik';
import { Row, Col } from '@bootstrap-styled/v4';
import { SpacingButton, Title } from "./Styles";
import InputText from '../components/InputText';
import * as yup from 'yup';
import { getService } from "../services/services";

const validationDefaultSchema = yup.object().shape({
    email: yup
        .string()
        .email('Debe ser un correo electronico valido.')
        .required('Correo electronico es requerido.')
});
const validationResetSchema = yup.object().shape({
    token_reset_password: yup
        .string()
        .required('Token es requerido.')
});

const ToolBar = ({ loading, view, setView, onChageView, props }) => (<Row style={{
    margin: "16px 0px"
}}>
    <Col>
        <SpacingButton
            size="lg"
            type="submit"
            disabled={loading}
            className="w-100">
            Enviar
        </SpacingButton>
    </Col>
    <Col md={12} align="center">
        <span>
            ¿Ya tiene una cuenta? <a to="/#" onClick={() => {
                if (onChageView)
                    onChageView("default");
            }}>Iniciar</a>
        </span>
    </Col>
    <Col md={12} align="center">
        {view == "default" && <span>
            Ya tengo un código <a to="/#" onClick={() => {
                setView("reset-password");
            }}>Ingresar</a>
        </span>}
        {view == "reset-password" && <span>
            Enviar código nuevamente <a to="/#" onClick={() => {
                setView("default");
            }}>Reenviar Código</a>
        </span>}
    </Col>
</Row>)
const ResetPassword = ({ onChageView, addNotification, ...props }) => {
    const [loading, setLoading] = useState(false);

    // const [initialValues, setInitialValues] = useState({
    //     email: '',
    // });

    const [view, setView] = useState("default");
    const onSubmit = payloads => {
        setLoading(true);
        const service = getService("recovery-password");
        service.create(payloads)
            .then(response => {
                setLoading(false);
                if (view == "default") {
                    addNotification('Se ha enviado un enlace a su correo, para restablecer su contraseña', 'info');
                    return setView("reset-password");
                }
                if (props.onSubmit) {
                    addNotification('Tu contraseña se ha cambiado exitosamente!', 'success');
                    props.onSubmit();
                }
            })
            .catch(err => {
                setLoading(false);
                addNotification(err.message, 'error');
            });
    }

    return (<>
        <Title>{view == "default" ? "Recuperar Contraseña" : "Cambiar Cotraseña"}</Title>
        {view == "reset-password" && <Formik
            validationSchema={validationResetSchema}
            onSubmit={onSubmit}
            render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (<Form
                onKeyPress={event => {
                    event.key === 'Enter' && handleSubmit();
                }}>
                <Row>
                    <Col md={12} className="mb-2">
                        <InputText
                            hasFormik
                            name="token_reset_password"
                            type="password"
                            placeholder="Escribe el código que te enviamos al correo."
                            label="Ingrese el Código"
                        />
                        <InputText
                            hasFormik
                            name="password"
                            type="password"
                            placeholder="Ingrese su nueva contraseña"
                            label="Nueva contraseña"
                        />
                    </Col>
                </Row>
                <ToolBar
                    loading={loading}
                    view={view}
                    onChageView={onChageView}
                    setView={setView}
                />
            </Form>)}
        />}
        {
            view == "default" &&
            <Formik
                validationSchema={validationDefaultSchema}
                onSubmit={onSubmit}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (<Form
                    onKeyPress={event => {
                        event.key === 'Enter' && handleSubmit();
                    }}>
                    <Row>
                        <Col md={12} className="mb-2">
                            <InputText
                                hasFormik
                                name="email"
                                type="email"
                                placeholder="Ingrese su correo electrónico"
                                label="Correo electrónico"
                            />
                        </Col>
                    </Row>
                    <ToolBar
                        loading={loading}
                        view={view}
                        onChageView={onChageView}
                        setView={setView}
                    />
                </Form>)
                } />
        }
    </>)
}


export default ResetPassword;