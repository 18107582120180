import {from} from 'rxjs';
import feathersClient from './feathers-connection';
import FeathersService from './feathers-service';

const feathersService = {
  login: ({email, password, strategy = 'local', accessToken}) =>
    from(
      feathersClient.authenticate({
        email,
        strategy,
        password,
        accessToken,
      }),
    ),
  retryLogin: () =>
    from(
      feathersClient.authenticate({
        strategy: 'jwt',
        accessToken: localStorage.getItem('feathers-jwt'),
      }),
    ),
  logout: () => from(feathersClient.logout()),
  current: () => from(feathersClient.service('current-user').find({})),
  get_project: id => from(feathersClient.service('projects').get({id})),
  // projects: new FeathersService('deals-projects'),
  users: new FeathersService('users'),
  contacts: new FeathersService('contacts'),
  blogs: new FeathersService('blogs'),
  projects: new FeathersService('projects'),
  phases: new FeathersService('projects-phases'),
  favoriteProjects: new FeathersService('favorite-projects'),
  companiesRanking: new FeathersService('construction-companies-ranking'),
  projectTypes: new FeathersService('projects-types'),
  magazine: new FeathersService('magazine'),
  contactProjects: new FeathersService('contact-projects'),
  projectComparison: new FeathersService('project-comparison'),
  zones: new FeathersService('zones'),
  cities: new FeathersService('locations-cities'),
  neighborhoods: new FeathersService('neighborhoods'),
  configurations: feathersClient.service('configurations'),
  // verifyEmailAccount: new FeathersService('verify-email-account'),
};

export default feathersService;
