import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { Row, Col, Button, H3, H4 } from '@bootstrap-styled/v4';
import Avatar from '../Avatar';
// import CirclePhoneIcon from '../icons/CirclePhoneIcon';
// import CircleWppIcon from '../icons/CircleWppIcon';
// import CircleMailIcon from '../icons/CircleMailIcon';
// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import BlueCalculator from '../icons/BlueCalculator';
// import GreenCalendar from '../icons/GreenCalendar';
import PinOutline from '../icons/PinOutline';
import InputText from '../InputText';
// import ButtonOutline from '../ButtonOutline';
import styled from 'styled-components';
import Text from '../Text';
import AreasOfInterestList from '../projects/AreasOfInterestList';
import ScheduleModal from '../ScheduleModal';
import AvatarHeadlineSkeleton from '../skeletons/AvatarHeadlineSkeleton';
import Api from '../../services/api';
import { addNotification } from '../../actions/notifications';
import AppButton from '../AppButton';
import { BASE_BUCKET_URL } from '../../constants/global';

const Info = styled.div`
  padding-top: 7.4375rem;
`;

// const LeftButtonOutline = styled(ButtonOutline)`
//   border-color: #8dc63f !important;
//   color: #8dc63f !important;
//   border-width: 2px;
//   opacity: 1 !important;
//   font-size: 17px !important;
//   justify-content: center;
// `;

// const RightButtonOutline = styled(ButtonOutline)`
//   border-color: #00b2e3 !important;
//   color: #00b2e3 !important;
//   opacity: 1 !important;
//   font-size: 17px !important;
//   justify-content: center;

//   @media (max-width: 768px) {
//     margin-top: 0.5rem;
//   }
// `;

const Location = styled.span`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.6;
`;

// const AreasOfInterest = styled.div`
//   padding-top: 2.875rem;
// `;

const Title = styled.h2`
  max-width: 293px;

    margin: 0;
    line-height: 1;
    font-size: 23px !important;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
`;

const Headline = styled.div`
  flex: 1;
`;

const InputTextMessage = styled(InputText)`
  height: 120px;
  min-height: 100px;
  max-height: 150px;
`;
const MyHeadForm = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .title-head {
      margin: 0;
      font-size: 1.75rem !important;
      text-align: left;
    }

`;

const schema = yup.object().shape({
  name: yup
    .string()
    .max(40, 'Nombre es demasiado largo')
    .required('Nombre es requerido.'),
  text: yup
    .string()
    .max(256, 'Maximo 256 caracteres')
    .required('Comentario es requerido.'),
  email: yup
    .string()
    .email('El E-Mail debe ser valido.')
    .required('Correo electronico es requerido.'),
  phone: yup.string().required('Telefono es requerido.'),
  last_name: yup
    .string()
    .required('Apellido es requerido.'),
});

const IMAGE_BLANK = 'assets/utils/image-not-found/logo-company.png';
const SendMessageForm = ({
  location,
  locationDescription,
  neighborhood,
  city,
  zonesInterest,
  loading,
  sellingCompany = {},
  user,
  isAuthenticate,
  addNotification,
  projectId,
  logo,
}) => {
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    text: '',
    phone: '',
  });

  useEffect(() => {
    if (isAuthenticate) {
      setInitialValues({
        ...initialValues,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: user.phone ? user.phone : '',
      });
    }
  }, [user]);

  const sendMessage = ({ name, last_name ,email, text, phone }, { resetForm }) => {
    setLoadingSendMessage(true);
    Api.contactProjects
      .create({
        full_name: `${name} ${last_name}`,
        message: text,
        email,
        phone,
        project_id: projectId,
      })
      .subscribe({
        next: () => {
          resetForm();
          addNotification('Mensaje enviado con exito !', 'success');
        },
        error: err => {
          // console.log(err);
          return addNotification('Error inesperado !', 'error');
        },
        complete: () => setLoadingSendMessage(false),
      });
  };

  return (
    <div style={{ position: 'relative', padding: "1rem"}}>
      <div id={'formcontacto'} style={{ position: 'absolute', top: -120 }} />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <Headline className="d-flex align-items-center">
          {loading ? (
            <AvatarHeadlineSkeleton />
          ) : (
            <MyHeadForm>
              <Avatar src={`${BASE_BUCKET_URL}${logo ? logo : IMAGE_BLANK}`} />
              <Title className="pl-3">
                {sellingCompany.name}
              </Title>
            </MyHeadForm>
          )}
        </Headline>

      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={sendMessage}
        enableReinitialize
        render={({ isSubmitting, handleSubmit }) => (
          <Form
            className="mb-3"
            onKeyPress={event => {
              event.key === 'Enter' && handleSubmit();
            }}>
            <Row>
              <Col className="mb-2" md={6}>
                <InputText name="name" hasFormik placeholder="Nombre" />
              </Col>
              <Col className="mb-2" md={6}>
                <InputText name="last_name" hasFormik placeholder="Apellido" />
              </Col>
              <Col className="mb-2" md={12}>
                <InputText name="email" hasFormik placeholder="Correo electrónico" />
              </Col>
              <Col className="mb-2" md={12}>
                <InputText name="phone" hasFormik placeholder="Teléfono" />
              </Col>
              <Col className="mb-2" md={12}>
                <InputTextMessage
                  name="text"
                  type="textarea"
                  hasFormik
                  rows="10"
                  placeholder="Mensaje"
                />
              </Col>
              <Col className="mb-2" md={12}>
                <label style={{marginLeft: '1rem', marginLeft:'1rem'}}>
                  <Field
                    required
                    type="checkbox"
                    name="checked"
                    value="terms"
                  />
                  <span style={{textDecoration:'underline', margin:'0 0.3rem'}}>
                    {' '}
                    Acepto las
                      <a
                        style={{textDecoration: 'none', color:'#000'}}
                        target="_blank"
                        href="https://construcaribe.s3.amazonaws.com/assets/TRATAMIENTO%20DE%20BASES%20DE%20DATOS.pdf">
                        {' '}
                        Politicas de Tratamientos de Datos
                      </a>
                  </span>
                </label>
              </Col>
              <Col className="mb-2" md={12} style={{display: "flex", justifyContent: "flex-end"}}>
                <AppButton
                  disabled={isSubmitting}
                  type="submit"
                  loading={loadingSendMessage}
                  className="mt-2">
                  Solicitar cotización
                </AppButton>
              </Col>
            </Row>
          </Form>
        )}
      />
      {!loading && (
        <React.Fragment>
          <Info>
            <H4 className="font-weight-bold text-capitalize">
              {neighborhood}, {city}
            </H4>
            {location && (
              <div className="d-flex align-items-center">
                <PinOutline />
                <Location className="ml-2 text-capitalize" style={{marginLeft:'0.5rem'}}>{location}</Location>
              </div>
            )}
            <Text>{locationDescription}</Text>
          </Info>
          <AreasOfInterestList items={zonesInterest} />
        </React.Fragment>
      )}
      <ScheduleModal
        open={showScheduleModal}
        onClose={() => setShowScheduleModal(false)}
      />
      <ScheduleModal
        open={showScheduleModal}
        onClose={() => setShowScheduleModal(false)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isAuthenticate: state.auth.isAuthenticate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (text, type) => dispatch(addNotification(text, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageForm);
