import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import {IconButton} from '@material-ui/core';
import {Container, H1, H3, Row, Col, Button} from '@bootstrap-styled/v4';
import {navigate} from '@reach/router';
import ProjectZones from '../projects/ProjectZones';
import SizeIcon from '../icons/SizeIcon';
import HomeIcon from '../icons/HomeIcon';
import PointIcon from '../icons/PointIcon';
import LayerIcon from '../icons/LayerIcon';
import Divider from '../Divider';
import TypeItemsProject from '../projects/TypeItemsProject';
import PinOutlineIcon from '../icons/PinOutline';
import ProjectTitle from '../projects/ProjectTitle';
import PropTypes from 'prop-types';
import {BASE_BUCKET_URL} from '../../constants/global';
import IframeGoogleMap from '../IframeGoogleMap';

const Image = styled.img`
  width: 100%;
  height: 485px;
  margin-bottom: 2.875rem !important;
  object-fit: cover;
`;

const Items = styled(Row)`
  padding-top: 3rem;
  padding-bottom: 2.5rem;
`;

const Title = styled(H3)`
  letter-spacing: 0 !important;
  color: #000000 !important;
  opacity: 0.6 !important;
  font-weight: bold !important;
`;

const Delete = styled(IconButton)`
  position: absolute !important;
  right: 10px;
  top: 10px;
  background-color: white !important;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const CompareProjectCard = ({project, onRemoveProject}) => {
  return (
    <React.Fragment>
      <ImageWrapper>
        <Delete
          onClick={() => {
            onRemoveProject(project.id);
          }}>
          <DeleteIcon />
        </Delete>
        <Image src={`${BASE_BUCKET_URL}${project.image}`} />
      </ImageWrapper>
      <ProjectTitle
        title={project.name}
        image={project.image}
        price={project.price || 0}
        hiddedShared
      />
      <Items>
        <Col sm={6} className="d-flex mb-2 align-items-center">
          <SizeIcon />{' '}
          <span className="pl-2 text-muted">Desde {project.area} m2</span>
        </Col>
        <Col sm={6} className="d-flex mb-2 align-items-center">
          <PointIcon />
          <span className="pl-2 text-muted text-capitalize">
            {project.neighborhood}, {project.city}
          </span>
        </Col>
        <Col sm={6} className="d-flex align-items-center">
          <HomeIcon />
          <span className="pl-2 text-muted">Proyecto {project.generalUse}</span>
        </Col>
        <Col sm={6} className="d-flex align-items-center">
          <LayerIcon />
          <span className="pl-2 text-muted">Estrato {project.stratus}</span>
        </Col>
      </Items>
      {project.commonZones && project.commonZones.length > 0 && <Divider />}
      {project.commonZones && project.commonZones.length > 0 && (
        <ProjectZones
          hasSpace
          items={project.commonZones}
          className="mt-5"
          hiddedText
        />
      )}
      <Divider />
      <Title className="mb-3 mt-5">Tipos de apartamento</Title>
      {project.types.map(type => (
        <TypeItemsProject type={type} key={type.id} />
      ))}
      <Divider className="mt-5" />
      <Title className="mb-3 mt-5">Ubicación</Title>
      <IframeGoogleMap lat={project.lat} long={project.long} />
      <div className="mt-3">
        <PinOutlineIcon />
        <span className="pl-2 text-muted">
          {project.address ? project.address : 'Sin determinar.'}
        </span>
      </div>
      <Button
        className="w-100 mt-3 text-white mb-3"
        size="lg"
        onClick={() => navigate(`/proyectos/${project.slug}`)}
        color="secondary">
        Ver proyecto
      </Button>
    </React.Fragment>
  );
};

CompareProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    stratus: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    address: PropTypes.number.isRequired,
    area: PropTypes.number.isRequired,
    neighborhood: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    onRemoveProject: PropTypes.func,
    generalUse: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    commonZones: PropTypes.array.isRequired,
    lat: PropTypes.string.isRequired,
    long: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompareProjectCard;
