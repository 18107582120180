import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col, H3, Button} from '@bootstrap-styled/v4';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '../components/Divider';
import ProfileSkeleton from '../components/skeletons/ProfileSkeleton';
import {navigate} from '@reach/router';
import {connect} from 'react-redux';
import Api from '../services/api';
import {setAuthenticate} from '../actions/notifications';
import {BASE_BUCKET_URL, LOGO} from '../constants/global';

const ContentLateralBar = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;

`;

const Company = styled.span`
  margin-top: 2.0625rem;
  font-size: 0.9375rem;
`;

const Username = styled.p`
  font-size: 1.7rem !important;
  font-weight: bold;
  text-align: center !important;
  line-height: 110% !important;
`;

const Aside = styled(Col)`
 
  padding-top: 5.1875rem;
  padding-bottom: 2.1875rem;

  @media (max-width: 768px) {
    padding-top: 2.1875rem;
  }
`;

const NavContainer = styled.nav`
  max-width: 270px;
  margin-top: 2rem;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
`;

const NavButton = styled(Button)`
  font-weight: bold !important;
  outline: 0 !important;
  color: ${props => (props.active ? '#00B2E3!important' : '#000000!important')};
  opacity: ${props => (props.active ? '1.0' : '0.2')};
  padding-left: 0 !important;
`;

const ProfileAvatar = styled(Avatar)`
  width: 136px !important;
  height: 136px !important;
`;

const ContentWrapper = styled(Container)`
  padding-top: 5.1875rem;
  padding-bottom: 7.5625rem;
`;
const ProfileForm = props => {
  let {user, loadingUser, setAuthenticate} = props;
  let {affiliate} = user;
  const handleLogOut = () => {
    Api.logout().subscribe({
      next: () => pushHome(),
    });
  };
  const pushHome = async () => {
    await navigate('/');
    setTimeout(() => {
      if (setAuthenticate) setAuthenticate(false);
    }, 0);
  };
  return (
    <ContentLateralBar>
      {loadingUser ? (
        <ProfileSkeleton />
      ) : (
        <React.Fragment>
          <ProfileAvatar
            src={
              affiliate === 'true'
                ? user.construction_company && user.construction_company.logo
                  ? `${BASE_BUCKET_URL}${user.construction_company.logo}`
                  : LOGO
                : //`https://api.adorable.io/avatars/170/${user.first_name}.png`
                  LOGO
            }
          />
          {affiliate === 'true' && (
            <Company>
              {user.construction_company && user.construction_company.name}
            </Company>
          )}
          <Username>{`${user.first_name} ${user.last_name}`}</Username>
        </React.Fragment>
      )}
      <Button
        color="link"
        onClick={handleLogOut}
        className="d-flex align-items-center">
        <ExitToAppIcon /> <span className="pl-2">Cerrar sesíon</span>
      </Button>
      <NavContainer>
        {affiliate === 'true' && (
          <NavButton
            onClick={() => navigate('/admin/mi-cuenta/proyectos')}
            active={props.location.pathname === '/admin/mi-cuenta/proyectos'}
            className="w-100 text-left"
            color="link">
            Proyectos
          </NavButton>
        )}
        <Divider />
        <NavButton
          onClick={() => navigate('/admin/mi-cuenta/contactos')}
          active={props.location.pathname === '/admin/mi-cuenta/contactos'}
          className="w-100 text-left"
          color="link">
          Contactos
        </NavButton>
        <Divider />
        <NavButton
          className="w-100 text-left"
          color="link"
          onClick={() => navigate('/mis-favoritos')}>
          Favoritos
        </NavButton>
      </NavContainer>
    </ContentLateralBar>
  );
};
const BaseAccount = ({
  loadingUser,
  children,
  setAuthenticate,
  user,
  ...props
}) => {
  let {affiliate} = user;
  affiliate = affiliate == 'true';
  return (
    <Row>
      <Aside md={3} className="d-flex flex-column align-items-center">
        <ProfileForm
          loadingUser={loadingUser}
          user={user}
          setAuthenticate={setAuthenticate}
          {...props}
        />
      </Aside>
      <Col md={9}>
        {affiliate && <ContentWrapper>{children}</ContentWrapper>}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    loadingUser: state.auth.loadingUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAuthenticate: value => dispatch(setAuthenticate(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseAccount);
