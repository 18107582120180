import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Match} from '@reach/router';

const ProtectedLink = ({isAuthenticate, children}) => {
  return (
    <React.Fragment>
      {isAuthenticate ? children : <Redirect to="/403" noThrow />}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticate: state.auth.isAuthenticate,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ProtectedLink);
