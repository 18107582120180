import React, {useEffect, useState} from 'react';
import {Container, Row, Col, H1} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompareProjectCard from '../components/projects/CompareProjectCard';
import Api from '../services/api';
import PropTypes from 'prop-types';

const Title = styled(H1)`
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font-size: 34px !important;
  font-weight: bold !important;
  margin-bottom: 4.0625rem !important;
  margin-top: 4.1875rem;
`;

const ContainerBox = styled(Container)`
  box-sizing: border-box;
`;

const Wrapper = styled(Row)`
  overflow-x: scroll;
  flex-wrap: nowrap !important;
`;

const CompareProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  //TODO obtener todos los proyectos  a comparar
  useEffect(() => {
    setLoading(true);
    let request = fetchProjects();
    return () => request.unsubscribe();
  }, []);

  const fetchProjects = () => {
    return Api.projectComparison.findAll().subscribe({
      next: data => setProjects(data),
      error: () => {},
      complete: () => setLoading(false),
    });
  };

  const removeProject = id => {
    setLoadingRemove(true);
    console.info(`remove project with id ${id}`);
    Api.projectComparison.remove(null, {query: {project_id: id}}).subscribe({
      next: () => {
        fetchProjects();
      },
      error: () => {},
      complete: () => setLoadingRemove(true),
    });
  };

  return (
    <ContainerBox>
      <Title className="text-center">Comparar proyectos</Title>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <CircularProgress size={100} />
        </div>
      ) : (
        <Wrapper className="mb-4">
          {projects.map(project => (
            <Col md={6} key={project.id.toString()}>
              <CompareProjectCard
                project={{
                  stratus: project.stratus,
                  price: project.types[0].last_price_in_thousands,
                  area: project.types[0].area_by_type,
                  neighborhood: project.neighborhood.name,
                  city: project.city.name,
                  name: project.name,
                  image: project.image,
                  slug: project.title_slug,
                  id: project.id,
                  generalUse: project.general_use,
                  types: project.types,
                  commonZones: project.common_zones,
                  lat: project._geoloc.lat,
                  long: project._geoloc.lng,
                  address: project.address,
                }}
                onRemoveProject={removeProject}
              />
            </Col>
          ))}
        </Wrapper>
      )}
    </ContainerBox>
  );
};

export default CompareProjects;
