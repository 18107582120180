import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const BasicCardSkeleton = ({large}) => {
  return (
    <React.Fragment>
      <Skeleton variant="rect" height={large ? 400 : 217} />
      <Skeleton width="60%" />
      <Skeleton width="20%" />
    </React.Fragment>
  );
};

export default BasicCardSkeleton;
