import React from 'react';
import {Input} from '@bootstrap-styled/v4';
import styled from 'styled-components';

const Icon = styled.div`
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 0.8rem;
  transform: translate(0, -50%);
`;

const Field = styled(Input)`
  padding-left: ${props =>
    !props.icon ? '2.4rem !important' : '.75rem!important'};
  border: none !important;
  outline: 0 !important;
  

  ::placeholder {
    color: rgba(0, 0, 0) !important;
    font-weight: lighter !important;
  }
  
`;

const Wrapper = styled.div`
  flex-grow: ${props => props.grow};
  max-width: ${props => props.maxWidth + 'px'};
  position: relative;
  outline: 0 !important;
  border-radius: 10px;
  overflow: hidden;
`;

const InputField = ({icon, grow, maxWidth, ...other}) => {
  if (icon) {
    return (
      <Wrapper grow={grow} maxWidth={maxWidth} className="d-flex">
        <Icon>{icon}</Icon>
        <Field {...other} />
      </Wrapper>
    );
  }

  return <Input {...other} {...other.field} />;
};

export default InputField;
