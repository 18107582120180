import React, {useState} from 'react';
import styled from 'styled-components';
import Text from '../Text';
import ProjectZones from '../projects/ProjectZones';
import TextSkeleton from '../skeletons/TextSkeleton';
import IframeGoogleMap from '../IframeGoogleMap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faMapMarkedAlt,
} from '@fortawesome/free-solid-svg-icons';

const Title = styled.h2`
  letter-spacing: 0 !important;
  color: #000000 !important;
  /* opacity: 0.6 !important; */
`;
const ShowMap = styled.button`
  box-shadow: 0px 3px 26px #0000000f;
  border-radius: 7px !important;
  background-color: #fff;
  padding: 0.4rem 1.25rem !important;
  box-sizing: content-box !important;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  align-items: center;
  width: auto;
  background-color: #fff;
  gap: 5px;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  margin-bottom: 5px;
`;

const IconMap = styled.div`
  transform: rotate(${({isOpen}) => (isOpen ? '90deg' : '0deg')});
  transition: 0.3s;
`;

const Wrapper = styled.div`
  position: relative;

  @media (max-width: 576px) {
    padding: 1rem;
  }
`;
const TextContainer = styled.div`
  /* box-shadow: 1px 0px 7px -3px;
  padding: 1rem;
  border-radius: 10px;
  margin: 0.5rem; */
`;

const ProjectAbout = ({
  about,
  zoneInterestDescription,
  adminCost,
  gasCost,
  electricityCost,
  waterCost,
  loading,
  commonZones,
  long,
  lat,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  if (loading) {
    return <TextSkeleton />;
  }
 
  return (
    <Wrapper>
      <TextContainer>
        <Title>Acerca del proyecto</Title>
        <Text>{about}</Text>
      </TextContainer>
      <ProjectZones items={commonZones} text={zoneInterestDescription} />
      <ShowMap onClick={() => setIsOpen(!isOpen)}>
      <FontAwesomeIcon size='sm' color='#000' icon={faMapMarkedAlt}/>
        Mostrar mapa
      <IconMap isOpen={isOpen}>
        <FontAwesomeIcon size='sm' color='#000' icon={faChevronRight}/>
      </IconMap>
      </ShowMap>
      {isOpen && <IframeGoogleMap long={long} lat={lat} />}
    </Wrapper>
  );
};


export default ProjectAbout;
