import Api from '../services/api';
import {store} from '../config';
import {getCurrentUser} from './auth';

export const toggleFavorite = projectId => {
  return dispatch =>
    new Promise((resolve, reject) => {
      if (store.getState().auth.user.favorite_projects.includes(projectId)) {
        Api.favoriteProjects
          .remove(null, {query: {project_id: projectId}})
          .subscribe({
            next: () => {
              resolve();
              dispatch(getCurrentUser());
            },
            error: err => reject(err),
          });
      } else {
        Api.favoriteProjects.create({project_id: projectId}).subscribe({
          next: () => {
            resolve();
            dispatch(getCurrentUser());
          },
          error: err => reject(err),
        });
      }
    });
};
