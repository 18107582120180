import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from '@reach/router';
import Logo from '../SvgImg/logo.svg';
import Position from '../SvgImg/position.svg'; 
import  LogoWa  from '../SvgImg/WA_Logo.svg';
import Gmail from '../SvgImg/Gmail.svg'; 

import {getCellPhone, getEmail, getWhatsapp} from '../utils/getDinamicsEnvs';
import Facebook from '../svgComponents/Facebook';
import Instagram from '../svgComponents/Instagram';
import LogoFooter from '../SvgImg/LogoFooter.svg';


const Wrapper = styled.footer`
  width: 100%;
  max-height: 476px;
  min-height: 476px;
  padding: 3rem 5rem 1rem 5rem;
  display: flex;
  background-color: #F6F6F6;

  font-size: 14px;
 

  .list_item-contact {
    list-style: none;
    padding: 0;

    line-height: 1.8rem;

    .item {
      a {
        text-decoration: none;
        color: black;
      }
    }
  }

  .item {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }

  .logo-footer {
    width: 407px;
    height: 68px;
  }

  .first {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;

    .content-col {
      display: flex;
      width: 100%;
    }
  }

  .second {
    max-width: 30%;
    min-width: 30%;

    padding-top: 1rem;
  }

  .copy {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-top: 1px solid #00438d;
  }
  .col1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .col2 {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .content_col-nav {
    width: 100%;
    display: flex;
    padding-left: 11rem;
  }

  .social-media {
    display: flex;
    gap: 1rem;
  }

  .logo__constru {
    position: relative;
    top: -11rem;
    left: calc(100% - 27rem);
    width: 32rem;
    height: 32rem;
  }



  @media (max-width: 1500px) {

    flex-direction: row;
    max-width: 1440px;

    .content_col-nav {
      width: 100% !important;
      display: flex !important;
      padding-left: 5rem !important;
    }

    .logo__constru {
      position: relative;
      top: -2.4rem;
      left: calc(100% - 27rem);
      width: calc(32rem - -1%);
      height: calc(32rem - 30%);
    }
  }

  @media (max-width: 1390px) {
    flex-direction: row;

    .content_col-nav {
      width: 100% !important;
      display: flex !important;
      padding-left: 5rem !important;
    }

    .logo__constru {
      position: relative;
      top: -1.4rem;
      left: calc(100% - 27rem);
      width: calc(32rem - -1%);
      height: calc(32rem - 30%);
    }

    .content-col {
      flex-direction: column;
    }

    .content_col-nav {
      padding-left: 0 !important;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: row;

    .content_col-nav {
      width: 100% !important;
      display: flex !important;
      padding-left: 5rem !important;
    }

    .logo__constru {
      position: relative;

      top: -0.4rem;
      left: calc(100% - 19rem);
      width: calc(28rem + 15%);
      height: calc(28rem - 30%);
    }

    .content-col {
      flex-direction: column;
    }

    .content_col-nav {
      padding-left: 0 !important;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 1rem;
    min-height: 850px;
    max-height: 850px;

    .logo-footer {
      width: 17rem;
    }

    .logo__constru {
      position: relative;
      top: -11.1rem;
      left: calc(100% + 10rem);
      width: calc(33rem + 60%);
      height: calc(31rem - 13%);

    }

    .copy {
      height: 70px;
      position: relative;
      bottom: -17rem;
      border-top: 1px solid #00438d;

      span {
        display: block;
        width: 9rem;
        padding-top: 2rem;
      }
    }

    .sprint {
      margin-left: 1.5rem;
    }

    .col2 {
      nav {
        ul {
          li {
            a {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }


  @media (max-width: 768px) {
    .logo__constru{
      position: relative;
      top: -10.1rem;
      left: calc(100% + 10rem);
      width: calc(33rem + 60%);
      height: calc(31rem - 13%);
    }
   }
   
  @media (max-width: 576px) {
    .logo__constru{
      position: relative;
      top: -7.1rem;
      left: calc(100% + 10rem);
      width: calc(33rem + 60%);
      height: calc(31rem - 13%);
    }
    
  }

`;
const Add57 = (a) => {
   let values = "+57 "+a.split("").slice(2).join("");
   return values; 
}


const Anchor = styled.a`
  
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #1890ff !important;
    cursor: pointer;
  }
`;



const Footer = () => {
  const [config, setConfig] = useState({});
  
  const _fetch = async () => {
    const [CEL_PHONE, WHATSAPP, EMAIL] = await Promise.all([
      getCellPhone(),
      getWhatsapp(),
      getEmail(),
    ]);
    setConfig({
      CEL_PHONE,
      WHATSAPP,
      EMAIL,
    });
  };

  useEffect(() => {
    _fetch();
  }, []);

  return (
    <Wrapper>
        <div className="first" >
          <div className="content-col">
            <div className="col1">
              <img className="logo-footer" src={Logo} alt="logo.svg" />
              <div className="row">
                <ul
                  className="list_item-contact"
                  style={{paddingLeft: '1rem', lineHeight: '1.8rem'}}>
                  {/* <li className="item">
                    <img src={Phone} alt="phone.svg"/>
                    <span>{config.CEL_PHONE}</span>
                  </li> */}
                  <li className="item">
                    <img src={LogoWa} alt="Whatsapp.svg"/>  
                    <Anchor href='https://wa.link/5gbj33' target="_blank">{config.WHATSAPP && Add57(config.WHATSAPP)}</Anchor>
                  </li>
                  <li className="item">
                    <img src={Gmail} alt="Whatsapp.svg"/>  
                    <Anchor href='mailto:redes@camacolatlantico.org'>{config.EMAIL}</Anchor>
                  </li>
                  <li className="item">
                    <img src={Position} alt="point.svg"/>  
                    <Anchor href='https://www.google.com/maps/place/Parque+Industrial+Caribe+Verde/@10.9666361,-74.8231196,13z/data=!4m10!1m2!2m1!1sconstrucaribe+!3m6!1s0x8ef5d32da18c4149:0x37128b48425f2bcc!8m2!3d10.95489!4d-74.8374466!15sCg1jb25zdHJ1Y2FyaWJlkgERYXBhcnRtZW50X2NvbXBsZXjgAQA!16s%2Fg%2F11c4c6t042?hl=es&entry=ttu' target="_blank">Barranquilla - Atlántico - Colombia</Anchor>
                  </li>
                </ul>
              </div>
            </div>

            <div className="content_col-nav">
              <div className="col2">
                <p style={{color: '#00438D', textTransform:"uppercase"}}>Acceso rapido</p>
                <nav>
                  <ul class="list_item-contact">
                    <li className="item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="item">
                      <Link to="/proyectos">Busca tu inmueble</Link>
                    </li>
                    <li className="item">
                      <Link to="/noticias">Noticias del sector</Link>
                    </li>
                    <li className="item">
                      <Link to="/revista">Revista Construcaribe</Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="col2 sprint">
                <p style={{color: '#00438D', textTransform:"uppercase"}}>Acceso rapido</p>
                <nav>
                  <ul class="list_item-contact">
                    <li className="item">
                      <a
                        target="_blank"
                        href="https://construcaribe.s3.amazonaws.com/assets/TRATAMIENTO%20DE%20BASES%20DE%20DATOS.pdf">
                        Política de privacidad
                      </a>
                    </li>
                    <li className="item">
                      <Link to="">Preguntas frecuentes</Link>
                    </li>
                    <li className="item">
                      <Link to="">Términos y condiciones</Link>
                    </li>
                    <li className="item">
                      <Link to="contacto">Pauta con nosotros</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="copy">
            <span>Construcaribe © 2023 - Diseñado por <Anchor href="https://www.digitraffic.co/" target='_blank' style={{textDecoration:"none"}}>Digitraffic</Anchor></span>
          </div>
        </div>
        <div className="second">
          <div className="col">
            <p style={{color: '#00438D', width: 'max-content',textTransform:"uppercase"}}>Redes sociales</p>
            <div className="social-media">
              <Instagram />
              <Facebook />
            </div>
            <img
              className="logo__constru"
              src={LogoFooter}
              alt="LogoFooter.svg"
            />
          </div>
        </div>
    </Wrapper>
  );
};

export default Footer;
