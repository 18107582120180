import React from 'react';
import styled from 'styled-components';
import {H3} from '@bootstrap-styled/v4';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.info('error', {error, errorInfo});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper className="d-flex justify-content-center align-items-center">
          <H3>Se produjo un error.</H3>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
