import React from 'react';
import {Thead, Tr, Th, Tbody, Td} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import BaseTable from '../account/BaseTable';
import {formatDate} from '../../modules/utils';
import {BASE_BUCKET_URL} from '../../constants/global';

const TdImage = styled(Td)``;

const Message = styled.p`
  max-width: 416px;
  max-height: 150px;
  overflow-y: auto;
  text-align: left !important;
`;

const Image = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  background-color: #f2f2f2;
`;

const ContactTable = ({contacts = [], ...props}) => {
  return (
    <BaseTable {...props}>
      <Thead>
        <Tr>
          <Th className="text-left">ID</Th>
          <Th className="text-left">Proyecto</Th>
          <Th className="text-left">Datos de contacto</Th>
          <Th className="text-left">Mensaje</Th>
        </Tr>
      </Thead>
      <Tbody>
        {contacts.map(contact => (
          <Tr key={contact.id.toString()}>
            <Th scope="row">{contact.id}</Th>
            <Td>
              <div className="d-flex align-items-center">
                <Image
                  src={`${BASE_BUCKET_URL}${contact.project.image}`}
                  alt="proyecto"
                />
                <span className="ml-3">{contact.project.name}</span>
              </div>
            </Td>
            <Td>
              <p className="text-left">
                {contact.full_name} <br /> {contact.phone}
                <br /> {contact.email}
              </p>
            </Td>
            <Td>
              <Message>
                <span className="text-muted d-block">
                  {formatDate(contact.createdAt)}
                </span>
                {contact.message}
              </Message>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </BaseTable>
  );
};

export default ContactTable;
