import React, { useState, useEffect, useRef } from 'react';
import Splitting from 'splitting';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import LensIcon from '../../components/icons/LensIcon';
import { searchClient } from '../../config';
import { createURL, searchStateToUrl } from '../../modules/utils';
import { navigate } from '@reach/router';
import { getService } from '../../services/services';
import { message } from 'antd';
import { URL_S3, DEFAULT_COVER } from '../../constants';
import { isMobile } from 'react-device-detect';
import { Block } from '@material-ui/icons';
// import styled from 'styled-components';
// import {H2, Button} from '@bootstrap-styled/v4';
// import {navigate} from '@reach/router';


const Banner = () => {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];


  let titleElem = useRef(null);
  let filtersElem = useRef(null);
  const [searchState, setSearchState] = useState({});
  const [images, setImages] = useState({});
  const [route, setRoute] = useState('');
  const getImage = field => {
    const service = getService('design');
    service
      .find({ query: { view: 'home' } })
      .then(response => {
        setRoute(response.data[0].url);
        if (isMobile) {
          setImages({ [field]: response.data[0].path_mobil });
        } else {
          setImages({ [field]: response.data[0].path_desktop });
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  useEffect(() => {
    const [results] = Splitting({ target: titleElem.current, by: 'lines' });

    getImage('cover', 1);
    return () => { };
  }, []);

  return (
    <a className='row' href={route} target="_blank">
      <img src={`${URL_S3}/${images["cover"]}`} />
    </a>
  );
};


export default Banner;
