import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

const TextSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton width="100%" />
      <Skeleton width="60%" />
      <Skeleton width="90%" />
      <Skeleton width="80%" />
      <Skeleton width="90%" />
    </React.Fragment>
  );
};

export default TextSkeleton;
