import React, {useState, useEffect} from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import Uploader from 'react-s3-uploader/s3upload';
import styled from 'styled-components';
import {Upload, Button, Icon, Avatar, Card, message, Divider} from 'antd';
import './fileupload.css';

import { BASE_URL_API } from '../../../../../constants/index';

const Wrapper = styled.div`
  flex-grow: 1;
`;
const CustomLabel = props => (
  <label
    {...props}
    className="label-s3uploader"
    htmlFor={props.idComponent}
    className="flat-button-file"
    variant="outlined"
    color="primary">
    {props.children}
  </label>
);
const CustomLabelStyled = styled(CustomLabel)`
  letter-spacing: 0;
  /* color: #000000;
  text-transform: uppercase;
  opacity: 1; */
  color: #000000;
  text-transform: uppercase;
  opacity: 0.3;
  font-size: 0.8125rem;
  margin-bottom: 10px;
  padding: 0 8px;
`;

const serverS3 = 'https://construcaribe.s3.amazonaws.com/';
const server = `${BASE_URL_API}`;
const defaultTypes = ['image/jpg', 'image/png', 'image/jpeg'];
const S3Uploder = ({maxSize = 20, allowTypes = defaultTypes, ...props}) => {
  let [signedUrl, setSignedUrl] = useState(null);
  let {headers, loader = true} = props;
  let [uploading, setUploading] = useState(false);
  let [image_path, setPreview] = useState(props.initialValue);
  let [filename, setFileName] = useState(null);

  let {value} = props;
  const uploader = new Uploader({
    signingUrl: '/s3Client/sign',
    signingUrlMethod: 'GET',
    accept: 'image/jpg,image/jpeg,image/png',
    s3path: props.path,
    signingUrlWithCredentials: true,
    contentDisposition: 'auto',
    uploadRequestHeaders: {
      'x-amz-acl': 'public-read',
    },
    onProgress: progress => {
      console.log('Progress: ', progress);
    },
    onFinishS3Put: function(signResult, file) {
      let {filename, name} = signResult;
      setPreview(filename);

      setUploading(false);
      if (props.onChange) {
        props.onChange(filename);
      }
    },
    /* scrubFilename: filename => filename.replace(/[^\w\d_\-.]+/gi, ''), */
    server,
  });
  const handleCahnge = info => {
    let {server, signingUrl} = uploader;
    fetch(`${server}${signingUrl}`)
      .then(response => {
        return response.json();
      })
      .then(function(data) {
        let {signedUrl, filename} = data;
        console.log(data);
        setSignedUrl(signedUrl);
      });
  };
  const uploadToS3 = data => {
    let {file} = data;
    let filename = file.name;
    console.log('----> FileName: ', file, filename);

    maxSize = maxSize || 20;
    allowTypes = allowTypes || defaultTypes;
    console.log('FILE: ', file);

    const isJpgOrPng = allowTypes.includes(file.type);
    if (!isJpgOrPng) {
      return message.error('You can only upload JPG/PNG file!');
    }
    const isLtSize = file.size / 1024 / 1024 < maxSize;
    if (!isLtSize) {
      return message.error('Image must smaller than 2MB!');
    }
    try {
      setFileName(filename);
      setUploading(true);
      uploader.uploadFile(file);
    } catch (err) {
      console.log('ERROR: ', err);
      setUploading(false);
    }
    return console.log('file:', file);
  };
  return (
    <div className="card-container">
      {!loader ? (
        <>
          <CustomLabelStyled idComponent={props.idComponent}>
            {props.children}
          </CustomLabelStyled>
          {
            <label className="s3Button">
              <ReactS3Uploader
                id={props.idComponent}
                signingUrl="/s3Client/sign"
                signingUrlMethod="GET"
                accept="image/jpg,image/jpeg,image/png"
                s3path={props.path}
                /*
                preprocess={onUploadStart}
                onSignedUrl={onSignedUrl}
                onProgress={onUploadProgress}
                onError={onUploadError}
                onFinish={onUploadFinish} */
                signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
                uploadRequestHeaders={{
                  'x-amz-acl': 'public-read',
                }} // this is the default
                contentDisposition="auto"
                scrubFilename={filename =>
                  filename.replace(/[^\w\d_\-.]+/gi, '')
                }
                server={`${server}/`}
                autoUpload={true}
                style={{visibility: 'hidden'}}
              />
            </label>
          }
        </>
      ) : (
        <Card
          className={
            props.className ? 'card-type ' + props.className : 'card-type'
          }
          style={props.style || {width: '100%'}}
          cover={
            value ? (
              <>
                <div className="cover-type">
                  <div
                    style={{
                      backgroundImage: `url(${serverS3}${value})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
                {filename && <span className="file_name">{filename}</span>}
              </>
            ) : (
              <Avatar className="avatar-icon" size={150} icon="build" />
            )
          }>
          <Upload
            showUploadList={false}
            onChange={handleCahnge}
            customRequest={uploadToS3}>
            <>
              <Button
                size={props.size || 'large'}
                className="btn-file-upload"
                loading={uploading}>
                {!uploading && <Icon type="upload" />}{' '}
                {!uploading
                  ? filename || value
                    ? 'Actualizar Foto'
                    : 'Subir Foto'
                  : 'Cargando...'}
              </Button>
              <div>
                {`Solo se permiten archivos en formato ${allowTypes.map(it =>
                  it.substring(it.indexOf('/') + 1, it.length),
                )} de maximo ${maxSize}MB `}
              </div>
            </>
          </Upload>
        </Card>
      )}
    </div>
  );
};

const Field = ({name, label, hasFormik = false, ...props}) => {
  let [initialized, setInitialValues] = useState(false);
  return (
    <Wrapper>
      <div mb={1}>
        <S3Uploder {...props} />
      </div>
    </Wrapper>
  );
};
export default Field;
