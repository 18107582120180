export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_AUTHENTICATE = 'SET_AUTHENTICATE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const START_LOADING_USER = 'START_LOADING_USER';
export const END_LOADING_USER = 'END_LOADING_USER';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const CHANGE_PATH = 'CHANGE_PATH';
