import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './views/Home';
import Favorites from './views/Favorites';
import ProjectDetail from './views/ProjectDetail';
import SimulateYourCredit from './views/SimulateYourCredit';
import Contact from './views/Contact';
import Projects from './views/Projects';
import News from './views/News';
import NewsDetail from './views/NewsDetail';
import BaseAccount from './views/BaseAccount';
import {Router, createHistory} from '@reach/router';
import styled from 'styled-components';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './App.css';
import MyContactAccount from './views/MyContactAccount';
import MyProjectsAccount from './views/MyProjectsAccount';
import CompareProjects from './views/CompareProjects';
import Notifications from './components/Notifications';
import NotFound from './views/NotFound';
import {retryLoginAndGetCurrentUser} from './actions/auth';
import ProtectedLink from './components/routes/ProtectedLink';
import ProjectRequest from './views/ProjectRequest';
import ProjectEditRequest from './views/ProjectEditRequest';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'


import {GA_APP_KEY} from './utils/Constants';
/* Google Analitics */
import ReactGA from 'react-ga';
import Magazine from './views/Magazine';

library.add(fas)
ReactGA.initialize(GA_APP_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

const Root = styled.div`
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const ScrollToTop = ({children, location}) => {
  ReactGA.pageview(location.pathname);
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

let history = createHistory(window);

const App = ({retryLoginAndGetCurrentUser, ...props}) => {
  useEffect(() => {
    retryLoginAndGetCurrentUser();
    return () => {};
  }, [retryLoginAndGetCurrentUser]);
  const onHandleLinkClick = e => {
    /*  alert("Hit??"+JSON.stringify(props.navigation)) */
  };
  return (
    <Root>
      <NavBar onHandleLinkClick={onHandleLinkClick} />
      <Router >
        <ScrollToTop path="/">
          <Home path="/" />
          <Favorites path="/mis-favoritos" />
          <ProjectDetail path="/proyectos/:slug" />
          <Contact path="/contacto" />
          <News path="/noticias" />
          <Magazine path="/revista" />
          <Projects path="/proyectos" />
          <CompareProjects path="/proyectos/comparar" />
          <NewsDetail path="/noticias/:slug" />
          <SimulateYourCredit path="/simula-tu-credito" />

          <ProtectedLink path="admin">
            <BaseAccount path="mi-cuenta">
              <MyProjectsAccount path="proyectos" />
              <MyContactAccount path="contactos" />
              <ProjectRequest path="proyectos/solicitar" />
              <ProjectEditRequest path="proyectos/:id/editar" />
            </BaseAccount>
          </ProtectedLink>
          <NotFound default />
        </ScrollToTop>
      </Router>
      <Footer />
      <Notifications />
    </Root>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    retryLoginAndGetCurrentUser: () => dispatch(retryLoginAndGetCurrentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
