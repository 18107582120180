import React from 'react';
import {
  Card,
  CardBlock,
  CardTitle,
  CardSubtitle,
  H2,
} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {formatDate} from '../modules/utils';
import {navigate} from '@reach/router';
// import { BASE_BUCKET_URL } from '../constants/global';
import {ProjectImage} from './com';
import Arrow from '../SvgImg/flechaCircle.svg';

const Wrapper = styled(Card)`
  background-color: transparent !important;
  cursor: pointer;
`;

const MyCardSubtitle = styled(CardSubtitle)`
  display: flex !important;
  justify-content: space-between !important;
  text-transform: capitalize;
  font-size: 0.8rem;
`;

const Image = styled(ProjectImage)`
  border-radius: 10px;
  object-fit: cover;
  background-color: #f2f2f2;
`;

const handlerTitle = title => {
  let titleList = title.split('');
  let firtsLetter = titleList?.shift();

  return firtsLetter?.toUpperCase() + titleList?.join('').toLowerCase();
};

const BasicCard = ({item = {}, children, alt, large, ...props}) => {
  return (
    <Wrapper
      {...props}
      onClick={() => navigate(`/noticias/${item.title_slug}`)}>
      <Image
        className="imagen-slider"
        size="medium"
        height={large ? '400px' : '217px'}
        src={item.cover_image}
        alt="Card image cap"
      />
      <CardBlock className="px-0">
        <CardTitle
          tag={H2}
          className="font-weight-bold"
          style={{fontSize: '1.2rem'}}>
          {handlerTitle(item.title)}
        </CardTitle>
        <MyCardSubtitle className="text-muted font-weight-normal">
          {formatDate(item.createdAt)}
          <img src={Arrow} />
        </MyCardSubtitle>
      </CardBlock>
    </Wrapper>
  );
};

export default BasicCard;
