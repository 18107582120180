import axios from 'axios';
import {URL_CAMACOL_API} from './Constants';
const Axios = axios.create({
  baseURL: URL_CAMACOL_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default Axios;
