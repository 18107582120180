import React, {useState, useEffect} from 'react';
import {Select} from 'antd';
import styled from 'styled-components';
import {ErrorMessage, Field as FormikField} from 'formik';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ErrorField from '../formik/ErrorField';
import {Box} from '@material-ui/core';
import './select.css';
const {Option} = Select;

/* let customStyle = {
  minWidth: '200px',
}; */
const CustomSelect = props => {
  let dataSource = props.dataSource || [];
  let {valueField = 'value', displayField = 'label'} = props;

  return (
    <Select
      className="select-field"
      {...props}
      dropdownClassName="menu-select"
      suffixIcon={<KeyboardArrowDownIcon />}
      notFoundContent={
        <div
          style={{
            padding: 10,
          }}>
          No options
        </div>
      }
      showSearch
      placeholder="Select a person"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      /*  onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
    */
    >
      {dataSource.map(item => (
        <Option key={item[valueField]} value={item[valueField]}>
          {item[displayField]}
        </Option>
      ))}
    </Select>
  );
};

const Label = styled.div`
  letter-spacing: 0;
  /* color: #000000;
  text-transform: uppercase;
  opacity: 1; */
  color: #000000;
  text-transform: uppercase;
  opacity: 0.3;
  font-size: 0.8125rem;
  margin-bottom: 10px;
  padding: 0 8px;
`;
const Wrapper = styled.div`
  flex-grow: 1;
`;
const Field = ({name, label, hasFormik = false, ...props}) => {
  let [initialized, setInitialValues] = useState(false);
  return (
    <Wrapper>
      <Label>{label}</Label>
      {hasFormik ? (
        <React.Fragment>
          <FormikField
            name={name}
            render={({field, form}) => {
              let {initialValues, values} = form;
              if (initialValues[name] && !initialized) {
                if (
                  initialValues[name] &&
                  initialValues[name] != '' &&
                  name &&
                  name in initialValues
                )
                  form.setFieldValue(name, initialValues[name]);
                setInitialValues(true);
              }
              return (
                <CustomSelect
                  defaultValue={initialValues[name]}
                  onChange={value => {
                    if (name) form.setFieldValue(name, value);
                  }}
                  {...props}
                />
              );
            }}
          />
          {/* component={CustomSelect} /> */}
          <ErrorField name={name} />
        </React.Fragment>
      ) : (
        <Box mb={1}>
          <CustomSelect {...props} />
        </Box>
      )}
    </Wrapper>
  );
};

export default Field;
