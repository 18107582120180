import * as React from "react"

const SvgComponent = (props) => (
  <a href=" https://www.facebook.com/construcaribeoficial" target="_blank" style={{ cursor: "pointer", zIndex: 10 }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      {...props}
    >
      <path
        fill="#00438D"
        d="M28 14.058c0 6.934-5.088 12.683-11.73 13.726v-9.683h3.23l.615-4.006H16.27v-2.6c0-1.096.538-2.164 2.258-2.164h1.748V5.92s-1.587-.271-3.102-.271c-3.166 0-5.234 1.919-5.234 5.391v3.054H8.42V18.1h3.519v9.683C5.3 26.74.21 20.991.21 14.058.21 6.384 6.432.163 14.105.163 21.78.163 28 6.383 28 14.058Z"
      />
    </svg>
  </a>
)
export default SvgComponent