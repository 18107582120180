import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={15}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m5.5 14 .473-.577a41.222 41.222 0 0 0 1.45-1.895l.355-.505C9.259 8.866 10 7.155 10 5.89 10 3.19 7.985 1 5.5 1S1 3.19 1 5.89c0 1.265.74 2.976 2.222 5.133l.355.505C4.13 12.298 4.77 13.123 5.5 14Z"
      clipRule="evenodd"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.5 7.889c1.036 0 1.875-.907 1.875-2.026 0-1.12-.84-2.026-1.875-2.026-1.036 0-1.875.907-1.875 2.026 0 1.119.84 2.026 1.875 2.026Z"
    />
  </svg>
)
export default SvgComponent