import React, {Component} from 'react';
import {Form, Row, Col, Button, Divider} from 'antd';
import './form.css';
import {debounce} from 'lodash/function';

const footerStyle = {
  marginTop: 20,
};
class AdvancedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      formLayout: 'vertical',
    };
    this.changeDebouce = debounce(this.changeDebouce, 800);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      /* alert(JSON.stringify(values)); */
      if (typeof this.props.onSubmit !== 'undefined') {
        this.props.onSubmit(err, values);
        //if (!err) this.props.form.resetFields();
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };
  toggle = () => {
    const {expand} = this.state;
    this.setState({expand: !expand});
  };
  changeDebouce = (key, value) => {
    if (this.props.onChange) {
      this.props.onChange(key, value);
    }
  };
  handleChange = (key, value) => {
    this.props.form.setFieldsValue({
      [key]: value,
    });
    this.changeDebouce(key, value);
  };
  checkdate = (rule, value, callback) => {
    if (value) {
      callback();
      return;
    }
    callback('Date is required!');
  };
  // To generate mock Form.Item
  getFields() {
    const me = this;
    const {getFieldDecorator} = this.props.form;
    const {children} = this.props;
    let childrens = [];

    let {formLayout} = this.state;
    formLayout = this.props.formLayout || formLayout;

    //console.log("formLayout:: ",formLayout);
    const formItemLayout =
      formLayout === 'vertical'
        ? {
            span: 12,
          }
        : {
            span: 24,
          };

    if (typeof children !== 'undefined') {
      /* if (formLayout === "inline") alert(JSON.stringify(formItemLayout)); */
      try {
        let {props} = children;
        let childNodes = props.children || [];
        childrens = React.Children.map(childNodes, (child, index) => {
          if (!child) return child;
          let {
            name,
            label,
            required,
            message,
            validations,
            style,
            initial,
            initialValue,
            onChange,
            type,
            xtype,
            flex,
            mode,
          } = child.props;

          name = typeof name === 'undefined' ? `field_${index}` : name;

          style = style || {};

          /* InitialValue Map */
          let {latName = 'lat', lngName = 'lng'} = child.props;
          let {form} = me.props;
          if (xtype === 'map') {
            let values = form.getFieldsValue([latName, lngName]);
            /* form.setFieldsValue({ [lngName]: values[lngName] });
            form.setFieldsValue({ [latName]: values[latName] }); */
            initialValue = Object.values(values);
            console.log('Values: ', initialValue, values);
          }

          validations = validations || [
            {
              required: required || this.props.allRequired || false,
              message: message || `${label} es requerido`,
            },
          ];
          if (type === 'email') {
            validations.push({
              type: 'regexp',
              pattern: new RegExp(
                '^([a-zd.-]+)@([a-zd-]+).([a-z]{2,8})(.[a-z]{2,8})?$',
              ),
              message: 'Wrong format!',
            });
          }
          delete child.value;
          if (xtype === 'hidden')
            style = {
              width: '100%',
              /* display:"none", */
              height: 0,
              margin: 0,
              padding: 0,
            };

          if (flex <= 1) style['width'] = `${flex * 100}%`;
          if (type === 'hidden') style['height'] = 0;
          return (
            <Col
              {...formItemLayout}
              key={index}
              style={{...style, padding: 5, display: 'inline-block'}}
              className={
                typeof child.className !== 'undefined'
                  ? 'item-form ' + child.className
                  : 'item-form'
              }>
              <Form.Item label={label} style={{width: '100%'}}>
                {getFieldDecorator(name || `field-${index}`, {
                  initialValue: initialValue || initial,
                  /*  trigger: "focus", */
                  validateTrigger: 'onChange',
                  /* trigger: "dateChange", */
                  valuePropName: 'value',

                  rules: validations,

                  onChange: function(key, value) {
                    if (xtype === 'map') {
                      let {form} = me.props;
                      /*  let { latName = "lat", lngName = "lng" } = child.props; */
                      value = key;
                      let {lng, lat} = value;
                      form.setFieldsValue({[lngName]: lng});
                      form.setFieldsValue({[latName]: lat});
                      console.log('----> ', key);
                    }
                    if (value)
                      if (typeof value === 'object') {
                        if (Array.isArray(value)) {
                          if (mode === 'multiple') {
                            /* SelectField */
                            value = value.map(item => {
                              let {props} = item;
                              return props.value;
                            });
                            console.log('VALUE:: ', value);
                          }
                          value = value.join(',');
                        } else {
                          value = key;
                        }
                      }

                      
                      me.handleChange(name, key);
                    if (typeof key === 'object')
                      if ('target' in key) value = key.target.value;
                    if (typeof onChange !== 'undefined') onChange(name, value);
                  },
                })(
                  React.cloneElement(child, {
                    style: {width: '100%'},
                    trigger: 'focus',
                    form,
                  }),
                )}
              </Form.Item>
            </Col>
          );
        });
      } catch (err) {
        console.log('ERROR: ', err);
      }
    }

    return childrens;
  }

  componentDidMount() {
    let {formLayout} = this.props;
    if (typeof formLayout !== 'undefined')
      this.setState({
        formLayout,
      });
  }
  componentWillUnmount() {
    this.props.form.resetFields();
  }
  render() {
    const {formLayout} = this.state;
    let {
      footer,
      className,
      style,

      textAcceptButton = 'GUARDAR',
      submitting = false,
      autoSubmit,
    } = this.props;
    /*     let autoSubmit = this.props.autoSubmit;
     */
    return (
      <Form
        ref={this.props.ref}
        className={className || 'advanced-form'}
        layout={formLayout}
        onSubmit={this.handleSubmit}
        style={style || {margin: formLayout === 'inline' ? 0 : 20}}>
        <Row
          type={formLayout === 'inline' ? 'flex' : undefined}
          justify={formLayout === 'inline' ? 'space-between' : 'start'}
          /* align="top" */

          gutter={8}>
          {this.getFields()}
        </Row>
        <Row
          className={
            typeof footer !== 'undefined' ? 'footer-advanced-form' : null
          }>
          {autoSubmit ? (
            <>
              <Row type="flex" justify="center">
                <Col span={24}>
                  <Divider
                    style={{
                      width: '100%',
                      margin: '20px 0px 20px 0px',
                    }}
                  />
                </Col>
                <Col span={24} align="center">
                  <Button
                    type="primary"
                    loading={submitting}
                    htmlType="submit"
                    className="login-form-button btn-green"
                    style={
                      this.props.buttonSubmitStyle || {
                        width: '32%',
                        height: 40,
                        padding: 5,
                        margin: 10,
                        textAlign: 'center',
                      }
                    }
                    size="large"
                    block>
                    {textAcceptButton}
                  </Button>
                </Col>
              </Row>
              <Row
                style={footerStyle}
                type="flex"
                justify="center"
                align="middle">
                <Col span={24}>{footer}</Col>
                <Col span={24}>
                  {!autoSubmit && (
                    <Divider
                      style={{
                        width: '100%',
                        margin: '20px 0px 20px 0px',
                      }}
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Row
              style={footerStyle}
              type="flex"
              justify="center"
              align="middle">
              <Col span={24}>{footer}</Col>
            </Row>
          )}
        </Row>
      </Form>
    );
  }
}
const WrappedAdvancedForm = Form.create({name: 'search_form'})(AdvancedForm);

export default WrappedAdvancedForm;
