import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import ProjectHeadline from '../components/projects/ProjectHeadline';
import Gallery from '../components/projects/Gallery';
import Divider from '../components/Divider';
import ProjectAbout from '../components/projects/ProjectAbout';
import SendMessageForm from '../components/projects/SendMessageForm';
import ProjectItems from '../components/projects/ProjectItems';
import ProjectInterest from '../components/projects/ProjectInterest';
/* SEO */
import SeoEngine from '../components/SeoEngine';
import { getService } from '../services/services';
import { message } from 'antd';
import { encode } from "base-64";
import { CLOUD_FRONT_URL, S3_BUCKET } from "../constants/global";
import { money } from "../utils/Helper";
import ProjecLocation from '../components/projects/ProjectLocation';

const Line = styled.div`
  /* background-image: url('/images/line-product-detail-two.png'); */
  height: 574px;
  width: 379px;
  position: absolute;
  top: 0;
  left: -10%;
`;
//By Joseph V.
const Frame = styled.div`
    width: 100%;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

`;
//By Joseph V.

const Content = styled(Container)`
  padding-top: 4.625rem;
  padding-bottom: 5.5625rem;
  box-sizing: border-box;
`;

const Relative = styled.div`
  position: relative;
`;

const ProjectDetail = ({ slug, location }) => {
    const [project, setProject] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const service = getService('projects');
        service
            .find({
                query: {
                    title_slug: slug,
                    status: 'Activo'
                },
            })
            .then(({ data }) => {
                if (data.length > 0) data = data[0];
                // console.log('project', data);
                setProject(data);
                setLoading(false);
            })
            .catch(err => {
                navigate('/not-found');
                message.error(err.message);
            });
    }, [slug]);



    const getImagePath = (json) => {
        json = JSON.stringify(json);
        const base64 = encode(json);
        return `${CLOUD_FRONT_URL}${base64}`;
    }

    return (
        <div>
            <SeoEngine
                title={project.name ? `${project.name} - Construcaribe` : 'Proyectos - Construcaribe'}
                description={
                    `${project.name} desde ${money(project.min_last_price_in_thousands)} y desde ${project.min_area_by_type} m2 ` +
                    `en ${project.neighborhood && project.neighborhood.name}, ${project.city && project.city.name} Estrato ${project.stratus}`
                }
                image={project.image ? getImagePath({
                    "bucket": S3_BUCKET,
                    "key": project.image,
                    "edits": {
                        "resize": {
                            "width": 1200,
                            "height": 627,
                            "fit": "cover"
                        }
                    }
                }) : project.image}
            />
            <Gallery cover={project.image} gallery={project.project_images} />
            <Frame>
                {/*!!project && console.log({project: project})*/}
                <ProjectHeadline
                    {...project}
                    location={location}
                    title={project.name}
                    generalUse={project.general_use}
                    neighborhood={project.neighborhood && project.neighborhood.name}
                    city={project.city && project.city.name}
                    stratus={project.stratus}
                    types={project.types}
                    loading={loading}
                    logo={project.path_logo}
                    projectId={project.id}
                />
            </Frame>
            {/* <Divider/> */}
            <Relative>
                <Line />
                <Content>
                    <Row>
                        <Col md={6} className="order-data-form">
                            <ProjectAbout
                                loading={loading}
                                about={project.project_description}
                                zoneInterestDescription={project.zones_interest_description}
                                adminCost={
                                    project.service_costs && project.service_costs.administrations
                                }
                                gasCost={project.service_costs && project.service_costs.gas}
                                electricityCost={
                                    project.service_costs && project.service_costs.electricity
                                }
                                waterCost={project.service_costs && project.service_costs.water}
                                commonZones={project.common_zones}
                                long={project._geoloc && project._geoloc.lng}
                                lat={project._geoloc && project._geoloc.lat}
                            />
                        </Col>
                        <Col md={6}>
                            <SendMessageForm
                                sellingCompany={project.selling_company}
                                loading={loading}
                                projectId={project.id}
                                location={project.address}
                                locationDescription={project.address_description}
                                neighborhood={project.neighborhood && project.neighborhood.name}
                                city={project.city && project.city.name}
                                logo={
                                    project.selling_company && project.selling_company.path_logo
                                }
                                zonesInterest={project.zones_interest}
                            />
                        </Col>
                    </Row>
                </Content>
            </Relative>
            {project.types && project.types.length > 0 && (
                <ProjectItems
                    items={project.types.filter(it => it.status == 'Activo')}
                />
            )}
            {project && project.stratus && <ProjectInterest project={project} />}
            {/* {/*<Banner/>*/}
        </div>
    );
};

export default ProjectDetail;
