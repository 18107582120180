import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  H6,
  InputGroupButton,
} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {
  InstantSearch,
  connectSearchBox,
  connectMenu,
  Configure,
  connectRefinementList,
} from 'react-instantsearch-dom';
import { navigate } from '@reach/router';
import InputText from '../components/InputText';
import LensIcon from '../components/icons/LensIcon';
import Checkbox from '../components/Checkbox';
import SelectLabel from '../components/SelectLabel';
import { searchClient } from '../config';
import AgClearRefinements from '../components/algoliaRefinements/AgClearRefinements';
import { AlgoliaPagination } from '../components/AppPagination';
import AgProjectsHits from '../components/algoliaRefinements/AgProjectsHits';
import AgRange from '../components/algoliaRefinements/AgRange';
import { createURL, searchStateToUrl, urlToSearchState } from '../modules/utils';
import AgGoogleMaps from '../components/algoliaRefinements/AgGoogleMaps';
import { Icon, message } from 'antd';
import { isMobile } from 'react-device-detect';
/* SEO */
import SeoEngine from '../components/SeoEngine';
import BannerTop from '../components/commons/banner';

//SLIDER
import Slider from 'react-slick'
import { getService } from '../services/services';

//prueba 
import { URL_S3, DEFAULT_COVER } from '../constants';
import Filter from '../SvgImg/filtros.svg';
import Closed from '../SvgImg/closed.svg';

// CONFIGURACIÓN DEL SLICK PARA LOS BANNERS PRINCIPALES
const RowMap = styled.div`
  display: ${props => (props.isMobile ? 'none' : 'block')};
  height: '900px';
  width: 100%;
  background-color: '#f0f0f0';
`;

const MyForm = styled(Form)`

    border: 1px solid #f0f0f0;
    border-radius: 10px;
    padding: 1rem;
    background-color: #f6f6f6;

  /* @media(max-width: 990px) {
     display: none;
  } */
`;

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  cssEase: "linear"
}

const WrapperMap = styled(Col)`
  /* position: absolute !important; */
  /* right: 0;
  top: 0; */
  z-index: 3;
  padding: 0 !important;

  & .ais-GeoSearch,
  & .ais-GeoSearch-map {
    height: 100%;
  }

  & .ais-GeoSearch-control {
    display: none !important;
  }

  @media (max-width: 768px) {
    position: relative !important;
  }
`;

const WrapperProjects = styled.div`

  overflow-y: none;
  @media (max-width: 768px) {
   
  }
`;

const Wrapper = styled.div`
  /* position: relative; */
  height: auto;
  min-height: 1129px;

  @media (max-width: 768px) {
    min-height: auto;
  }
  @media (max-width: 425px) {
    & .form-search-open {
      position: fixed;
      left: 0;
      top: 40px;
      z-index: 98;
      padding: 10px;

      background: #f0f0f0;
      box-shadow: 3px 3px 3px #ccc;
      border: 1px solid #ccc;

      overflow-y: scroll !important;
      min-width: 100% !important;
      padding-bottom: 150px !important;
      max-height: 95vh !important;
    }

    & .form-search-open .form-check-label {
      min-width: 50% !important;
    }

    & .input-group-btn {
      display: flex !important;
    }
    & .form-search {
      position: absolute;
      left: 5px;
      top: -85px;
      z-index: 98;
      padding: 10px 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
    }
    & .group-fields .form-group .bIhspk svg {
      padding: 10px !important;
      width: 100% !important;
      position: absolute !important;
      width: 40px !important;
      height: 40px !important;
      left: -10px !important;
      top: -18px !important;
      overflow: hidden !important;
    }
    & .group-fields .form-group {
      margin: 0px !important;
      padding: 15px 0px !important;
    }
    & .search-project {
      margin-top: 0px !important;
    }
    & .jIJGvA {
      /* box-shadow: 3px 3px 3px #ccc; */
    }
    & .search-project .jIJGvA {
      display: none !important;
    }
    & .gvLdgB.mt-4 {
      margin-top: 0px !important;
    }
    & .form-search .group-fields .form-group {
      width: 45px !important;
    }
    & .form-search .group-fields-row,
    .form-search .bTAAPD,
    .form-search .form-check,
    .form-search .vKqDP,
    .form-search .dOBUxZ {
      visibility: hidden !important;
      width: 0px !important;
      height: 0px !important;
    }
    & .form-search .neighborhood {
      display: none;
    }
    & .form-search .city {
      display: none;
    }
    /* Open */
    & .form-search-open .dOBUxZ {
      justify-content: center !important;
      margin-bottom: 0px !important;
    }
    & .form-search-open .group-fields .form-group {
      width: 100% !important;
      display: block !important;
    }
    & .form-search-open .jIJGvA {
      display: block !important;
    }
    & .form-search-open .search-project .group-fields-row {
      display: block !important;
    }
    & .form-search-open .search-project .group-fields {
      display: block !important;
    }
  }
`;

const ProjectsContainer = styled(Container)`
  /* max-width: 100% !important; */
  margin-top: 1.6875rem;

  @media (min-width: 1200px) {
    max-width: 1440px !important;
  }

`;

const ProjectsOptions = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProjectSelectLabel = styled(SelectLabel)`
  min-width: 150px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const ClearWrapper = styled(Col)`
  flex-grow: 0 !important;
`;

const SearchButton = styled(InputGroupButton)`
  visibility: hidden !important;
  width: 0 !important;
  display: none;
  padding: 0px;
  margin: 0px;
  display: flex !important;
  justify-content: center;

  @media (max-width: 425px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
    z-index: 0 !important;
    margin: 16px 10px;
    width: 100% !important;
    visibility: visible !important;
  }
`;
const FilterButton = styled(InputGroupButton)`
  visibility: hidden !important;
  width: 0 !important;
  display: none;
  padding: 0px;
  margin: 0px;

  & i {
    margin: 4px;
    vertical-align: middle !important;
  }

  @media (max-width: 425px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
    z-index: 0 !important;
    margin: 16px 10px;
    width: 100% !important;

    padding: 10px;
    border-radius: 8px;

    display: flex !important;
    justify-content: center;

    visibility: visible !important;
  }
`

const GridContainer = styled.div`
  display: grid;
  height:auto;
  width: auto;
  grid-template-columns: minmax(min-content, 1px) 4fr;
  grid-template-areas: 
    "BannerAside Body";
`
const DivBody = styled.div`
  grid-area:Body;
  display:flex;
  flex-direction:column;
  height:100%;
  width:100%;
  & > div{width:100%;}
`;
const DivAside = styled.div`
  grid-area:BannerAside;
  display:block;
  position:sticky;
  height:100%;
  max-height: 1160px;
  width:250px;
  background-color:#fff;
`;

//-------------------------------------------------------
const ContentProject = styled.div`
 width:100%; 
 display:flex; 
 flex-direction:column; 
 margin-left: 1rem; 

 .content_project {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  //grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 1rem; 
  grid-template-areas: 
    ". . ."
    ". . ."
    ". . ."; 
 }

 @media(max-width: 990px){

    margin-left: 0; 

    .content_project {  
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 1rem;
        grid-auto-flow: row;
        grid-template-areas:
        ". ."
        ". ."
        ". ."
        ". ."
        ". .";
    }
  }

  @media(max-width: 782px){

    margin-left: 0; 

    .content_project {
      margin: 0 1rem;
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "."
        "."
        "."
        "."
        "."
        "."
        "."
        "."; 
    }
}
                        
`;

const FormContent = styled.div`
    width: 30%;
    min-width: 300px !important;

    @media(max-width: 1000px){
      position: absolute;
      z-index: 1;
      width: 90%;
    }
`;

const MyCol = styled(Col)`
    display: flex;

    @media(max-width: 1000px){
      justify-content: center;
    }

`;


const ButtonClosed = styled.div`

    display: flex;
    justify-content: flex-end;
    padding: 0 0.5rem;
    margin: 0.5rem 0;
    font-size: 1.1rem;
    

    button {
      display: none;
      border: none;
      background: none;
    }

    @media(max-width: 1000px){
      button {
        display: block;
      }
    }
`;

const InvokeFilter = styled.div`

    background-color: #00438d; 
    position: fixed;
    top: 76px;
    left: -45px;
    z-index: 142;
    width: 10%;
    height: 2.7rem;
    display: flex;
    justify-content: flex-end;
    border-radius: 0 10px 10px 0;
    visibility: hidden; 

    &:hover{
      left: -26px;
    }

    &:focus{
      left: -26px;
    }

    button {
      border: none;
      background: none;
    }

    @media(max-width: 1000px){
      visibility: visible;

      button {
        visibility:'visible';
      } 
    }

    @media(max-width: 576px){
      width: 19%;
    }
`;

//-------------------------------------------------------

const NameTextField = ({
  onClick,
  visible,
  currentRefinement,
  refine,
  style,
}) => {
  return (
    <InputText
      style={{
        // opacity: 
      }}
      type="search"
      value={currentRefinement}
      /* onClick={onClick} */
      onChange={event => refine(event.currentTarget.value)}
      icon={
        <LensIcon
          style={
            {
              /* padding: 8,
        height: "100%",
        width: "100%", */
            }
          }
          onClick={() => (!visible ? onClick() : null)}
        />
      }
      placeholder="Nombre de proyecto y más..."
    />
  );
};

const NameTextFieldSearchBox = connectSearchBox(NameTextField);

const MenuSelect = ({
  items,
  currentRefinement,
  refine,
  label,
  placeholder,
  style,
  className,
}) => {

  if(placeholder === 'Vis' || className === 'Vis'){
    items = items.filter(e => e.label !== '2')
  }
  return (
    <ProjectSelectLabel
      className={className}
      style={style}
      options={items.filter(it => {
        return it.label != 'true';
      })}
      label={label}
      value={{ value: currentRefinement, label: currentRefinement }}
      onChange={event => refine(event.value)}
      placeholder={placeholder}
    />
  );
};

const AgMenuSelect = connectMenu(MenuSelect);

const RefinementList = ({ items, currentRefinement, refine }) => (
  <React.Fragment>
    {items.map(item => (
      <Checkbox styled={{ color: '#000' }}
        key={item.label}
        checked={item.isRefined}
        onClick={event => refine(item.value)}
        label={item.label}
      />
    ))}
  </React.Fragment>
);

const AgRefinementList = connectRefinementList(RefinementList);

const Product = [
  {
    id: '1',
    img: 'https://img.kytary.com/eshop_es/mar/0/637818080594070000/c0d7e25a/64965442_pozadi-hudbou-proti-v%C3%A1lceL-es.jpg'
  },
  {
    id: '2',
    img: 'https://i.pinimg.com/474x/69/11/a2/6911a26b702fcedaa821157d0d54b927.jpg'
  },
]

const Projects = ({ location, history }) => {
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  const [search, setSearch] = useState(null);
  // const [cls, setCls] = useState('form-search');
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(true);


  const handleFilters = (event) => {
    event.preventDefault();
    setOpen(!open);
  }

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);
    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(location, updatedSearchState));
      }, 400),
    );
    setSearchState(updatedSearchState);
  };
  const handleOnClear = () => {
    setSearchState({});
    setSearch({});
  };
  const handleSearch = () => {
    setVisible(visible => !visible);
  };

  //custom state
  const [images, setImages] = useState([]);

  //function
  const getImage = () => {
    const service = getService("design");
    service.find({ query: { view: 'projects', position: 'left'} })
      .then(response => {
        setImages(response.data);
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  useEffect(() => {
    getImage();
  }, []);

  return (
    <div style={{ marginTop: '6rem' }}>
      <SeoEngine
        title={'Proyectos - Construcaribe'}
        description={
          'Encuentra todos los proyectos nuevos del atlántico en un solo lugar'
        }
      />
      <BannerTop path={"projects"} position={"top"} />
      <GridContainer>
        {!isMobile || images.length >= 1 && <DivAside>
          <Slider css={{ width: '100%', height: '1160px' }} arrows={false} {...settings}>
            {images.map((x, i) =>
              <a href={x.url} target="_blank">
                <img
                  style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                  key={x.id}
                  src={(x.path_desktop || x.path_mobile) ? `${URL_S3}/${(x.path_desktop || x.path_mobile)}` : DEFAULT_COVER}
                  alt='slider'
                />
              </a>
            )}
          </Slider>
        </DivAside>}
        <DivBody>
          <InstantSearch
            indexName="projects"
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
            css={{ width: '100%' }}
            searchClient={searchClient}>
            <Wrapper>
              <WrapperProjects id="wrapper-projects">
                <ProjectsContainer>
                  <Row className="row-flex-end">
                    <MyCol xs={12}>
                      <Configure
                        hitsPerPage={
                          9
                        } /*  restrictSearchableAttributes={['name']} */
                      />
                      {
                        open && (
                          <InvokeFilter>
                            <button onClick={handleFilters}>
                              <img src={Filter} alt="logo-filter.svg" />
                            </button>
                          </InvokeFilter>

                        )
                      }
                      <FormContent className={`${(open && isMobile) ? 'exit_form' : 'open_form'}`}>
                        <MyForm>
                          <ButtonClosed >
                            <button onClick={handleFilters}>
                              <img src={Closed} alt="closed.svg" />
                            </button>
                          </ButtonClosed>
                          <ProjectsOptions>
                            <FormGroup>

                              {/* --------------------SELECT--------------- */}

                              <div className='filtros-general-barrio-ciudad'>
                                <NameTextFieldSearchBox
                                  visible={visible}
                                  currentRefinement={search}
                                  onClick={handleSearch}
                                  style={{
                                    width: '100%',
                                    marginTop: '20px',
                                  }}
                                />

                                <AgMenuSelect
                                  className="city"
                                  attribute="city.name"
                                  label="Ciudad"
                                  placeholder="Ciudad"
                                />

                                <AgMenuSelect
                                  attribute="neighborhood.name"
                                  label="Barrio"
                                  placeholder="Barrio"
                                  className="neighborhood"
                                />
                              </div>

                            </FormGroup>
                            {/* --------------------SELECT--------------- */}


                            <FormGroup className="content_accordion">
                              <p className="add-filters">MAS FILTROS</p>
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                  <p className="accordion-header" id="headingOne">
                                    <button
                                      style={{ height: '40px' }}
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne">
                                      Tipo de inmueble
                                    </button>
                                  </p>
                                  <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                      {/* segundo grupo */}
                                      <FormGroup
                                        className="group-fields-row"
                                        style={{ width: '100%' }}>
                                        {
                                          <AgMenuSelect
                                            attribute="uses"
                                            style={{
                                              width: '100%',
                                              marginTop: '20px',
                                            }}
                                            label="Tipo de inmueble"
                                            placeholder="Tipo de inmueble"
                                          />
                                        }
                                        <AgMenuSelect
                                          className="stratus"
                                          style={{
                                            width: '100%',
                                            marginTop: '20px',
                                          }}
                                          attribute="stratus"
                                          label="Estrato"
                                          placeholder="Estrato"
                                        />

                                        <AgMenuSelect
                                          className="Vis"
                                          style={{
                                            width: '100%',
                                            marginTop: '20px',
                                          }}
                                          attribute="type_living_place"
                                          label="Tipo de vivienda"
                                          placeholder="Vis"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>

                                <div className="accordion-item" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                  <p className="accordion-header" id="headingTwo">
                                    <button
                                      style={{ height: '40px' }}
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="true"
                                      aria-controls="collapseTwo">
                                      Rangos de precio y área
                                    </button>
                                  </p>
                                  <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      {/* Tercer grupo */}
                                      <FormGroup
                                        className="group-fields-row"
                                        style={{
                                          width: '100%',
                                        }}>
                                        <AgRange
                                          label="Rango de área"
                                          attribute="min_area_by_type"
                                        />
                                        <AgRange
                                          label="Rango de precio"
                                          attribute="min_last_price_in_thousands"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item" style={{ marginBottom: '1rem' }}>
                                  <p className="accordion-header" id="headingTree">
                                    <button
                                      style={{ height: '40px' }}
                                      class="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTree"
                                      aria-expanded="true"
                                      aria-controls="collapseTree">
                                      Áreas comunes
                                    </button>
                                  </p>
                                  <div
                                    id="collapseTree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTree"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      <FormGroup check className="d-flex list-checks">
                                        <AgRefinementList attribute="common_zones.name" />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </ProjectsOptions>
                          <div style={{ width: '100%', marginBotton: '1rem' }}>
                            <div className="d-flex justify-content-center align-content-center w-100">
                              <AgClearRefinements onClear={handleOnClear} />
                            </div>
                          </div>

                        </MyForm>
                      </FormContent>
                      <ContentProject>
                        <div className='content_project'>
                          <AgProjectsHits />
                        </div>
                        <Row className="justify-content-center">
                          <AlgoliaPagination />
                          {/* <AppPagination page={1} total={1} /> */}
                        </Row>
                      </ContentProject>
                    </MyCol>
                  </Row>
                </ProjectsContainer>
              </WrapperProjects>
              {!isMobile && (
                <Row>
                  {/* FILA MAPA */}
                  <RowMap isMobile={isMobile}>
                    {!isMobile && (
                      <Row style={{ marginTop: '3rem' }}>
                        <WrapperMap xs={12} md={{ size: 12 }}>
                          <div
                            style={{
                              height: '660px',
                              width: '100%',
                            }}>
                            <AgGoogleMaps />
                          </div>
                        </WrapperMap>
                      </Row>
                    )}
                  </RowMap>
                </Row>
              )}
            </Wrapper>
          </InstantSearch>
        </DivBody>
        {/*<Banner/>*/}
      </GridContainer>
    </div>
  );
};

export default Projects;