/* FeatureForm.js
name,
use,
area_by_type,
last_price_in_thousands,
last_price_in_m2,
status,
createdAt,
deletedAt,
id,
image_path,
project_phase_id,
purpose,
ref_type_id,
unit_by_type,
updatedAt
 */

import React, { useState } from 'react';
import Form from '../com/Form';
import InputText from '../InputText';
import { Row, Col, InputNumber } from 'antd';
import Select from '../../components/select/Select';
import './formtype.css';
import S3Uploader from '../com/S3Uploader';
import qs from 'qs';
import { navigate } from '@reach/router';
const flexBox = {
    xxl: 8,
    xl: 8,
    lg: 8,
    mb: 12,
    xs: 12,
};
const uses = [
    {
        value: 'Apartamento',
        label: 'Apartamento',
    },
    {
        value: 'Casa',
        label: 'Casa',
    },
    {
        value: 'Bodega',
        label: 'Bodega',
    },
    {
        value: 'Oficina',
        label: 'Oficina',
    },
    {
        value: 'Habitaciones',
        label: 'Habitaciones',
    },
];
const status = [
    {
        value: 'ACTIVO',
        label: 'Activo',
    },
    {
        value: 'DESACTIVADO',
        label: 'Desactivado',
    },
];
const purposes = [
    {
        value: 'Venta',
        label: 'Venta',
    },
    {
        value: 'Arrendar',
        label: 'Arrendar',
    },
];
const FeatureForm = props => {
    let { data } = props;
    const refForm = React.createRef();
    return (
        <Form ref={refForm} className="form-type" {...props}>
            <Row gutter={8}>
                <Col {...flexBox}>
                    <InputText hasFormik
                        name="ref_project_type_id"
                        label="Referencia"
                        placeholder="Referencia"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText type="number" hasFormik name="study_rooms"
                        label="Estudio"
                        placeholder="Estudio"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText
                        type="number"
                        hasFormik name="service_room_and_bathroom"
                        label="Cuartos de Servicio"
                        placeholder="Cuartos de Servicio"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText
                        type="number"
                        hasFormik name="clothing_area"
                        label="Área de ropa"
                        placeholder="Área de ropa"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText
                        type="number"
                        hasFormik name="clothing_area"
                        label="Área de ropa"
                        placeholder="Área de ropa"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="bedrooms"
                        label="Alcobas"
                        placeholder="Alcobas"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="warehouse"
                        label="Almacén"
                        placeholder="Almacén"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="dining_room"
                        label="Comedor"
                        placeholder="Comedor"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="backyard"
                        label="Patio Interior"
                        placeholder="Patio Interior"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="bathrooms"
                        label="Baños"
                        placeholder="Baños"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="additional_office"
                        label="Oficina"
                        placeholder="Oficina"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="fireplace"
                        label="Chimenea"
                        placeholder="Chimenea"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="attic"
                        label="Ático"
                        placeholder="Ático"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="balcony_terrace"
                        label="Balcón terraza"
                        placeholder="Balcón terraza"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="mezzanine"
                        label="Mezzanine"
                        placeholder="Mezzanine"
                    />
                </Col>
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="kitchen"
                        label="Cocina"
                        placeholder="Cocina"
                    />
                </Col>
                {/* <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="vestier"
                        label="Baño Social"
                        placeholder="Baño Social"
                    />
                </Col> */}
                <Col {...flexBox}>
                    <InputText hasFormik
                        type="number"
                        name="bedrooms_expandable"
                        label="Cuarto expandible"
                        placeholder="Cuarto expandible"
                    />
                </Col>
            </Row>
        </Form>
    );
};
export default FeatureForm;
