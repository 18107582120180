import React from 'react';
import styled from 'styled-components';
import AreasOfInterestList from '../projects/AreasOfInterestList';
import IframeGoogleMap from '../IframeGoogleMap';

const Wrapper = styled.div`
  .title {
    background-color: #00438d;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.7rem;


    .title_text {
      margin: 0;
      color: #fff;
    }

    .text{
        margin: 0;
        text-align: center;
        font-size: 30px;
        color: #fff; 
    }
  }
`;

const ProjecLocation = ({
  long,
  lat,
  location,
  locationDescription,
  neighborhood,
  city,
  zonesInterest,
}) => {
  return (
    <Wrapper>
      <div className="content-wrapper_map">
        <div className="title">
          <div>
            <p className="text">Ubicacíon del proyecto</p>
            <p className="title_text">
              {neighborhood}, {city} - {locationDescription}
            </p>
          </div>
        </div>
        <IframeGoogleMap long={long} lat={lat} />
      </div>
      <div className="content-wrapper_zones">
        <AreasOfInterestList items={zonesInterest} />
      </div>
    </Wrapper>
  );
};

export default ProjecLocation;
