import * as React from "react"
const SvgComponent = (props) => (
  <a href="https://www.instagram.com/construcaribe/" target="_blank" style={{cursor:"pointer", zIndex:10}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      {...props}
    >
      <path
        fill="#00438D"
        d="M13.895 2.503c3.71 0 4.15.014 5.614.081 1.515.07 3.075.415 4.178 1.518 1.114 1.114 1.449 2.659 1.518 4.178.067 1.465.081 1.905.081 5.615s-.014 4.15-.08 5.614c-.07 1.507-.422 3.081-1.519 4.178-1.114 1.114-2.657 1.449-4.178 1.518-1.464.067-1.904.081-5.614.081s-4.15-.014-5.615-.08c-1.495-.07-3.09-.43-4.178-1.519-1.108-1.108-1.448-2.668-1.518-4.178-.067-1.464-.08-1.904-.08-5.614s.013-4.15.08-5.615c.069-1.5.425-3.085 1.518-4.178C5.214 2.991 6.764 2.654 8.28 2.584c1.465-.067 1.905-.08 5.615-.08Zm0-2.503c-3.774 0-4.247.016-5.73.083-2.147.099-4.28.696-5.833 2.249C.773 3.891.182 6.019.083 8.165.016 9.648 0 10.121 0 13.895c0 3.773.016 4.247.083 5.729.099 2.146.699 4.284 2.249 5.834 1.557 1.557 3.689 2.15 5.833 2.248 1.483.067 1.956.084 5.73.084 3.773 0 4.247-.017 5.729-.084 2.147-.098 4.282-.697 5.834-2.248 1.56-1.56 2.15-3.687 2.248-5.834.067-1.482.084-1.956.084-5.73 0-3.773-.017-4.246-.084-5.729-.098-2.147-.697-4.281-2.248-5.833C23.901.777 21.764.181 19.623.083 18.142.016 17.668 0 13.894 0Z"
      />
      <path
        fill="#00438D"
        d="M13.895 6.76a7.135 7.135 0 1 0 0 14.27 7.135 7.135 0 0 0 0-14.27Zm0 11.766a4.632 4.632 0 1 1 0-9.263 4.632 4.632 0 0 1 0 9.263ZM21.312 8.145a1.667 1.667 0 1 0 0-3.335 1.667 1.667 0 0 0 0 3.335Z"
      />
    </svg>
  </a>
)
export default SvgComponent