import React, {useEffect, useState, useRef} from 'react';
import {Container} from '@bootstrap-styled/v4';
import {Box, Tabs, Tab} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Headline from '../components/account/Headline';
import ProjectDataForm from '../components/projects/ProjectDataForm';
import Typography from '@material-ui/core/Typography';
import Api from '../services/api';
import {async} from 'q';
import ProjectFases from './ProjectFases';
import qs from 'qs';
import {navigate} from '@reach/router';
import {map} from 'rxjs/operators';
import {Input, message, Row, Col} from 'antd';
import {CRUD} from '../components/com';
import {Grid} from '../components/commons/';
import {
  getProjectSales,
  getZonesInterest,
  getProjectCommonZones,
  getCommonZones,
  createZoneInterst,
} from '../services/index';
import {
  projects_sales_company,
  projects_zones_interest,
  zones_interest,
  projects_common_zones,
} from '../services/services';
import {Button, Icon, Divider} from 'antd';
import ProjectImages from './ProjectImages';
import CommonZones from './common-zones/CommonZones';
import ProjectGallery from './project-gallery/ProjectGallery';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ProjectRequest = props => {
  const [loading, setLoading] = useState(false);
  const [loading_phases, setLoadingPhases] = useState(true);
  const [project, setProject] = useState({});
  const [phases, setPhases] = useState([]);
  const [value, setValue] = React.useState(0);
  let [zonename, setZoneName] = useState('');
  const ref = useRef(null);
  const getProject = async project_id => {
    setLoading(true);
    Api.projects.get(project_id).subscribe(data => {
      setProject(data);
      setLoading(false);
      getPhases(project_id);
    });
  };
  const getPhases = project_id => {
    setLoadingPhases(true);
    Api.phases
      .find({
        query: {
          $sort: {
            createdAt: -1,
          },
          project_id,
          $limit: 99999999,
        },
      })
      .subscribe(response => {
        let {data} = response;
        setPhases(data);
        setLoadingPhases(false);
      });
  };
  useEffect(() => {
    let {id, location} = props;
    let {search} = location;
    if (search) {
      search = qs.parse(search.replace(/\?/g, ''));
      let step = search.step || 0;
      setValue(Number(step));
    }
    getProject(id);
  }, [props.id]);
  const handleChange = (event, newValue) => {
    let {id, location} = props;
    let {search, pathname} = location;
    search = qs.parse(search.replace(/\?/g, ''));
    search['step'] = newValue;
    navigate(`${pathname}?${qs.stringify(search)}`);
    setValue(newValue);
  };
  const handleOnSubmit = data => {
    let {id} = data;
    Api.projects.patch(id, data).subscribe(
      response => {
        message.success('Proyecto Actualizado!');
        // console.log('Enviado!', response);
      },
      error => {
        message.error(error.message);
      },
    );
  };
  const handleSubmitFases = (data = {}, actions) => {
    let project_id = props.id;
    data['project_id'] = project_id;
    let {id} = data;
    if (id)
      return Api.phases.patch(id, data).pipe(
        map(response => {
          getPhases(project_id);
        }),
      );
    if (project_id)
      Api.phases.create(data).pipe(
        map(response => {
          getPhases(project_id);
        }),
      );
  };
  const handleAdd = () => {
    createZoneInterst({
      name: zonename,
      project_id: props.id,
    })
      .then(response => {
        message.info('Registro creado con éxito.');
        zonename = null;
      })
      .catch(err => message.error(err.message));
  };
  return (
    <React.Fragment>
      <Headline fullTitle title="Solicitud creación de proyecto" />
      {
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example">
            <Tab label="DATOS GENERALES" {...a11yProps(0)} />
            <Tab label="FASES" {...a11yProps(1)} />
            <Tab label="COMPAÑIA VENDEDORA" {...a11yProps(2)} />
            <Tab label="ZONAS DE INTERÉS" {...a11yProps(3)} />
            <Tab label="ZONAS COMÚNES" {...a11yProps(4)} />
            <Tab label="IMAGENES" {...a11yProps(5)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ProjectDataForm project={project} onSubmit={handleOnSubmit} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ProjectFases
              data={phases}
              loading={loading_phases}
              onSubmit={handleSubmitFases}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <React.Fragment>
              <div>
                {
                  <CRUD
                    autoLoad={true}
                    autoLoadRecord={true}
                    mode="form"
                    source="projects-sales-company"
                    params={{
                      query: {
                        project_id: props.id,
                      },
                    }}
                    service={projects_sales_company}
                    fields={[
                      {
                        name: 'id',
                        type: 'hidden',
                      },
                      {
                        name: 'project_id',
                        type: 'hidden',
                        initialValue: props.id,
                      },
                      {
                        xtype: 'fileuploader',
                        name: 'path_logo',
                        path: `projects-images/${props.id}/images/`,
                        flex: 1,
                      },
                      {
                        name: 'name',
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                        label: 'Razón social',
                        placeholder: 'Razón social',
                      },
                      {
                        name: 'nit',
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                        label: 'Nit',
                        placeholder: 'Nit',
                      },
                      {
                        name: 'address',
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                        label: 'Dirección',
                        placeholder: 'Dirección',
                      },
                      {
                        name: 'phone',
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                        label: 'Teléfono',
                        placeholder: 'Teléfono',
                      },
                      {
                        name: 'email',
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                        label: 'Email',
                        placeholder: 'Email',
                      },
                      {
                        name: 'whatsapp',
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                        label: 'Whatsapp',
                        placeholder: 'Whatsapp',
                      },
                    ]}
                    columns={[
                      {
                        dataIndex: 'name',
                        text: 'Nombre',
                      },
                      {
                        dataIndex: 'nit',
                        text: 'Nit',
                      },
                      {
                        dataIndex: 'address',
                        text: 'Dirección',
                      },
                      {
                        dataIndex: 'phone',
                        text: 'Teléfono',
                      },
                    ]}
                  />
                }
              </div>
            </React.Fragment>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <React.Fragment>
              <div>
                {
                  <CRUD
                    title="Zonas de interés"
                    mode="default"
                    params={{
                      query: {
                        project_id: props.id,
                      },
                    }}
                    /* autoLoad={false} */
                    service={projects_zones_interest}
                    fields={[
                      {
                        name: 'id',
                        type: 'hidden',
                      },
                      {
                        name: 'project_id',
                        type: 'hidden',
                        initial: props.id,
                      },
                      {
                        xtype: 'custom',
                        flex: 1,
                        style: {
                          margin: 10,
                        },
                        renderItem: function(record) {
                          return (
                            <>
                              <Row
                                type="flex"
                                justify="space-between"
                                gutter={8}
                                align="middle">
                                <Col span={22}>
                                  <div>
                                    <span>Crear tus zonas de interés</span>
                                  </div>
                                  <Input
                                    name="name"
                                    size="large"
                                    value={zonename}
                                    label={'Zona de interés'}
                                    onChange={e => {
                                      zonename = e.target.value;
                                      /* setZoneName(e.target.value) */
                                    }}
                                    placeholder={'Zona de interés'}
                                  />
                                </Col>
                                <Col span={2}>
                                  <Button
                                    style={{
                                      marginTop: 20,
                                    }}
                                    shape="circle"
                                    icon="plus"
                                    className="btn-green"
                                    onClick={handleAdd}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Divider />
                              </Row>
                            </>
                          );
                        },
                      },
                      {
                        xtype: 'selectfield',
                        name: 'zone_interest_id',
                        ref: ref,
                        flex: 1,
                        model: {
                          service: getZonesInterest,
                        },
                        label: 'Zona de interés',
                        placeholder: 'Zona de interés',
                        refresh: true,
                        validations: [
                          {
                            required: true,
                            message: ' es requerido',
                          },
                        ],
                      },
                      {
                        /* xtype: 'numberfield', */
                        name: 'journey_time',
                        flex: 1,
                        validations: [
                          {
                            required: true,
                            /*  type: 'number', */
                            message: ' es requerido',
                          },
                          {
                            /*  type: 'number', */
                          },
                          {
                            max: 255,
                          },
                        ],
                        label: 'Tiempo',
                        placeholder: 'Tiempo',
                      },
                    ]}
                    columns={[
                      {
                        dataIndex: 'name',
                        text: 'Nombre',
                        render: function(record) {
                          return record.zone_interest.name;
                        },
                      },
                      {
                        dataIndex: 'journey_time',
                        text: 'Tiempo',
                      },
                    ]}
                  />
                }
              </div>
            </React.Fragment>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <React.Fragment>
              <div>{<CommonZones {...props} />}</div>
            </React.Fragment>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <React.Fragment>
              <div>{<ProjectGallery project_id={props.id} />}</div>
            </React.Fragment>
          </TabPanel>
        </>
      }
    </React.Fragment>
  );
};
export default ProjectRequest;
