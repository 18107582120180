import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import { BASE_URL_API } from '../constants/index';
const client = feathers();

// Configure an AJAX library (see below) with that client
client.configure(feathers.rest(BASE_URL_API).fetch(window.fetch.bind(window)));

// client.configure(socketio(socket));
client.configure(
  feathers.authentication({
    path: '/authentication',
    entity: 'user',
    service: 'users',
    cookie: 'feathers-jwt',
    storageKey: 'feathers-jwt',
    storage: window.localStorage,
  }),
);

export default client;
