import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Formik, Form } from 'formik';
import { Box } from '@material-ui/core';
import { message } from 'antd';
import AppButton from '../../components/AppButton';
import styled from 'styled-components';

const ButtonSubmit = styled(AppButton)`
  max-width: 300px;
  float: right;
`;

const CustomForm = props => {
  let [bindSubmit, setBindSubmit] = useState(props.bindSubmit || false);
  let [readOnly, setReadOnly] = useState(props.readOnly || false);
  let [edit, setEdit] = useState(false);
  let [submitting, setSubmitting] = useState(false);
  const { setForm, onSubmit, ref, data } = props;

  const formRef = React.createRef();

  return (
    <Formik
      className={props.className}
      ref={props.ref || formRef}
      initialValues={data}
      enableReinitialize={true}
      getForm={() => {
        return formRef;
      }}
      /* validationSchema={schema} */
      onSubmit={(data, actions) => {
        if (bindSubmit) {
          setReadOnly(!readOnly);
          if (!edit) {
            return setEdit(!edit);
          }
          setEdit(!edit);
          setSubmitting(true);
          if (onSubmit)
            return onSubmit(data, actions).subscribe({
              next: response => {
                setSubmitting(false);
              },
              error: err => {
                message.error(err.message);
                setSubmitting(false);
              },
            });
        }
        if (onSubmit) onSubmit(data, actions);
      }}
      render={({
        isSubmitting,
        setFieldValue,
        values,
        initialValues,
        submitForm,
      }) => {
        if (formRef.current) {
          if (typeof setForm === 'function') {
            setForm(formRef.current);
          }
        }
        return (
          <Form className={props.className} noValidate>
            <Box mt={4} mb={6}>
              <Row>{props.children}</Row>
              <Row>
                <Col>
                  {bindSubmit && (
                    <ButtonSubmit loading={submitting} type="submit">
                      {!edit ? 'Editar' : 'Guardar'}
                    </ButtonSubmit>
                  )}
                </Col>
              </Row>
            </Box>
          </Form>
        );
      }}
    />
  );
};
export default CustomForm;
