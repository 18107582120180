import {Input, message} from 'antd';
import React, {useState, useEffect} from 'react';
import {SimpleForm, SelectField} from '../../components/commons';
import {getService} from '../../services/services';

const CommonZoneForm = ({project_id, onSubmit, ...props}) => {
  const [common_zones, setCommonZones] = useState([]);
  const [loading, setLoading] = useState([]);
  const getData = () => {
    const service = getService('common-zones');
    setLoading(true);
    service
      .find({query: {$limit: 9999}})
      .then(({data}) => {
        setCommonZones(data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };
  useEffect(() => {
    if (project_id) getData();
  }, [project_id]);
  return (
    <>
      <SimpleForm
        source="projects-common-zones"
        loading={loading}
        onSubmit={onSubmit}>
        <Input type="hidden" name="project_id" initial={project_id} />
        <SelectField
          name="common_zone_id"
          choices={common_zones}
          flex={1}
          label="Zona común"
          placeholder="Zona común"
        />
      </SimpleForm>
    </>
  );
};
export default CommonZoneForm;
