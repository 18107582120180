import React from 'react';

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g opacity={0.6}>
      <path
        data-name="Trazado 82"
        d="M16.875 18H1.125A1.126 1.126 0 010 16.875v-5.25a.375.375 0 11.75 0v5.25a.376.376 0 00.375.375h15.75a.376.376 0 00.375-.375V1.125a.376.376 0 00-.375-.375h-5.25a.375.375 0 110-.75h5.25A1.126 1.126 0 0118 1.125v15.75A1.126 1.126 0 0116.875 18zm0 0"
      />
      <path
        data-name="Trazado 83"
        d="M15.375 15.75a.377.377 0 01-.265-.109l-4.5-4.5a.375.375 0 01.53-.53l4.5 4.5a.375.375 0 01-.265.64zm0 0"
      />
      <path
        data-name="Trazado 84"
        d="M10.875 14.25a.375.375 0 01-.375-.375v-3a.375.375 0 01.375-.375h3a.375.375 0 010 .75H11.25v2.625a.375.375 0 01-.375.375zm0 0"
      />
      <path
        data-name="Trazado 85"
        d="M9.375 10.5h-1.5a.375.375 0 110-.75h1.5a.37.37 0 00.1-.015.375.375 0 01.212.719 1.125 1.125 0 01-.312.046zm-3.75 0h-1.5a.375.375 0 010-.75h1.5a.375.375 0 110 .75zm-3.75 0h-.75a1.119 1.119 0 01-.946-.518.375.375 0 01.631-.4.373.373 0 00.315.172h.75a.375.375 0 110 .75zm8.25-2.036a.375.375 0 01-.375-.375v-1.5a.375.375 0 11.75 0v1.5a.375.375 0 01-.375.375zm-9.75-.536A.375.375 0 010 7.553v-1.5a.375.375 0 01.75 0v1.5a.375.375 0 01-.375.375zm9.75-3.215a.375.375 0 01-.375-.375v-1.5a.375.375 0 11.75 0v1.5a.375.375 0 01-.375.376zm-9.75-.535A.375.375 0 010 3.8V2.3a.375.375 0 11.75 0v1.5a.375.375 0 01-.375.378zm9.566-3.17A.374.374 0 019.658.88a.372.372 0 00-.283-.13h-.857a.375.375 0 010-.75h.857a1.123 1.123 0 01.85.388.375.375 0 01-.283.621zM1.069.754a.4.4 0 01-.4-.319.356.356 0 01.295-.423A1.137 1.137 0 011.125 0h1.393a.375.375 0 010 .75H1.125a.354.354 0 01-.056.004zm5.2 0h-1.5a.375.375 0 110-.75h1.5a.375.375 0 110 .75zm0 0"
      />
    </g>
  </svg>
);

export default SvgComponent;
