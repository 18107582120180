import api from '../services/api'

export const getCellPhone = async () => {
  const value = await api.configurations.find({query: {
    key: 'CEL_PHONE'
  }}).then(res => res.total ? res.data[0].value : '' )
  return value
}

export const getWhatsapp = async () => {
  const value = await api.configurations.find({query: {
    key: 'WHATSAPP'
  }}).then(res => res.total ? res.data[0].value : '' )
  return value
}

export const getEmail = async () => {
  const value = await api.configurations.find({query: {
    key: 'EMAIL'
  }}).then(res => res.total ? res.data[0].value : '' )
  return value
}