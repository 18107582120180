import {
  SET_CURRENT_USER,
  START_LOADING_USER,
  END_LOADING_USER,
} from '../constants/actionTypes';
import Api from '../services/api';
import {setAuthenticate} from './notifications';

export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const startLoadingUser = () => ({
  type: START_LOADING_USER,
});

export const endLoadingUser = () => ({
  type: END_LOADING_USER,
});

export const retryLoginAndGetCurrentUser = () => {
  console.info('Retry login and get current user...');
  return dispatch => {
    if (localStorage.getItem('feathers-jwt')) {
      Api.retryLogin().subscribe({
        next: () => {
          dispatch(setAuthenticate(true));
          dispatch(getCurrentUser());
        },
        error: err => {
          dispatch(setAuthenticate(false));
        },
        complete: () => {},
      });
    } else {
      dispatch(setAuthenticate(false));
    }
  };
};

export const getCurrentUser = () => {
  return dispatch => {
    dispatch(startLoadingUser());
    Api.current().subscribe({
      next: data => {
        console.log('USER: ', data);
        dispatch(setCurrentUser(data));
      },
      error: err => console.log(err),
      complete: () => dispatch(endLoadingUser()),
    });
  };
};
