import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={30}
    fill="none"
    {...props}
  >
    <ellipse cx={15.5} cy={15} fill="#fff" rx={15.5} ry={15} />
    <path
      stroke="#65A92E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.001 19 22 14.998l-4-4.001M9 14.997h13"
    />
  </svg>
)
export default SvgComponent