import Select from 'react-select';
import React from 'react';
import styled from 'styled-components';
import { Field as FormikField} from 'formik';
import ErrorField from './formik/ErrorField';
import {Box} from '@material-ui/core';

const Label = styled.div`
  letter-spacing: 0;
  color: #000 !important;
  text-transform: uppercase;
  /* opacity: 0.3; */
  font-size: 0.8125rem;
  margin-bottom: 10px;
  padding: 0 8px;
`;

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    background: '#585E673F',
    borderRadius: '5px',
    minHeight: '40px',
  }),
  option: (provided, state) => ({
    ...provided,
    textTransform: 'capitalize',
  }),
  indicatorSeparator: () => ({}),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 15,
    fontWeight: 'lighter',
    
  }),
};

const Wrapper = styled.div`
  flex-grow: 1;
`;

// const handleOpen = () => {
//   alert("EPA!")
// }

const AppSelect = ({options, placeholder, ...props}) => (
  <Select
    onClick
    styles={customStyles}
    options={options}
    placeholder={placeholder}
    {...props}
    {...props.field}
    
  />
);

const SelectLabel = ({name, label, className, hasFormik = false, style, ...props}) => {
  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      {hasFormik ? (
        <React.Fragment>
          <FormikField name={name} {...props} component={AppSelect} />
          <ErrorField name={name} />
        </React.Fragment>
      ) : (
        <Box mb={1} style={style}>
          <AppSelect {...props} />
        </Box>
      )}
    </Wrapper>
  );
};

export default SelectLabel;
