import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const CardNewsSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton variant="rect" height={158} width={'100%'}/>
      <Skeleton width="100%" />
      <Skeleton width="100%" />
      <Skeleton width="100%"/>
    </React.Fragment>
  );
};

export default CardNewsSkeleton;
