import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.svg`
  cursor: pointer;
  
`;

const SvgComponent = ({isActive = false, ...props}) => {
  if (isActive) {
    return (
      <Wrapper width={25} height={21.99} {...props}>
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="1"
            y1="1"
            x2="0"
            gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fab01b" />
            <stop offset="1" stopColor="#fd4040" />
          </linearGradient>
        </defs>
        <g id="Grupo_152" data-name="Grupo 152" transform="translate(32.842)">
          <path
            id="Trazado_21"
            data-name="Trazado 21"
            d="M-14.182,0c-2.443,0-4.626,1.681-6.16,3.384C-21.877,1.681-24.059,0-26.5,0a5.5,5.5,0,0,0-2.524.626,6.955,6.955,0,0,0-3.816,6.15,6.887,6.887,0,0,0,2.372,4.877c2.72,2.8,10.128,10.339,10.128,10.339s7.407-7.535,10.128-10.339A6.889,6.889,0,0,0-7.843,6.776,6.953,6.953,0,0,0-11.658.626,5.5,5.5,0,0,0-14.182,0"
            transform="translate(0 0)"
            fill="url(#linear-gradient)"
          />
        </g>
      </Wrapper>
    );
  }
  return (
    <Wrapper width={25} height={21.99} {...props}>
      <path
        data-name="Trazado 260loveicon"
        d="M15.429.5c-1.955 0-3.7 1.345-4.928 2.707C9.273 1.845 7.527.5 5.573.5a4.4 4.4 0 00-2.019.5A5.564 5.564 0 00.501 5.92a5.51 5.51 0 001.9 3.9c2.176 2.243 8.1 8.271 8.1 8.271s5.925-6.028 8.1-8.271a5.511 5.511 0 001.9-3.9A5.563 5.563 0 0017.448 1a4.4 4.4 0 00-2.019-.5m0 .739a3.7 3.7 0 011.684.42 4.8 4.8 0 012.649 4.256 4.774 4.774 0 01-1.689 3.393c-1.77 1.823-6.071 6.2-7.572 7.732-1.5-1.528-5.8-5.909-7.572-7.732a4.776 4.776 0 01-1.686-3.393 4.8 4.8 0 012.649-4.256 3.7 3.7 0 011.681-.42c2.4 0 4.928 3.072 4.928 3.072s2.528-3.072 4.928-3.072"
        stroke="#000"
      />
    </Wrapper>
  );
};

export default SvgComponent;
