import React, {useEffect, useState} from 'react';
/* import PropTypes from 'prop-types'; */
import {Row, Col} from '@bootstrap-styled/v4';
import {Box, Tabs, Tab} from '@material-ui/core';
import AppButton from '../AppButton';
import InputText from '../InputText';
import SelectLabel from '../SelectLabel';
import {Formik, Form} from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import Api from '../../services/api';
import {async} from 'q';

const ButtonProject = styled(AppButton)`
  max-width: 300px;
`;

const TextAreaField = styled(InputText)`
  height: 200px;
`;
const schema = yup.object().shape({
  name: yup
    .string()
    .max(40, 'Nombre es demasiado largo')
    .required('Nombre es requerido.'),
  address: yup.string().required('Dirección es requerida.'),
  /* 
  email: yup
    .string()
    .email('Debe ser un E-Mail valido.')
    .required('Correo electronico es requerido.'), */
});
let initialized = false;
function ProjectDataForm(props) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(2);
  const [selectedOption, selectOption] = React.useState(null);
  const [zones, setZones] = useState([]);
  const [cities, setCities] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const sendProject = (data, actions) => {
    if (actions) actions.setSubmitting(false);
    if (props.onSubmit) props.onSubmit(data);
  };
  const getNeighborhoods = async (query = {}) => {
    setLoading(true);
    let request = await Api.neighborhoods.find({query}).subscribe(response => {
      let {data} = response;
      setNeighborhoods(data);
      setLoading(false);
    });

    return () => request.unsubscribe();
  };
  const getZones = async (
    query = {
      $client: {paginate: true, limit: 99999999},
    },
  ) => {
    setLoading(true);
    let request = await Api.zones
      .find({query, paginate: false})
      .subscribe(data => {
        data = Object.values(data);
        setZones(data);
        setLoading(false);
      });
  };
  const getCities = async (query = {}) => {
    setLoading(true);
    let request = await Api.cities
      .find({query, paginate: false})
      .subscribe(({data}) => {
        /*         data = Object.values(data);
         */
        setCities(data);
        setLoading(false);
      });
  };
  useEffect(() => {
    getZones();
    getCities();
    getNeighborhoods();
    let defaultValues = props.defaultValues || {};
    setDefaultValues(defaultValues);
  }, [props.defaultValues]);

  const {project = {}} = props;

  return (
    <Formik
      ref={props.ref}
      initialValues={project}
      enableReinitialize={true}
      /* validationSchema={schema} */
      onSubmit={(values, actions) => {
        delete values.general_uses;
        delete values.neighborhood;
        delete values.general_uses;
        delete values.city;
        delete values.zone;
        delete values.stratu;
        delete values.stratu;
        delete values.type_living_places;
        console.log('Submit:: ', values);
        sendProject(values, actions);
      }}
      render={({isSubmitting, setFieldValue, values, initialValues}) => {
        let {project} = props;
        if (!initialized && project) {
          if (initialValues['stratus']) {
            setFieldValue('stratu', {
              label: `Estrato ${initialValues['stratus']}`,
              value: initialValues['stratus'],
            });
          }
          if (initialValues['neighborhood']) {
            setFieldValue('neighborhood', {
              label: initialValues['neighborhood']['name'],
              value: initialValues['neighborhood']['id'],
            });
          }
          if (initialValues['general_use']) {
            setFieldValue('general_uses', {
              label: initialValues['general_use'],
              value: initialValues['general_use'],
            });
          }
          if (initialValues['type_living_place']) {
            setFieldValue('type_living_places', {
              label: initialValues['type_living_place'],
              value: initialValues['type_living_place'],
            });
          }
          if (initialValues['city']) {
            setFieldValue('city', {
              label: initialValues['city']['name'],
              value: initialValues['city']['id'],
            });
          }
          if (initialValues['zone']) {
            setFieldValue('zone', {
              label: initialValues['zone']['name'],
              value: initialValues['zone']['id'],
            });
            initialized = true;
          }
        }
        return (
          <Form>
            <Box mt={4} mb={6}>
              <Row>
                <Col xs={12} md={6}>
                  <InputText name="name" hasFormik label="nombre" />
                </Col>
                <Col xs={12} md={6}>
                  <InputText hasFormik name="address" label="direccion" />
                </Col>
                <Col xs={12} md={6}>
                  <SelectLabel
                    isSearchable
                    defaultValue={initialValues['city']}
                    value={values['city']}
                    name="city"
                    label="Ciudad"
                    onKeyUp={(refName, e) => console.log(refName, e)}
                    onChange={selected => {
                      let {value} = selected;
                      setFieldValue('city', selected);
                      setFieldValue('city_id', value);
                    }}
                    options={cities.map(item => {
                      return {
                        value: item['id'],
                        label: item['name'],
                      };
                    })}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectLabel
                    isSearchable
                    defaultValue={initialValues['zone']}
                    value={values['zone']}
                    name="zone"
                    label="Zona"
                    onKeyUp={(refName, e) => console.log(refName, e)}
                    onChange={selected => {
                      let {value} = selected;
                      setFieldValue('zone', selected);
                      setFieldValue('zone_id', value);
                    }}
                    options={zones.map(item => {
                      return {
                        value: item['id'],
                        label: item['name'],
                      };
                    })}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectLabel
                    name="neighborhood"
                    label="barrio"
                    /* defaultValue={initialValues['neighborhood']} */
                    value={values['neighborhood']}
                    options={neighborhoods.map(item => {
                      return {
                        value: item['id'],
                        label: item['name'],
                      };
                    })}
                    onChange={selected => {
                      let {value} = selected;
                      setFieldValue('neighborhood', selected);
                      setFieldValue('neighborhood_id', value);
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputText name="lat" hasFormik label="latitud" />
                </Col>
                <Col xs={12} md={6}>
                  <InputText name="long" hasFormik label="longitud" />
                </Col>
                <Col xs={12} md={6}>
                  <SelectLabel
                    name="type_living_place"
                    label="Tipo de vivienda"
                    defaultValue={initialValues['type_living_places']}
                    value={values['type_living_places']}
                    onChange={selected => {
                      let {data} = selected;
                      setFieldValue('type_living_places', selected);
                      setFieldValue('type_living_place', value);
                    }}
                    options={[
                      {
                        value: 'VIS',
                        label: 'VIS',
                      },
                      {
                        value: 'No VIS',
                        label: 'No VIS',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectLabel
                    name="stratu"
                    label="Estrato"
                    /* defaultValue={stratu} */
                    value={values['stratu']}
                    onChange={selected => {
                      let {value} = selected;
                      setFieldValue('stratus', value);
                      setFieldValue('stratu', selected);
                    }}
                    options={[
                      {
                        value: 1,
                        label: 'Estrato 1',
                      },
                      {
                        value: 2,
                        label: 'Estrato 2',
                      },
                      {
                        value: 3,
                        label: 'Estrato 3',
                      },
                      {
                        value: 4,
                        label: 'Estrato 4',
                      },
                      {
                        value: 5,
                        label: 'Estrato 5',
                      },
                      {
                        value: 6,
                        label: 'Estrato 6',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectLabel
                    name="general_uses"
                    label="Uso general"
                    value={values['general_uses']}
                    onChange={selected => {
                      let {value} = selected;
                      setFieldValue('general_use', value);
                      setFieldValue('general_uses', selected);
                    }}
                    options={[
                      {
                        value: 'Residencial',
                        label: 'Residencial',
                      },
                      {
                        value: 'No Residencial',
                        label: 'No Residencial',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputText name="phone" hasFormik label="telefono" />
                </Col>
                <Col xs={12} md={6}>
                  <InputText
                    name="number_of_blocks"
                    hasFormik
                    label="numero de bloques"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputText
                    name="number_of_units"
                    hasFormik
                    label="numero de unidades"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputText
                    name="fiduciary_entity"
                    hasFormik
                    label="entidad fiduciaria"
                  />
                </Col>
                {project && (
                  <Col xs={12} md={24}>
                    <TextAreaField
                      name="project_description"
                      type="textarea"
                      hasFormik
                      rows="20"
                      label="Descripcion de proyecto"
                    />
                  </Col>
                )}
                {project && (
                  <Col xs={12} md={24}>
                    <TextAreaField
                      name="address_description"
                      type="textarea"
                      hasFormik
                      rows="20"
                      label="Descripcion de Dirección"
                    />
                  </Col>
                )}
                {project && (
                  <Col xs={12} md={24}>
                    <TextAreaField
                      name="zones_interest_description"
                      type="textarea"
                      hasFormik
                      rows="20"
                      label="Descripcion de zonas de interes"
                    />
                  </Col>
                )}
              </Row>
            </Box>
            <ButtonProject
              type="submit"
              size="lg"
              color="secondary"
              loading={loading}
              disabled={isSubmitting}
              className="w-100 text-white mt-1">
              Guardar
            </ButtonProject>
          </Form>
        );
      }}
    />
  );
}

export default ProjectDataForm;
