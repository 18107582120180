import React from 'react';
import { H3, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import Text from '../Text';
import { BASE_BUCKET_URL } from '../../constants/global';
import GamesRoundIcon from '../icons/GamesRoundIcon';
import SocialRoundIcon from '../icons/SocialRoundIcon';
import ParkingRoundIcon from '../icons/ParkingRoundIcon';
import GreenZoneRound from '../icons/GreenZoneRound';
import GymRoundIcon from '../icons/GymRoundIcon';

const ZoneText = styled.span`
  color: #000000;
  text-transform: uppercase;
  margin-left: 7px;
  /* opacity: 0.6; */
`;
const Title = styled(H3)`
  letter-spacing: 0 !important;
  color: #000000 !important;
  /* opacity: 0.6 !important; */
  /* font-weight: bold !important; */
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
`;

const Wrapper = styled.div`
  min-height: ${props => (props.hasSpace ? '150px' : 'auto')};
  margin: 0 0 1.2rem 0;

  /* box-shadow: 1px 0px 7px -3px;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem 0.5rem 0.5rem 0.5rem; */
`;

const ProjectZones = ({ items = [], text, hiddedText = false, ...props }) => {
  return (
    <Wrapper {...props}>
      <Title>Zonas comunes</Title>
      {!hiddedText && <Text>{text}</Text>}
      <Row mb={2}>
        {items.map(item => (
          <Col md={6} styled={{display:'flex', justifyContent:'flex-start', alignItem: 'center'}} key={item.id}>
            <Icon
              src={`${BASE_BUCKET_URL}${item.icon_path}`}
              alt="zona comun"
            />
            <ZoneText>{item.name}</ZoneText>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

export default ProjectZones;
