import {
  Col,
  message,
  Row,
  Layout,
  List,
  Card,
  Button,
  ConfigProvider,
  InputNumber,
  Popconfirm,
} from 'antd';
import React, {useState, useEffect} from 'react';
import {FileField} from '../../components/commons';
import {getService} from '../../services/services';
import {Wrapper, WrapperEmpty} from './Styles';

const {Content} = Layout;
const service = getService('projects-images');
const Gallery = ({project_id, source, record, onFinish, ...props}) => {
  const [images, setImages] = useState([]);
  const [current, setCurrent] = useState({});
  const [loading, setLoading] = useState(false);
  const getImages = () => {
    setLoading(true);
    setCurrent({});
    service
      .find({
        query: {
          project_id,
          $sort: {
            priority: -1,
          },
        },
      })
      .then(({data}) => {
        setLoading(false);
        setImages([
          ...data,
          {
            type: 'empty',
          },
        ]);
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  const remove = id => {
    setCurrent({});
    return service
      .remove(id)
      .then(response => {
        message.success('Imagen eliminada!');
        getImages();
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  const save = params => {
    let {id, path} = params;
    if (!id)
      return service
        .create({
          project_id,
          path: path,
          priority: images.length,
        })
        .then(response => {
          message.success('Imagen cargada!');
          getImages();
        })
        .catch(err => {
          message.error(err.message);
        });
    service
      .patch(id, {
        path: path,
        ...params,
      })
      .then(response => {
        message.success('Imagen actualizada!');
        getImages();
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  const handleOnFinish = (path, id) => {
    save({
      id,
      path,
    });
  };
  const renderEmptyText = () => (
    <WrapperEmpty>
      <FileField
        componentId={'new-photo'}
        source="photo"
        path="project_images"
        name="photo"
        label="Subir Primera Imagen"
        onFinish={(url, file) => handleOnFinish(url)}
      />
    </WrapperEmpty>
  );
  const renderItem = item => {
    return (
      <List.Item
        className={item.type == 'empty' ? 'empty-item' : ''}
        key={item.id}>
        <Card
          cover={
            <>
              {item.id && (
                <Row
                  type="flex"
                  justify="end"
                  gutter={4}
                  style={{
                    margin: '10px 0px',
                  }}>
                  <Col>
                    {item.id == current.id ? (
                      <InputNumber
                        defaultValue={item.priority}
                        placeholder="Prioridad"
                        onChange={value =>
                          setCurrent({
                            ...item,
                            ['priority']: value,
                          })
                        }
                      />
                    ) : (
                      <span
                        style={{
                          padding: '4px',
                          display: 'block',
                        }}>
                        {item.priority}
                      </span>
                    )}
                  </Col>
                  <Col>
                    <Button
                      type={item.id == current.id ? 'primary' : 'dashed'}
                      icon={item.id == current.id ? 'save' : 'edit'}
                      onClick={() => {
                        if (item.id == current.id) save(current);
                        setCurrent(item);
                      }}
                    />
                  </Col>
                  {item.type != 'empty' && item.id == current.id && (
                    <Col>
                      <Button
                        type={'dashed'}
                        icon={'close'}
                        onClick={() => {
                          setCurrent({});
                        }}
                      />
                    </Col>
                  )}
                  {item.type != 'empty' && item.id != current.id && (
                    <Col>
                      <Popconfirm
                        placement="bottomRight"
                        title={'Desea eliminar la foto?'}
                        onConfirm={() => {
                          remove(item.id);
                        }}
                        okText="Si"
                        cancelText="No">
                        <Button type={'danger'} icon={'delete'} />
                      </Popconfirm>
                    </Col>
                  )}
                </Row>
              )}
              <FileField
                className="new-image"
                componentId={item.id || 'empty'}
                source="photo"
                path="project_images"
                label={item.type == 'empty' && 'Cargar Nueva Imagen'}
                type={item.type}
                name="photo"
                record={{
                  ...item,
                  photo: item.path,
                  image: item.path,
                }}
                onFinish={url => handleOnFinish(url, item.id)}
              />
            </>
          }
        />
      </List.Item>
    );
  };
  useEffect(() => {
    if (project_id) getImages();
  }, [project_id]);
  return (
    <>
      {
        <ConfigProvider renderEmpty={renderEmptyText}>
          <Row type="flex" justify="end">
            <Col>
              <Button
                loading={loading}
                icon="reload"
                onClick={() => getImages()}
              />
            </Col>
          </Row>
          <List
            grid={{gutter: 4, column: 2}}
            renderItem={renderItem}
            loading={loading}
            dataSource={images}
          />
        </ConfigProvider>
      }
    </>
  );
};

const ProjectGallery = ({project_id, ...props}) => {
  const [images, setImages] = useState([]);
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(true);

  const [uploading, setUploading] = useState({});
  const getImages = () => {
    const service = getService('projects-images');
    service
      .find({
        query: {
          project_id,
        },
      })
      .then(({data}) => {
        setImages(data);
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  const getProject = () => {
    const service = getService('projects');
    setLoading(true);
    service
      .get(project_id)
      .then(data => {
        setLoading(false);
        setRecord(data);
        getImages();
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  const handleUploadFinish = (field, url, file, _id) => {
    const service = getService('projects');
    setUploading({
      ...uploading,
      [field]: true,
    });
    service
      .patch(project_id, {
        path_image: true,
        [field]: url,
      })
      .then(response => {
        window.location.reload();
      })
      .catch(err => message.error(err.message));
  };
  useEffect(() => {
    if (project_id) getProject();
  }, [project_id]);
  if (loading) return 'Cargando...';
  return (
    <Wrapper>
      <Layout>
        <Row gutter={8}>
          <Col span={24}>
            <FileField
              componentId="path_logo"
              source="path_logo"
              path="project_images"
              label="SUBIR LOGO"
              name="path_logo"
              loading={uploading['path_logo']}
              record={record}
              onFinish={(url, file, id) =>
                handleUploadFinish('path_logo', url, file, id)
              }
            />
          </Col>
          <Col span={8}>
            <FileField
              componentId="image"
              source="image"
              path="project_images"
              label="SUBIR IMAGEN DE PORTADA"
              name="image"
              loading={uploading['image']}
              record={record}
              onFinish={(url, file, id) =>
                handleUploadFinish('image', url, file, id)
              }
            />
          </Col>
          <Col span={16}>
            <Content>
              <Gallery
                project_id={project_id}
                source="project_images"
                record={record}
              />
            </Content>
          </Col>
        </Row>
      </Layout>
    </Wrapper>
  );
};
export default ProjectGallery;
