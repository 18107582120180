import React from 'react';
import styled from 'styled-components';
import {H3, Button} from '@bootstrap-styled/v4';
import {navigate} from '@reach/router';

const Image = styled.div`
  background-image: url('/images/404.png');
  max-width: 763px;
  width: 100%;
  height: 596px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Wrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const NotFound = () => {
  return (
    <Wrapper className="d-flex flex-column justify-content-center align-items-center">
      <Image />
      <H3>Ups, parace que te perdiste...</H3>
      <Button className="mt-4" onClick={() => navigate('/')}>
        Volver al inicio
      </Button>
    </Wrapper>
  );
};

export default NotFound;
