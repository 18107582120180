import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));
function createData(name, calories, fat, carbs, protein) {
  return {name, calories, fat, carbs, protein};
}
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
];
const DrawRow = row => {
  let data = [];
  Object.values(row).forEach(item => {
    data.push(item);
  });
  return data;
};
const CustomTable = props => {
  const classes = useStyles();
  const columns = props.columns || [];
  const dataSource = props.dataSource || [];
  return (
    <>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="caption table">
          {props.caption && <caption>{props.caption}</caption>}
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell key={index}>{item.text}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((row, colIndex) => {
              return (
                <TableRow key={colIndex}>
                  {DrawRow(row)
                    .filter(item => {
                      return item != null;
                    })
                    .map((item, rowIndex) => (
                        <TableCell
                          key={`${colIndex}-${rowIndex}`}
                          component="th"
                          scope="row">
                          {rowIndex}
                          {row[columns[rowIndex]['dataIndex']]}
                        </TableCell>
                      )
                    )}

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default CustomTable;
