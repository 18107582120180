import React from 'react';
const IframeGoogleMap = ({lat, long}) => {
  
  return (
    <>
      {lat !== null && (
        
        <iframe
          width="100%"
          height="380"
          style={{
            border: 0,
            backgroundColor: 'gray',
          }}
          allowFullScreen={false}
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBIIMCtAQw9VFVhzdRxDe9XROOGAqqY0ps&zoom=12&q=${lat},${long}&center=${lat},${long}`}
        />
      )}
    </>
  );
};

export default IframeGoogleMap;
