import React, { useState, useEffect } from 'react';
import { encode } from 'base-64';
import styled from 'styled-components';
import {
    CardImg,
} from '@bootstrap-styled/v4';
import { CLOUD_FRONT_URL, S3_BUCKET } from '../../constants/global';

const WrapperImage = styled(CardImg)`
  object-fit: cover;
`;
const ProjectImage = ({ size, src, fit = "cover", ...props }) => {
    const [image_path, setImagePath] = useState();

    const getImagePath = (json) => {
        json = JSON.stringify(json);
        const base64 = encode(json);
        const newUrl = `${CLOUD_FRONT_URL}${base64}`;
        setImagePath(newUrl);
    }
    useEffect(() => {
        let options = {
            "bucket": S3_BUCKET,
            "key": src,
            "edits": {
                "resize": {
                    "fit": fit
                }
            }
        };
        switch (size) {
            case "small":
                options["edits"]["resize"]["width"] = 200;
                options["edits"]["resize"]["height"] = 200;
                break;
            case "medium":
                options["edits"]["resize"]["width"] = 400;
                options["edits"]["resize"]["height"] = 400;
                break;
            case "large":
                options["edits"]["resize"]["width"] = 1000;
                options["edits"]["resize"]["height"] = 1000;
                break;
            case "medium_large":
                options["edits"]["resize"]["width"] = 700;
                options["edits"]["resize"]["height"] = 550;
                break;
            default:
                options["edits"]["resize"]["width"] = 400;
                options["edits"]["resize"]["height"] = 400;
                break;
        }
        getImagePath(options);
    }, [src, size, fit])
    return (
        <>
            {image_path && <WrapperImage
                {...props}
                src={image_path}
            />}
        </>);
}

export default ProjectImage;