import React from 'react';
import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
  H2,
  H3,
  Card,
  CardBlock,
  Button,
} from '@bootstrap-styled/v4';
import {Tab, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SelectLabel from '../components/SelectLabel';
import InputText from '../components/InputText';
import CheckBoxBox from '../components/CheckBoxBox';
import HomeOutline from '../components/icons/HomeOutline';
import BuildingOutlineIcon from '../components/icons/BuildingOutlineIcon';
import {Formik, Form} from 'formik';
import {RadioGroup} from '@material-ui/core';
import * as yup from 'yup';
import ErrorField from '../components/formik/ErrorField';

const schema = yup.object().shape({
  destination: yup.mixed().required('Destino es requerido.'),
  type: yup.mixed().required('Tipo de credito es requerido.'),
  plan: yup.mixed().required('Plan de amortización es requerido.'),
  years: yup.number().required('Años es requerido.'),
  value: yup.number().required('Valor del crédito es requerido.'),
});

const Wrapper = styled.div`
  width: 100%;
  background-image: url('/images/simulate-back.png');
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
`;

const Title = styled(H2)`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.6;
  font-size: 24px !important;
`;

const Text = styled.p`
  letter-spacing: -0.6px;
  color: #000000;
  opacity: 0.4;
  font-size: 20px;
`;

const FormCard = styled(Card)`
  border-radius: 10px;
`;

const FormCardTitle = styled(H3)`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;

const FormCardBlock = styled(CardBlock)`
  padding: 2.5625rem 4.625rem !important;
  @media (max-width: 768px) {
    padding: 1.5rem 2rem !important;
  }
`;

const AppTab = styled(Tab)`
  display: inline-block;
  border-bottom: ${props => (props.selected ? '5px solid' : 'none')};
  border-color: #00b2e3 !important;
  bottom: -1px;
  position: relative;
  list-style: none;
  /*padding: 0.5375rem 1.7875rem;*/
  cursor: pointer;
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
  opacity: ${props => (props.selected ? '1' : '0.2')};
  text-align: center;
  flex: 1;
`;

const AppTabList = styled(TabList)`
  padding: 0 !important;
  display: flex;
`;

const TitleLabel = styled.div`
  letter-spacing: 0;
  /* color: #000000;
text-transform: uppercase;
opacity: 1; */
  color: #000000;
  text-transform: uppercase;
  opacity: 0.3;
  font-size: 0.8125rem;
  margin-bottom: 10px;
`;

const ContainerBox = styled(Container)`
  box-sizing: border-box;
`;

const SimulateYourCredit = () => {
  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ];

  const handleSubmit = params => {};

  return (
    <Wrapper>
      <ContainerBox>
        <Row>
          {/* <Col xs={12} md={6}>
            <Title>Cómo simular tu crédito</Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit
              amet felis sit amet magna lobortis molestie. In aliquet pharetra
              massa, nec venenatis arcu. Integer scelerisque ligula sit amet
              dolor tristique laoreet. In tortor massa, cursus aliquet volutpat
              ac, vestibulum eget ipsum. Aenean molestie nisi nec lacinia
              aliquet. Nam rhoncus auctor elit finibus iaculis. Cras eu varius
              ipsum. Donec finibus, arcu eget ullamcorper convallis, urna sem
              vulputate lectus, eu pharetra risus quam id sem.
            </Text>
          </Col> */}
          <Col xs={24} md={24}>
            <FormCard>
              <FormCardBlock>
                <FormCardTitle>Calcular financiamiento</FormCardTitle>
                <Formik
                  initialValues={{
                    destination: '',
                    type: '',
                    plan: '',
                    years: '',
                    value: '',
                  }}
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                  render={({isSubmitting, setFieldValue, values}) => (
                    <Form>
                      <AppTabList>
                        <AppTab>Por valor del crédito</AppTab>
                        <AppTab>Por ingresos</AppTab>
                      </AppTabList>
                      <TitleLabel>Destino del crédito</TitleLabel>
                      <RadioGroup
                        value={values.destination}
                        onChange={event =>
                          setFieldValue('destination', event.target.value)
                        }>
                        <Row className="mb-2">
                          <Col md={6}>
                            <CheckBoxBox value="home">
                              <HomeOutline /> <span className="pl-2">Casa</span>
                            </CheckBoxBox>
                          </Col>
                          <Col md={6}>
                            <CheckBoxBox value="apartment">
                              <BuildingOutlineIcon />
                              <span className="pl-2">Apartamento</span>
                            </CheckBoxBox>
                          </Col>
                        </Row>
                      </RadioGroup>
                      <ErrorField name="destination" />
                      <Row>
                        <Col md={12}>
                          <SelectLabel
                            hasFormik
                            name="type"
                            label="Tipo de credito"
                            options={options}
                          />
                        </Col>
                        <Col md={12}>
                          <SelectLabel
                            hasFormik
                            label="Plan de amortización"
                            name="plan"
                            options={options}
                          />
                        </Col>
                        <Col md={6}>
                          <InputText
                            hasFormik
                            name="years"
                            label="Plazo en años"
                          />
                        </Col>
                        <Col md={6}>
                          <InputText
                            hasFormik
                            name="value"
                            label="Valor del crédito"
                          />
                        </Col>
                      </Row>
                      <Button type="submit" className="w-100 mt-4">
                        Calcular
                      </Button>
                    </Form>
                  )}
                />
              </FormCardBlock>
            </FormCard>
          </Col>
        </Row>
      </ContainerBox>
    </Wrapper>
  );
};

export default SimulateYourCredit;
