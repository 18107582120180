import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

const AvatarHeadlineSkeleton = () => {
  return (
    <div className="d-flex w-100 pr-2">
      <Skeleton
        variant="circle"
        width={84}
        style={{
          minWidth: 84,
          minHeight: 84,
        }}
        height={84}
      />
      <div className="w-100 pl-2">
        <Skeleton width="100%" />
        <Skeleton width="80%" />
      </div>
    </div>
  );
};

export default AvatarHeadlineSkeleton;
