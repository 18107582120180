import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const ProfileSkeleton = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <Skeleton variant="circle" width={136} height={136} />
      <Skeleton width="100%" />
      <Skeleton width="80%" />
    </div>
  );
};

export default ProfileSkeleton;
