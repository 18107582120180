import React from 'react';
import { Button, Col} from '@bootstrap-styled/v4';
import { CircularProgress } from '@material-ui/core';


const AppButton = ({loading, children, ...props}) => {
  return (
    <Col>
      <Button {...props}>
        {loading ? <CircularProgress /* color="white" */ size={20} /> : children}
      </Button>
    </Col>
  );
};

export default AppButton;
