import React from 'react';
import {H1, H3} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {BASE_BUCKET_URL} from '../../constants/global';
import CurrencyFormat from 'react-currency-format';

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(H1)`
  font-size: 32px !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  text-transform: capitalize;
`;

const SubTitle = styled(H3)`
  font-size: 22px !important;
  opacity: 0.6;
  margin-bottom: 0 !important;
`;

const Shared = styled.span`
  font-weight: lighter;
  color: #000000;
  opacity: 0.6;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 84px;
  height: 84px;
  background-color: gray;
  object-fit: cover;
`;

const ProjectTitle = ({image, title, price, hiddedShared = false}) => {
  return (
    <div className="d-flex">
      <Image src={`${BASE_BUCKET_URL}${image}`} />
      <Info className="pl-3">
        <Title>{title}</Title>
        <SubTitle className="text-muted">
          Desde&nbsp;
          <CurrencyFormat
            value={price}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </SubTitle>
        {!hiddedShared && <Shared>Compartir:</Shared>}
      </Info>
    </div>
  );
};

export default ProjectTitle;
