import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {Row, Col, Button, H2} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import * as yup from 'yup';
import {Formik, Form, Field} from 'formik';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from 'react-redux';
import Api from '../services/api';
import InputText from '../components/InputText';
import {addNotification, setAuthenticate} from '../actions/notifications';
import {navigate, Link} from '@reach/router';
import {getCurrentUser} from '../actions/auth';
import {Icon, message} from 'antd';
import {get_token_camacol} from '../services';
import ResetPassword from './ResetPassword';

const validationRegisterSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(56, 'Nombre es demasiado largo')
    .required('Nombre es requerido.'),
  lastName: yup
    .string()
    .max(56, 'Apellidos es demasiado largo')
    .required('Apellido es requerido.'),
  email: yup.string().required('Correo electronico es requerido.'),
  phone: yup.string().required('Teléfomo es requerido.'),
  password: yup.string().required('Contraseña es requerido.'),
  confirmPassword: yup
    .string()
    .required('Confirmar contraseña es requerido.')
    .test('password-match', 'No son iguales las contraseñas.,', function(
      confirmPassword,
    ) {
      return confirmPassword === this.parent.password;
    }),
});

const validationLoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Debe ser un correo electronico valido.')
    .required('Correo electronico es requerido.'),
  password: yup.string().required('Contraseña es requerida.'),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ColPrimary = styled(Col)`
  padding-bottom: 5.0625rem;
  padding-left: 3.8125rem !important;
  padding-right: 3.8125rem !important;
  padding-top: 5.5rem;

  @media (max-width: 768px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`;

const Title = styled(H2)`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.8;
  font-weight: bold;
  font-size: 24px;
  max-width: 272px;
  margin-bottom: 2.625rem;
`;

const ColSecondary = styled(Col)`
  background: #f7f7f7 0 0 no-repeat padding-box;
  padding-top: 3.5625rem;
  padding-bottom: 5.0625rem;
  padding-left: 3.8125rem !important;
  padding-right: 3.8125rem !important;

  @media (max-width: 768px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`;

const DialogRow = styled(Row)`
  min-height: 493px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-family: 'arial' !important;
`;

const SpacingButton = styled(Button)`
  margin-top: 2.4375rem;
`;

const CloseIconModal = styled(IconButton)`
  position: absolute !important;
  right: 20px !important;
  top: 20px !important;
  z-index: 5 !important;
`;

const LoginModal = ({
  addNotification,
  setAuthenticate,
  getCurrentUser,
  open,
  onClose,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('default');
  const [loginType, setLoginType] = useState('default');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  };

  const register = (
    {firstName, lastName, email, phone, password},
    {resetForm},
  ) => {
    setLoading(true);
    Api.users
      .create({
        email: email,
        password: password,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
      })
      .subscribe({
        next: resp => {
          addNotification('Bienvenido !', 'success');
          return authentication({
            email: email,
            password: password,
          })
            .then(() => {
              resetForm();
              handleClose();
            })
            .catch(err => {
              setLoading(false);
              message.error(err.message);
            });
        },
        error: err => {
          setLoading(false);
          addNotification(err.message, 'error');
        },
        complete: () => setLoading(false),
      });
  };

  const authentication = params => {
    return new Promise((resolve, reject) => {
      Api.login(params).subscribe({
        next: resp => {
          getCurrentUser();

          handleClose();
          console.log('USER: ', user);
          let {affiliate} = user;
          setAuthenticate(true);
          setLoading(false);
          addNotification('Inicio de sesión con exito !', 'success');

          if (affiliate === 'true') {
            navigate('/admin/mi-cuenta/proyectos');
          } else {
            navigate('/mis-favoritos');
          }
        },
        error: err => {
          setLoading(false);
          reject(err);
          return addNotification('Error inesperado !', 'error');
        },
        complete: () => {
          setLoading(false);
          resolve();
        },
      });
    });
  };
  
  const login = (params, {resetForm}) => {
    setLoading(true);
    if (loginType === 'default')
      return authentication(params)
        .then(() => resetForm())
        .catch(err => {
          setLoading(false);
          message.error(err.message);
        });

    get_token_camacol(params).then(({data}) => {
      let {accessToken} = data;
      params['strategy'] = 'camacolAuth';
      params['accessToken'] = accessToken;
      authentication(params)
        .then(() => resetForm())
        .catch(err => message.error(err.message));
    });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      maxWidth="lg"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <CloseIconModal onClick={handleClose}>
        <CloseIcon />
      </CloseIconModal>
      {loading && <LinearProgress />}
      <DialogRow>
        {view == 'default' && (
          <ColPrimary xs={12} md={6}>
            <Title>Inicia sesión</Title>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={validationLoginSchema}
              onSubmit={login}
              render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form
                  onKeyPress={event => {
                    event.key === 'Enter' && handleSubmit();
                  }}>
                  <Row>
                    <Col md={12} className="mb-2">
                      <InputText
                        hasFormik
                        name="email"
                        type="email"
                        label="Correo electrónico"
                      />
                    </Col>
                    <Col md={12}>
                      <InputText
                        hasFormik
                        name="password"
                        type="password"
                        label="contraseña"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label>
                        <Field
                          required
                          type="checkbox"
                          name="checked"
                          value="terms"
                        />
                        <span>
                          {' '}
                          Al ingresar acepto las
                          <a
                            target="_blank"
                            href="https://construcaribe.s3.amazonaws.com/assets/TRATAMIENTO%20DE%20BASES%20DE%20DATOS.pdf">
                            {' '}
                            Politicas de Tratamientos de Datos
                          </a>
                        </span>
                      </label>
                    </Col>
                    <Col>
                      <SpacingButton
                        size="lg"
                        type="submit"
                        onClick={() => setLoginType('default')}
                        disabled={loading}
                        className="w-100">
                        Iniciar sesión
                      </SpacingButton>
                    </Col>
                    <Col>
                      <SpacingButton
                        size="lg"
                        type="submit"
                        onClick={() => setLoginType('afiliate')}
                        disabled={loading}
                        className="w-100">
                        <Icon type="team" /> Iniciar sesión como constructora
                      </SpacingButton>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      margin: '16px 0px',
                    }}>
                    <Col align="center">
                      <span>
                        Olvidó su contraseña?{' '}
                        <a to="/#" onClick={() => setView('reset-password')}>
                          Recuperar contraseña
                        </a>
                      </span>
                    </Col>
                  </Row>
                </Form>
              )}
            />
          </ColPrimary>
        )}
        {view == 'reset-password' && (
          <ColPrimary xs={12} md={6}>
            <ResetPassword
              onSubmit={() => {
                setView('default');
              }}
              addNotification={addNotification}
              onChageView={view => setView(view)}
            />
          </ColPrimary>
        )}
        <ColSecondary xs={12} md={6}>
          <Title>¿Estas interesado en comprar vivienda? Regístrate aquí.</Title>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              phone: '',
              confirmPassword: '',
            }}
            validationSchema={validationRegisterSchema}
            onSubmit={register}
            render={({
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form
                onKeyPress={event => {
                  event.key === 'Enter' && handleSubmit();
                }}>
                <Row>
                  <Col md={6} className="pl-0 pb-2">
                    <InputText name="firstName" hasFormik dark label="nombre" />
                  </Col>
                  <Col md={6} className="pl-0 pb-2">
                    <InputText
                      name="lastName"
                      hasFormik
                      dark
                      label="apellidos"
                    />
                  </Col>
                  <Col md={6} className="pr-0 pb-2">
                    <InputText
                      type="email"
                      name="email"
                      dark
                      hasFormik
                      label="correo electronico"
                    />
                  </Col>
                  <Col md={6} className="pr-0 pb-2">
                    <InputText
                      type="phone"
                      name="phone"
                      dark
                      hasFormik
                      label="Teléfono/Celular"
                    />
                  </Col>
                  <Col md={6} className="pl-0">
                    <InputText
                      hasFormik
                      name="password"
                      dark
                      type="password"
                      label="contraseña"
                    />
                  </Col>
                  <Col md={6} className="pr-0">
                    <InputText
                      name="confirmPassword"
                      dark
                      hasFormik
                      type="password"
                      label="repetir contraseña"
                    />
                  </Col>
                  <Col>
                    <label>
                      <Field
                        required
                        type="checkbox"
                        name="checked"
                        value="terms"
                      />
                      <span>
                        Al registrarme acepto las
                        <a
                          target="_blank"
                          href="https://construcaribe.s3.amazonaws.com/assets/TRATAMIENTO%20DE%20BASES%20DE%20DATOS.pdf">
                          {' '}
                          Politicas de Tratamientos de Datos
                        </a>
                      </span>
                    </label>
                  </Col>

                  <Col md={12} className="pt-3">
                    <SpacingButton
                      type="submit"
                      size="lg"
                      color="success"
                      disabled={loading}>
                      Registrarse ahora
                    </SpacingButton>
                  </Col>
                </Row>
              </Form>
            )}
          />
        </ColSecondary>
      </DialogRow>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addNotification: (text, type) => dispatch(addNotification(text, type)),
    setAuthenticate: value => dispatch(setAuthenticate(value)),
    getCurrentUser: () => dispatch(getCurrentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
