import React from 'react';
import {Label, Input} from '@bootstrap-styled/v4';


const Checkbox = ({label, ...props}) => {
  return (
    <Label>  
      <Input type="checkbox" {...props} />
      <span>{label}</span>
    </Label>
  );
};

export default Checkbox;
