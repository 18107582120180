import {Button, Col, Row} from 'antd';
import React, {useState, useEffect} from 'react';
import {Grid, MyModal} from '../../components/commons/';
import {Icon} from 'antd';
import CommonZoneForm from './CommonZoneForm';
const CommonZones = props => {
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleSubmit = (err, data) => {
    setVisible(false);
    setRefresh(refresh => !refresh);
  };
  return (
    <>
      <Grid
        title="Zonas Comunes"
        btnNewtext="Agregar Zona Común"
        name="Zona Común"
        search={false}
        loading={refresh}
        filterDefaultValues={{
          project_id: props.id,
        }}
        extra={
          <>
            <Button
              type="primary"
              size="large"
              onClick={() => setVisible(true)}
              style={{
                margin: '0px 4px',
              }}>
              <Icon type="plus" /> Agregar Zonas Comunes
            </Button>
          </>
        }
        actions={{
          delete: true,
        }}
        source={'projects-common-zones'}
        columns={[
          {
            source: 'name',
            label: 'Nombre',
            sortable: false,
            render: function(value, record) {
              return record.common_zones.name;
            },
          },
        ]}
      />
      <MyModal
        title="Agregar Zonas Comunes"
        visible={visible}
        onCancel={() => setVisible(false)}>
        <CommonZoneForm onSubmit={handleSubmit} project_id={props.id} />
      </MyModal>
    </>
  );
};
export default CommonZones;
