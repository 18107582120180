import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Container } from '@bootstrap-styled/v4';
import InputText from '../components/InputText';
// import Banner from '../components/home/Banner';
// import PhoneContactIcon from '../components/icons/PhoneContactIcon';
// import MailContactIcon from '../components/icons/MailContactIcon';
import { CircularProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Api from '../services/api';
import { addNotification } from '../actions/notifications';
import { connect } from 'react-redux';
import { getService } from '../services/services';
import { message } from 'antd';
import Facebook from '../svgComponents/Facebook';
import Instagram from '../svgComponents/Instagram';
// import { HORARIO, CEL_PHONE, EMAIL } from '../utils/Constants';
// import { URL_S3, DEFAULT_COVER_CONTACT } from '../constants';
import Position from '../SvgImg/mapPoint.svg'; 
import  LogoWa  from '../SvgImg/phoneBlue.svg';
import Gmail from '../SvgImg/email.svg'; 
import Planet from '../SvgImg/planet.svg';
import {Label, Input} from '@bootstrap-styled/v4';

const SocialMedia = styled.div`
    height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
`;

const MyHeader = styled.div`
  height: 143px;
  width: 100%;
  background-color: #00438D;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.8rem;

  .header_title{
    .header_title_text {
      color: #fff;
      font-size: 35px;
    }
  }

  @media(max-width:900px){
    margin-top: 3.8rem;
  }
`;

const FormTitle = styled.p`
  font-size: 30px;
  margin-bottom: 2rem !important;
  padding: 0 .5rem;
  font-weight: bold;
`;

const FormContainer = styled(Form)`
  max-width: 958px;
  margin: 0 auto;
  padding-top: 4.8125rem;
  padding-bottom: 6.0625rem;
`;

const LineOne = styled.div`
  width: 207px;
  height: 444px;
  position: absolute;
  right: 0;
  top: 0;
  //background-image: url('/images/line-contact-one.png');
`;

const LineTwo = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 314px;
  height: 190px;
  //background-image: url('/images/line-contact-two.png');
`;

const Block = styled.div`

  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  

  .block_section_content{
    max-width: 1200px;
    /* width: 100%; */
    height: 143px;
    border-radius: 10px;
    background: #D3E8FF;
    text-align: center;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 1rem;

    @media(max-width:1290px){
      flex-direction: column;
    /* justify-content: flex-end; */
      align-items: start;
      height: 70%;
      padding-left: 1rem;
      /* width: 60%; */
    }
  }
`;

const MyInputText = styled(InputText)`
    background-color: #fff !important;
    border-radius: 10px !important; 
    border: 1px solid #BBC7D8 !important;
    height: 50px;
`;

const Relative = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

     @media(max-width:1290px){
         margin: 0 1rem;
      }
 
  .content_relative {
      border-radius: 10px;
      display: flex;
      max-width: 1280px;
      box-shadow: 1px 1px 13px 0px rgba(96, 108, 125, 0.15);
      
      @media(max-width:1290px){
          .image_women{
            display: none;
          }
      }
      /* background-color: #fef; */
   }
`;

const ContainerBox = styled(Container)`
  box-sizing: border-box;
  padding: 0 5rem !important;

   @media(max-width:1290px){
         padding: 0 1rem !important;
    }
`;

const MessageInput = styled(InputText)`
    height: 120px;
    background-color: #fff !important;
    border-radius: 10px !important; 
    border: 1px solid #BBC7D8 !important;
    margin-top: 1rem;
`;

const schema = yup.object().shape({
  name: yup
    .string()
    .max(40, 'Nombre es demasiado largo')
    .required('Nombre es requerido.'),
  text: yup
    .string()
    .max(100, 'Maximo 100 caracteres')
    .required('Comentario es requerido.'),
  email: yup
    .string()
    .email('Debe ser un E-Mail valido.')
    .required('Correo electronico es requerido.'),
  autorizacion: yup
  .boolean()
  .required("Debe acertar los terminos."),
  subject: yup
    .string()
    .max(156, 'Maximo 256 caracteres')
    .required('Asunto es requerido.'),
  phone: yup.string().required('Telefono es requerido.'),
});

const Contact = ({ addNotification }) => {
  const [loading, setLoading] = React.useState(false);
  const [images, setImages] = useState({});
  const getImage = (field, id) => {
    const service = getService('design');
    service
      .get(id)
      .then(response => {
        setImages({
          [field]: response.value,
        });
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  const sendMessage = ({ name, text, email, subject, phone }, { resetForm }) => {
    setLoading(true);
    Api.contacts
      .create({
        name,
        email,
        phone,
        subject,
        message: text,
      })
      .subscribe({
        next: resp => {
          addNotification('Mensaje enviado con exito !', 'success');
          setLoading(false);
          resetForm();
        },
        error: err => {
          addNotification('Error inesperado !', 'error');
          setLoading(false);
        },
      });
  };
  useEffect(() => {
    getImage('cover', 2);
  }, []);
  return (
    <>
      <MyHeader>
        <div className='header_title'>
          <span className='header_title_text'>Contáctanos</span>
        </div>
      </MyHeader>
      <SocialMedia>
        <Instagram />
        <Facebook />
      </SocialMedia>
      <Relative>
        <div className='content_relative'>
          <img className="image_women" src="images/women_contact.jpg" alt="women.jpg" />
          <ContainerBox>
            <Formik
              initialValues={{
                name: '',
                email: '',
                text: '',
                phone: '',
                subject: '',
              }}
              validationSchema={schema}
              onSubmit={sendMessage}
              render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <FormContainer
                  onSubmit={handleSubmit}
                  onKeyPress={event => {
                    event.key === 'Enter' && handleSubmit();
                  }}>
                  <FormTitle>Escríbenos</FormTitle>
                  <Row>
                    <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
                      <MyInputText name="name" hasFormik placeholder="Nombre" />
                    </Col>
                    <Col xs={12} md={6}>
                      <MyInputText name="phone" hasFormik placeholder="Teléfono" />
                    </Col>
                    <Col xs={12} md={6}>
                      <MyInputText
                        name="email"
                        hasFormik
                        placeholder="Correo electronico"
                      />
                    </Col>
                    <Col md={12}>
                      <MyInputText name="subject" hasFormik placeholder="Asunto" />
                    </Col>
                    <Col md={12}>
                      <MessageInput
                        max={100}
                        name="text"
                        type="textarea"
                        hasFormik
                        rows="20"
                        placeholder="Mensaje"
                      />
                    </Col>
                    <Col md={12}>
                    </Col>
                    <Col md={12} style={{ margin: "1rem 0" }}>
                      {/* <Checkbox label={'Autorizacion para el tratamiento de datos personales'}/> */}
                      <div style={{ display: "flex", gap: "1rem", justifyContent: "start", alignItems: "center", margin: "0 1rem" }}>
                        <Input id="check" type="checkbox" hasFormik name="autorizacion"/>
                        <label for="check">Autorizacion para el tratamiento de datos personales</label>
                      </div>
                    </Col>
                    <Col md={12} style={{ padding: "0 1.5rem", heigth: "50px" }}>
                      <Button className="w-100" style={{ heigth: "100%" }}>
                        {loading ? <CircularProgress size={20} /> : "Solicitar cotización"}
                      </Button>
                    </Col>
                  </Row>
                </FormContainer>
              )}
            />
          </ContainerBox>
        </div>
      </Relative>
      <Block>
        <div className='block_section_content'>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"0.5rem", margin:"0 5px"}}>
            <img src={Position} alt="point.svg"/> 
            <span>Lunes a viernes 8:00 a.m a 12:00</span>
          </div>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"0.5rem", margin:"0 5px"}}>
            <img src={LogoWa} alt="point.svg"/> 
            <span>oficina 803 (+57) 3145479067</span>
          </div>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"0.5rem", margin:"0 5px"}}>
            <img src={Gmail} alt="point.svg"/> 
            <span><a href='mailto:redes@camacolatlantico.org' target='_blank' style={{color:"black", textDecoration:"none"}}>redes@camacolatlantico.org</a></span>
          </div>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"0.5rem", margin:"0 5px"}}>
          <img src={Planet} alt="point.svg"/> 
            <span>BARRANQUILLA</span>
          </div>
        </div>
      </Block>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (text, type) => {
      dispatch(addNotification(text, type));
    },
  };
};

export default connect(null, mapDispatchToProps)(Contact);
