import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`

  color: black !important;
  font-size: 27px !important;
  font-weight: 500 !important;
  margin: 1.5rem 0 !important;

`;

const Headline = ({children, className}) => {
  return (
    <Title>
      {children}
    </Title>
  );
};

export default Headline;
