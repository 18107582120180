import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBlock,
  CardTitle,
  CardSubtitle,
  Article,
  H2,
} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import {CircularProgress} from '@material-ui/core';
import LoveIcon from '../../components/icons/LoveIcon';
import StartIcon from '../../components/icons/StartIcon';
import PinIcon from '../../components/icons/PinIcon';
import {Link, navigate} from '@reach/router';
import {connect} from 'react-redux';
import {getCurrentUser} from '../../actions/auth';
import {toggleFavorite} from '../../actions/projects';
import {ProjectImage} from '../../components/com/';


const ProjectImageWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 45%;
    width: 100%;
    background: transparent linear-gradient(180deg, #000000 0%, #80808000 100%)
      0 0 no-repeat padding-box;
    opacity: 0.5;
  }
`;

const ProjectCaption = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 22px 20px;
  width: 100%;
`;

const ProjectTitle = styled(H2)`
  font-size: 16px;
  margin-bottom: 0 !important;
  text-transform: capitalize;
  color: #fff !important;
  
`;

const ProjectStratum = styled.div`
  text-align: center;
  background-color: ${props =>  props.listColor[props.numStratus - 1]};
  border-radius: 10px;
  font-size: 14px;
  padding-top: 3px;
  width: 73px;
  height: 27px;
  white-space: nowrap;
`;

const ProjectPrice = styled.p`
  font-size: 17px;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  margin: 0 !important;
`;

const LocationText = styled.div`
  font-weight: lighter !important;
  color: rgba(0, 0, 0, 0.5) !important;
  text-transform: uppercase;

  display: flex;
  justify-content: start;
  align-items: center;

`;

const CaptionText = styled.p`
  font-weight: 500 !important;
  color: black !important;
  margin: 0;
`;

const Root = styled.div`
  background-color: transparent !important;
  cursor: pointer;
`;

const ProjectsCard = ({
  project,
  isAuthenticate,
  getCurrentUser,
  compactBlock,
  favoriteProjects,
  onChangeFavorite,
  loadingUser,
  toggleFavorite,
  ...props
}) => {
  const {stratus, price, area, neighborhood, city, name, image} = project;
  const [loading, setLoading] = useState(false);
  const colors = ["purple","blue", "#BF5418", "#258CCA", "#DFA104", "#65A92E"];

  const handleToggleFavorite = async () => {
    setLoading(true);
    try {
      await toggleFavorite(project.id);
      if (onChangeFavorite) onChangeFavorite();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const _renderLoveIcon = () => {
    if (loading || loadingUser) {
      return (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={21}
          thickness={4}
        />
      );
    }
    return (
      <LoveIcon
        onClick={handleToggleFavorite}
        isActive={favoriteProjects.includes(project.id)}
      />
    );
  };

  const IconWrappe = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
  `; 

  const numStratus = stratus === true ? 1 : Number(stratus.split(" ")[1]); 
  const linkProject = `/proyectos?menu%5Bstratus%5D=Estrato%20${numStratus}&page=2&configure%5BhitsPerPage%5D=9`; 

  return (
    <Root tag={Article} {...props}>
      <ProjectImageWrapper
        >
        <ProjectCaption className="d-flex justify-content-between text-white align-items-center">
          <ProjectTitle>{name}</ProjectTitle>
          <ProjectStratum numStratus={numStratus} listColor={colors} onClick={() => navigate(linkProject)}>{stratus}</ProjectStratum>
        </ProjectCaption>
        <ProjectImage onClick={() => navigate(`/proyectos/${project.slug}`)}
          className="w-100"
          height="257px"
          size="medium"
          src={project.image}
          alt={project.name}
        />
        
      </ProjectImageWrapper>
      <CardBlock style={{margin:'0.5rem'}} className={`pb-5 ${compactBlock && 'px-0'}`} onClick={() => navigate(`/proyectos/${project.slug}`)}>
        <div className="d-flex justify-content-between align-items-center">
          <ProjectPrice className="font-weight-normal d-flex align-items-center">
            <b className="pl-1 pr-3">
               Desde &nbsp;
              <CurrencyFormat
                value={price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </b>
            <IconWrappe>
                {isAuthenticate && _renderLoveIcon()}
                <StartIcon />
            </IconWrappe>
          </ProjectPrice>
        </div>
        <CaptionText className="text-muted">Áreas desde {area} m2</CaptionText>
        <LocationText className="text-muted">
          <PinIcon />
          <span className="pl-1" style={{fontSize:"0.7rem", fontWeight: 500, marginLeft: "0.5rem", marginTop:0}}>
            {neighborhood ? neighborhood + ',' : ''} {city}
          </span>
        </LocationText>
      </CardBlock>
    </Root>
  );
};

ProjectsCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    stratus: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    area: PropTypes.number.isRequired,
    neighborhood: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    onChangeFavorite: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    isAuthenticate: state.auth.isAuthenticate,
    favoriteProjects: state.auth.user.favorite_projects,
    loadingUser: state.auth.user.loadingUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCurrentUser: () => dispatch(getCurrentUser()),
    toggleFavorite: id => dispatch(toggleFavorite(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCard);
