import React from 'react';
import {Link} from '@material-ui/core';
import {connectCurrentRefinements} from 'react-instantsearch-dom';
import Styled from 'styled-components';

const MyLink = Styled(Link)`

    width: 100% !important;
    height: 44px !important;
    background-color: #00438D;
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 10px;
    cursor: pointer !important;  

    .text-link {
      color: white;
      font-size: 16px;
    }

    @media(max-width: 576px){
      justify-content: center;
      /* position: relative;
      top: -3rem; */
      max-width: 75% !important;
    }
    
`;

const ClearRefinements = ({items, refine, onClear}) => (
  <MyLink
    onClick={() => {
      refine(items);
      if (onClear) onClear(items);
    }}
    disabled={!items.length}>
    <span className='text-link' style={{fontSize:"20px"}}>Limpiar</span>
  </MyLink>
);

export default connectCurrentRefinements(ClearRefinements);
