import React, {useState} from 'react';
import {
  Row,
  Col,
  List,
  Card,
  Collapse,
  Tag,
  message,
  Table,
  Button,
  Tooltip,
} from 'antd';
import PhaseForm from '../phases/PhaseForm';
/* import Table from '../Table'; */
import Headline from '../account/Headline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import './phases.css';
import Modal from '../modal/Modal';
import PhaseTypeForm from './PhaseTypeForm';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Api from '../../services/api';
import {getService} from '../../services/services';
import FeatureForm from './FeatureForm';
const Actions = styled.div`
  display: flex;
  justifycontent: space-between;
  & button {
    height: 40px !important;
    width: 40px !important;
  }
  & button {
    margin: 0px 4px !important;
  }
  & button i {
    vertical-align: middle !important;
    padding: 10px !important;
  }
`;
const {Panel} = Collapse;
const customPanelStyle = {
  borderRadius: 4,
  marginBottom: 0,
  border: 0,
  overflow: 'hidden',
};
const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
  },
  {
    title: 'Uso',
    dataIndex: 'use',
  },
  {
    title: 'Area por tipo',
    dataIndex: 'area_by_type',
  },
  {
    title: 'Precio en miles',
    dataIndex: 'last_price_in_thousands',
  },
  {
    title: 'Precio en m2',
    dataIndex: 'last_price_in_m2',
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    render: value => (
      <span>
        {<Tag color={value == 'Activo' ? 'green' : 'red'}>{value}</Tag>}
      </span>
    ),
  },
];
const Phase = props => {
  /* let tipos_fases = props.tipos_fases || []; */
  const [show_type, showType] = useState(false);
  const [isActive, setActive] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [tipos_fases, setTiposFase] = useState([]);
  const [actionType, setActionType] = useState();
  const [type_record, stTypeData] = useState({
    use: 'Apartamento',
    purpose: 'Venta',
    status: 'Activo',
  });
  const onAction = (action, record) => {
    setActionType(action);
    stTypeData(record || {});
    showType(true);
  };
  const create = values => {
    let {id} = props;
    values['project_phase_id'] = id;
    values['project_type_id'] = type_record.id;
    const service = getService(actionType);
    return Api.projectTypes.create(values).subscribe({
      next: response => {
        
      },
      complete: () => {
        showType(false);
        getTypes();
      },
      error: err => {
        showType(false);
        message.error(err.message);
      },
    });
  };
  const edit = values => {
    let {id} = values;
    values['project_phase_id'] = props.id;
    values['project_type_id'] = type_record.id;
    if (actionType == 'projects-types-features') {
      id = type_record.features.id;
    }
    const service = getService(actionType);
    console.log('plano::', values);
    if (id)
      return service
        .patch(id, values)
        .then(response => {
          showType(false);
          getTypes();
        })
        .catch(err => {
          showType(false);
          message.error(err.message);
        });
    service
      .create(values)
      .then(response => {
        showType(false);
        getTypes();
      })
      .catch(err => {
        showType(false);
        message.error(err.message);
      });
  };
  const getTypes = () => {
    let {id} = props;
    return Api.projectTypes
      .find({
        query: {
          project_phase_id: id,
          $limit: 1000,
        },
      })
      .subscribe({
        next: response => {
          let {data} = response;
          setTiposFase(data);
        },
        complete: () => {},
        error: err => console.log(err.message),
      });
  };
  return (
    <Card className="item-card-phase">
      <Collapse
        destroyInactivePanel={true}
        className="item-card-phase-collapse"
        accordion
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({isActive}) => setActive(isActive)}>
        <Panel
          className="header-type"
          style={customPanelStyle}
          header={
            <Row
              className="header-item-card"
              type="flex"
              justify="start"
              align="middle"
              gutter={8}>
              <Col>
                <>
                  {isActive ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}{' '}
                </>
              </Col>
              <Col>
                <strong>Nombre: </strong>
                {props.name}
              </Col>
              <Col>
                <strong>Unidades: </strong>
                {props.number_of_units}
              </Col>
              <Col>
                <strong>Propósito: </strong>
                {props.purpose}
              </Col>
              <Col>
                <strong>Estado: </strong>
                <Tag color={props.status == 'ABIERTA' ? 'green' : 'red'}>
                  {props.status}
                </Tag>
              </Col>
            </Row>
          }>
          <React.Fragment>
            <Row gutter={8} type="flex" justify="center" align="middle">
              <Col span={24} className="form-container-phase">
                <PhaseForm
                  onSubmit={props.onSubmit}
                  bindSubmit={true}
                  readOnly={true}
                  data={{...props}}
                />
              </Col>
              <Col span={24}>
                <React.Fragment>
                  <Headline
                    /*  title={<span>Tipos de Fases</span>} */
                    action="Crear Tipo"
                    handleClick={() => {
                      // showType(true);
                      onAction('projects-types');
                    }}
                  />
                </React.Fragment>
                <Collapse
                  destroyInactivePanel={true}
                  className="tipos-fases-collapse"
                  accordion
                  bordered={false}
                  defaultActiveKey={['1']}
                  onChange={() => {
                    let {id} = props;
                    if (!initialized) {
                      getTypes();
                      setInitialized(true);
                    }
                  }}
                  /* expandIcon={({isActive}) => setActive(isActive)} */
                >
                  <Panel
                    className="header-type"
                    header={<span>Tipos de Fases</span>}>
                    <Table
                      rowKey="id"
                      pagination={false}
                      columns={[
                        ...columns,
                        {
                          title: 'Edición',
                          key: 'action',
                          render: (text, record) => (
                            <Actions>
                              {
                                <Tooltip title="Características">
                                  <Button
                                    size="large"
                                    type="dashed"
                                    icon="build"
                                    onClick={() =>
                                      onAction(
                                        'projects-types-features',
                                        record,
                                      )
                                    }
                                  />
                                </Tooltip>
                              }
                              <Tooltip title="Editar">
                                <Button
                                  className="btn-gray"
                                  primary="true"
                                  onClick={() =>
                                    onAction('projects-types', record)
                                  }>
                                  <EditIcon />
                                </Button>
                              </Tooltip>
                              {/*
                              <Tooltip title="Borrar">
                                <Button
                                  className="btn-gray"
                                  primary="true"
                                  onClick={() =>
                                    Api.projectTypes
                                      .remove(record.id)
                                      .toPromise()
                                      .then(() => getTypes())
                                  }>
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                              */}
                            </Actions>
                          ),
                        },
                      ]}
                      dataSource={tipos_fases}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            {
              <Modal
                onCancel={() => {
                  showType(false);
                  stTypeData({});
                }}
                title={
                  type_record['id']
                    ? `Editar ${type_record['name'] || 'Registro'}`
                    : 'Crear Nuevo'
                }
                open={show_type}
                onSubmit={(values, actions) => {
                  console.log('ActionType: ', actionType, values);
                  if (values['id']) return edit(values);
                  create(values);
                  stTypeData({});
                }}
                form={
                  actionType == 'projects-types' ? (
                    <PhaseTypeForm data={type_record} />
                  ) : (
                    <FeatureForm data={type_record.features} />
                  )
                }
              />
            }
          </React.Fragment>
        </Panel>
      </Collapse>
    </Card>
  );
};
const Phases = props => {
  let data = props.data || [];
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24}>
        <List
          className="phases-list"
          loading={props.loading}
          itemLayout="vertical"
          size="large"
          gutter={0}
          bordered={false}
          dataSource={data}
          renderItem={(item, index) => {
            return (
              <List.Item key={index}>
                <Phase {...item} onSubmit={props.onSubmit} />
              </List.Item>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default Phases;
