import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import BootstrapProvider from '@bootstrap-styled/provider';
import * as serviceWorker from './serviceWorker';
import theme from './modules/theme';
import {Normalize} from 'styled-normalize';
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import {Provider} from 'react-redux';
import {store} from './config';
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick-theme.css';
import './views/projects.css'; 
import './index.css';

//Inicializacion tag manager 
const tagManagerArgs = {
  gtmId: 'GTM-NVQKNNK'
}

TagManager.initialize(tagManagerArgs)

const muiTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: purple,
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: ['League Spartan'].join(','),
  },
});

/* import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  ${Normalize}

  // You can continue writing global styles herec
  body {
    padding: 0;
    background-color: black;
  }
` */

let root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store} onUpdate={() => window.scrollTo(0, 0)}>
    <ThemeProvider theme={muiTheme}>
      <BootstrapProvider theme={theme}>
        <Normalize />
        <App />
      </BootstrapProvider>
    </ThemeProvider>
  </Provider>,
    root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
