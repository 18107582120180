import React from 'react';
import {Table} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {LinearProgress} from '@material-ui/core';

const AppTable = styled(Table)`
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  max-width: 1055px;

  & th {
    color: #8dc63f;
    font-weight: bold;
    background-color: #fafafa;
    text-align: center;
    vertical-align: middle !important;
  }

  & td {
    text-align: center;
    vertical-align: middle !important;
  }

  @media (min-width: 769px) {
    display: table !important;
  }
`;

const BaseTable = ({loading, children}) => {
  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      <AppTable className="table-responsive">{children}</AppTable>
    </React.Fragment>
  );
};

export default BaseTable;
