import React from 'react';
import styled from 'styled-components';
import { H3 } from '@bootstrap-styled/v4';


const Image = styled.div`
  background-image: url('/images/no-data.png');
  max-width: 636px;
  width: 100%;
  height: 565px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Wrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
`;

//Aqui hay un h3 !!!!!!!
const NoData = ({title}) => {
  return (
    <Wrapper className="d-flex flex-column justify-content-center align-items-center">
      <Image />
      <H3>{title}</H3> 
    </Wrapper>
  );
};

export default NoData;
