import React, {useState, useEffect, useRef} from 'react';
import CustomModal from './modal/Modal';
import Form from './form/AdvancedForm';
import {Switch, S3Uploader, SelectField as Select} from './form/fields';
import Map from './form/map/MapBox';
import lodash from 'lodash';
import {Input, InputNumber, message} from 'antd';
import './crud.css';
import {getService} from '../../../services/services';
/*  */
import {Row, Col, List, Icon, Slider, Button, Modal} from 'antd';
const defaultActions = [
  {
    action: 'edit',
    icon: 'edit',
  },
  {
    action: 'delete',
    icon: 'delete',
  },
];
const {confirm} = Modal;
const style = {
  button: {
    width: 40,
    height: 40,
    float: 'right',
  },
  icon: {
    padding: 0,
  },
  flex: {
    width: '100%',
  },
};
const Store = props => {
  let {model} = props;
  let {service, baseParams = {}, id} = model;
  const [data, setData] = useState(model.data || []);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const load = () => {
    setLoading(true);
    setLoaded(false);
    setData([]);
    if (id)
      return service(id, baseParams).then(({data}) => {
        /*  let {data} = response; */
        setData(data);
        setLoading(false);
      });
    service(baseParams).then(({data}) => {
      /*  let {data} = response; */
      setData(data);
      setLoading(false);
    });
    setLoaded(true);
  };
  if (typeof service === 'function' && !loaded) {
    load();
  }
  return !loading && <Select onRefresh={load} {...props} dataSource={data} />;
};
const Factory = props => {
  let {xtype} = props;
  switch (xtype) {
    case 'selectfield':
      return <Store {...props} />;
      break;
    case 'switch':
      return <Switch {...props} />;
      break;
    case 'fileuploader':
      return <S3Uploader {...props} />;
      break;
    case 'textarea':
      return <Input.TextArea {...props} />;
      break;
    case 'map':
    case 'slider':
      return <Slider {...props} />;
      break;
    case 'map':
      return (
        <Row {...props} type="flex" justify="center" align="middle">
          <Col span={24}>
            <div
              style={{
                width: '100%',
                height: 200,
                marginBottom: 60,
              }}>
              {props.title && <h2>{props.title}</h2>}
              {
                <Map
                  onClick={(eOpts, e) => {
                    let {lngLat} = e;
                    if (props.onChange) props.onChange(lngLat);
                  }}
                  zoom={20}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
            </div>
          </Col>
        </Row>
      );
      break;
    case 'numberfield':
      return <InputNumber size={props.size || 'large'} {...props} />;
      break;
    case 'custom':
      return (
        <Row {...props} type="flex" justify="center" align="middle">
          <Col span={24}>
            <div
              style={{
                width: '100%',
                height: 'auto',
                marginBottom: 8,
              }}>
              {props.title && <h2>{props.title}</h2>}
              {props.renderItem ? props.renderItem(props) : null}
            </div>
          </Col>
        </Row>
      );
      break;
    default:
      return <Input size={props.size || 'large'} {...props} />;
      break;
  }
};

const CRUD = props => {
  let {model} = props;
  if (model) props = {...props, ...model};
  let {
    title,
    name = '',
    columns = [],
    service,
    showHeaders = true,
    allowCreate = true,
    autoLoadRecord,
    actions = [],
    displayMessageError = false,
    allRequired,
    autoSubmit = true,
    autoLoad = false,
    footer,
    emptyRecord = false,
    grid,
    source,
    autoRefresh = false,
    params = {},
  } = props;

  actions =
    actions.length > 0
      ? actions.filter(item => {
          if (item) return true;
        })
      : defaultActions;
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [items, setItems] = useState([]);
  const [data, setData] = useState((model && model.data) || []);
  const [filters, setFilters] = useState({});
  const [record, setRecord] = useState({});
  /* const [autoLoad, setAutoLoad] = useState(false); */
  const [loading, setLoading] = useState(props.loading || true);
  const [mode, setMode] = useState(props.mode || 'default');
  const [btnNewtext, setBtnNewtext] = useState(
    props.btnNewtext || 'CREAR NUEVO',
  );
  const [layout, setLayout] = useState(props.layout || 'default');

  const onRemove = id => {
    if (service) {
      return service
        .remove(id)
        .then(response => {
          message.info('Registro eliminado.');
          loadData(filters);
        })
        .catch(err => message.error(err.message));
    }
  };
  const onAction = (action, record) => {
    switch (action) {
      case 'edit':
        setRecord(record);
        setItems(getFields(record));
        setVisible(true);
        break;
      case 'delete':
        confirm({
          title: `Desea eliminar éste ${name ? name : 'registro'}?`,
          content: `Al hacer click en el botón OK, se eliminará el ${
            name ? name : 'registro'
          }.`,
          onOk() {
            if (onRemove) onRemove(record.id);
          },
          onCancel() {},
        });
        break;
      default:
        if (props.onAction) props.onAction(action, record);
        break;
    }
  };
  const Submit = data => {
    return new Promise((resolve, reject) => {
      if (service) {
        let primaryKey = props['primaryKey'] || 'id';
        let id = data[primaryKey];

        setSubmitting(true);

        if (!submitting) {
          if (id) {
            return service
              .patch(id, data)
              .then(response => {
                // loadData(params);
                window.location.reload();
                setSubmitting(false);
                resolve();
              })
              .catch(response => {
                reject(response);
              });
          }
          return service
            .create(
              lodash.merge(
                {},
                data,
                params.query.project_id
                  ? {project_id: params.query.project_id}
                  : {},
              ),
            )
            .then(response => {
              // loadData(params);
              window.location.reload();
              setSubmitting(false);
              resolve();
            })
            .catch(response => {
              reject(response);
            });
        }
      }
    });
  };
  const handleSubmit = (err, data) => {
    if (err) {
      if (!displayMessageError) return;
      let msg = [];
      err = Object.values(err).map(error => {
        error.errors.map(item => {
          msg.push(item['message']);
        });
      });
      return message.error(
        msg.map(error => {
          return (
            <span
              style={{
                textAlign: 'left',
              }}>
              {error}
              <br />
            </span>
          );
        }),
      );
    }

    return new Promise((resolve, reject) => {
      if (!submitting) {
        setSubmitting(true);
        Submit(data)
          .then(response => {
            message.info(
              `Registro ${!data.id ? 'creado' : 'actualizado'} con éxito`,
            );

            // loadData(params);
            window.location.reload();
            setVisible(false);
            setSubmitting(false);
            let form = formRef.current;
            if (form) {
              form.resetFields();
            }
            if (props.onSubmit) {
              props.onSubmit(response);
            }
            resolve(response);
          })
          .catch(err => {
            message.error(err.message || JSON.stringify(err));
            setSubmitting(false);
            reject(err);
          });
      }
    });
  };
  const loadData = (params = {}) => {
    return new Promise((resolve, reject) => {
      if (service) {
        setLoading(true);
        service.find(params).then(response => {
          let {data} = response;
          setLoading(false);
          if (mode === 'default') {
            data = data.map(item => {
              item['onAction'] = onAction;
              return item;
            });
          } else {
            if (Array.isArray(data)) {
              setData(data);
            }
            //data = data.length > 0 ? data[0] : {};
            if (!emptyRecord) {
              setRecord(data);
            }
            return resolve(data);
          }
          /* if (Array.isArray(data)) {
              setData(data);
            } */
          setLoading(false);
          resolve(data);
        });
      }
    });
  };
  const renderHeader = columns => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        gutter={8}
        style={{
          padding: '0px 0px 8px 0px',
        }}>
        {columns.map((col, index) => {
          col.span = col.span || Math.round(24 / (columns.length + 1));
          return (
            <Col
              key={index}
              span={col.span}
              align={col.align ? col.align : 'left'}>
              <div className={`col-crud ${col.align ? col.align : 'left'}`}>
                <h3>{col.text}</h3>
              </div>
            </Col>
          );
        })}
        {mode === 'default' && actions.length > 0 && (
          <Col
            style={{
              margin: '0px auto',
            }}>
            <div className={`col-crud center`}>
              <h3>Acciones</h3>
            </div>
          </Col>
        )}
      </Row>
    );
  };
  const renderItemList = ({item = {}, columns = [], actions}) => {
    let {onAction} = item;
    actions = actions ? actions : defaultActions;
    return (
      <List.Item
        style={{
          borderBottom: '1px solid #cccccc75',
          marginBottom: 0,
          paddingTop: 8,
        }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={8}
          style={{
            padding: '0px 0px 8px 0px',
          }}>
          {columns.map((col, index) => {
            col.span = col.span || Math.round(24 / (columns.length + 1));
            return (
              <Col
                key={index}
                span={col.span}
                align={col.align ? col.align : 'left'}>
                <div className={`col-crud ${col.align ? col.align : 'left'}`}>
                  {!col.render ? item[col.dataIndex] : col.render(item)}
                </div>
              </Col>
            );
          })}
          {actions.length > 0 && (
            <Col align="center" className="action-container">
              <div className={`col-crud center`}>
                <Row
                  type="flex"
                  gutter={5}
                  align="middle"
                  justify="center"
                  style={{
                    marginRight: 0,
                  }}>
                  {actions
                    .filter(item => {
                      if (item) return true;
                    })
                    .map((el, index) => {
                      let {action, icon, key} = el;
                      return (
                        <Col align="center" key={key || index}>
                          <Button
                            onClick={() => onAction(action, item)}
                            style={el.style || style.button}
                            shape="circle"
                            key={key || index}
                            icon={icon}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Col>
          )}
        </Row>
      </List.Item>
    );
  };
  const getFields = (record = {}) => {
    let {fields} = props;
    let items = [];
    let primaryKey = props['primaryKey'] || 'id';
    let isPrimary = false;
    if (fields)
      fields.forEach((field, index) => {
        let type = field.type || 'textfield';
        field[type] = type;
        if (field['name'] === primaryKey) isPrimary = true;
        /* Reset Values */
        if (field['initialValue']) delete field['initialValue'];
        if (field['initial']) delete field['initial'];
        /* Reset Values */
        if (field['name'] in record)
          field['initialValue'] = record[field['name']];
        field['initial'] = record[field['name']];
        items.push(<Factory key={index} {...field} />);
      });
    if (!isPrimary) {
      let primary = {
        name: primaryKey,
        type: 'hidden',
      };
      items.push(<Factory {...primary} />);
    }
    return items;
  };
  const getRecord = id => {
    if (source) {
      const service = getService(source);
      if (service && id) {
        setLoading(true);
        loadData(props.params).then(data => {
          console.log(mode === 'form' && data[0] ? data[0] : data);
          setItems(getFields(mode === 'form' && data[0] ? data[0] : data));
          setLoading(false);
        });
      }
    }
  };
  const loadRecord = record => {
    if (!autoLoad) return setItems(getFields(record));
    let {project_id} = props.params;
    loadData(props.params).then(data => {
      if (!emptyRecord) return setItems(getFields({}));
      setItems(getFields(data));
    });
  };
  const handleOnChange = (key, value) => {
    /* alert(key + ":" + value +"-->"+ autoRefresh) */
    if (autoRefresh) {
      if (props.mode === 'default') {
        setFilters(props.params);
        loadData(props.params).then(data => {
          setData(data);
        });
        setItems(getFields(record));
      } else {
        loadRecord(record);
      }
      return () => {
        setRecord({});
        setItems([]);
      };
    }
  };
  const formRef = useRef();
  const gridRef = useRef();
  useEffect(() => {
    let {project_id} = props.params.query;
    if (data.length > 0)
      setData(
        data.map(item => {
          item['onAction'] = onAction;
          return item;
        }),
      );

    if (props.mode === 'default') {
      setFilters(props.params);
      loadData(props.params).then(data => {
        setData(data);
        setItems(getFields(data));
      });
      /* setItems(getFields(record)); */
    } else {
      if (props.autoLoad) {
        getRecord(project_id);
      } else {
        if (emptyRecord) {
          loadRecord({});
        } else {
          loadRecord(record);
        }
      }
    }
    return () => {
      setRecord({});
      setItems([]);
    };
  }, []);
  return (
    <div className="crud-container">
      <Row className="perfil-toolbar" type="flex" gutter={16}>
        {title && mode != 'field' && (
          <Col span={12} align="start">
            <h2>{title || `Listado ${name ? ' de ' + name : ''}`}</h2>
          </Col>
        )}
        {allowCreate && mode == 'default' && layout === 'default' && (
          <Col span={12}>
            <Button
              style={{
                float: 'right',
              }}
              onClick={() => {
                setRecord({});
                setItems(getFields(record));
                setVisible(true);
              }}
              size="large"
              className="btn-green">
              <Icon type="plus" />{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}>
                {btnNewtext || 'CREAR NUEVO'}
              </span>
            </Button>
          </Col>
        )}
      </Row>
      <Row type="flex" align="middle" justify="center">
        {mode === 'form' && !loading && (
          <Form
            ref={formRef}
            onChange={handleOnChange}
            footer={footer}
            autoSubmit={autoSubmit}
            allRequired={allRequired}
            onSubmit={handleSubmit}
            submitting={submitting}>
            <div>{items}</div>
          </Form>
        )}
        {mode === 'field' && !loading && (
          <Form
            ref={formRef}
            onChange={handleOnChange}
            className="form-simple-field"
            footer={footer}
            formLayout="inline"
            autoSubmit={autoSubmit}
            allRequired={allRequired}
            onSubmit={handleSubmit}
            submitting={submitting}>
            <div>{items}</div>
          </Form>
        )}
        {(mode == 'default' || layout === 'grid') && (
          <>
            {title && (
              <Col span={12} align="start">
                <h2>{title || `Listado ${name ? ' de ' + name : ''}`}</h2>
              </Col>
            )}
            <List
              ref={gridRef}
              /* loading={loading} */
              className="crud-list"
              itemLayout="vertical"
              grid={
                grid
                  ? {...grid}
                  : {
                      column: 1,
                      gutter: 16,
                      /*  xl:6,
              lg:6,
              md:6,
              xs:6 */
                      /* xl: Math.round(24 / (columns.length + 1)),
              lg: Math.round(24 / (columns.length + 1)),
              md: Math.round(24 / (columns.length + 1)),
              xs: 24 */
                    }
              }
              style={{
                width: '100%',
              }}
              dataSource={data}
              renderItem={item => {
                if (props.renderItem)
                  return props.renderItem({
                    item,
                    columns,
                    actions,
                    form: formRef,
                    grid: gridRef,
                    crud: {
                      load: function(filters) {
                        loadData(filters || params);
                      },
                    },
                  });
                return renderItemList({
                  item,
                  columns,
                  actions,
                  form: formRef,
                  grid: gridRef,
                  crud: {
                    load: function(filters) {
                      loadData(filters || params);
                    },
                  },
                });
              }}
              header={showHeaders ? renderHeader(columns) : null}
            />
          </>
        )}
        {visible && (
          <CustomModal
            closable
            title={
              <h2>
                {record.id ? (
                  <span>
                    <Icon type="edit" /> {`Editar ${name}`}
                  </span>
                ) : (
                  <span>
                    <Icon type="plus-circle" /> {`Crear ${name}`}
                  </span>
                )}
              </h2>
            }
            className="modal-company"
            visible={visible}
            footer={null}
            onCancel={() => {
              setRecord({});
              setVisible(false);
            }}>
            <div>
              <Form
                ref={formRef}
                onChange={handleOnChange}
                footer={footer}
                autoSubmit={autoSubmit}
                allRequired={allRequired}
                onSubmit={handleSubmit}
                submitting={submitting}>
                <div>{items}</div>
              </Form>
            </div>
          </CustomModal>
        )}
      </Row>
    </div>
  );
};

export default CRUD;
