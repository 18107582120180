import React, {useEffect, useState} from 'react';
import SeoEngine from '../components/SeoEngine';
import api from '../services/api';
import styled from 'styled-components';

Magazine.propTypes = {};

const Container = styled.div`
    height: 830px;
    margin-top: 3rem;

    @media(max-width: 576px){
      height: 900px;
    }
`; 

function Magazine(props) {
  const [link, setLink] = useState('');
  useEffect(() => {
    api.magazine
      .findAll({query: {$limit: 1, $sort: {position: -1}}})
      .subscribe({
        next: data => {
          if (data.length) {
            setLink(data[0].link);
          }
        },
      });
  });

  return (
    <Container>
      <SeoEngine
        title={'Construcaribe'}
        description={
          'Encuentra todos los proyectos nuevos del atlántico en un solo lugar'
        }
      />
      <iframe 
        src={link}
        frameBorder="0"
        allowusermedia={true}
        allowFullScreen={true}
        allowTransparency={true}
        scrolling={'no'}
        seamless={'seamless'}
        style={{width: '100%', height: '768px'}}
      />
    </Container>
  );
}

export default Magazine;
