import React from 'react';

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 17.939" {...props}>
    <g data-name="Grupo 70" opacity={0.6}>
      <path
        data-name="Trazado 86"
        d="M17.512 12.368l-2.712-1.31 2.715-1.311a.864.864 0 000-1.556L14.8 6.881l2.712-1.311a.864.864 0 000-1.556l-4.028-1.945a.263.263 0 10-.228.473l4.028 1.945a.338.338 0 010 .61L9.148 9.024a.337.337 0 01-.294 0L.717 5.097a.338.338 0 010-.61L8.853.559a.337.337 0 01.294 0l3 1.449a.263.263 0 00.229-.473l-3-1.449a.859.859 0 00-.751 0L.489 4.014a.864.864 0 000 1.556L3.2 6.881.489 8.191a.864.864 0 000 1.556L3.2 11.058l-2.711 1.31a.864.864 0 000 1.556l8.136 3.928a.86.86 0 00.751 0l3.3-1.591a.263.263 0 00-.229-.473l-3.3 1.591a.337.337 0 01-.294 0L.717 13.451a.338.338 0 010-.61l3.091-1.491 4.817 2.325a.859.859 0 00.751 0l4.817-2.325 3.091 1.492a.338.338 0 010 .61l-3.734 1.8a.263.263 0 10.228.473l3.734-1.8a.864.864 0 000-1.557zm-8.365.833a.337.337 0 01-.294 0L.717 9.274a.338.338 0 010-.61l3.091-1.492 4.817 2.325a.859.859 0 00.751 0l4.817-2.325 3.091 1.492a.338.338 0 010 .61z"
      />
    </g>
  </svg>
);

export default SvgComponent;
