import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const SeoEngine = ({ title, image, canonical, description }) => {
    useEffect(() => {
        // Remove the default static meta description in favor of react helmets dynamic one
        if (document.querySelector("[name='description']")) {
            document.querySelector("[name='description']").remove()
        }
        return () => {
        }
    }, [])
    return (
        <Helmet>
            <meta charSet="utf-8" />
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {<link rel="canonical" href={canonical || window.location.href} />}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://construcaribe.com/" />
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            <meta property="og:image" content={image ? image : "https://construcaribe.com/images/slogo2.png"} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://construcaribe.com/" />
            {title && <meta property="twitter:title" content={title} />}
            {description && <meta property="twitter:description" content={description} />}
            <meta property="twitter:image" content={image ? image : "https://construcaribe.com/images/slogo2.png"} />
            <script>
                {
                    `!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '165573478804780');
                    fbq('track', 'PageView');`
                }
            </script>
        </Helmet>
    )
};
export default SeoEngine;
