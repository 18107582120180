import React from 'react';

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15.099 17.501" {...props}>
    <g stroke="#000" strokeWidth={0.5}>
      <path
        data-name="Trazado 223"
        d="M3.636 11.456H.751a.5.5 0 00-.5.5v4.8a.5.5 0 00.5.5h2.885a.5.5 0 00.5-.5v-4.8a.5.5 0 00-.5-.5zm-.5 4.8H1.251v-3.8h1.887v3.8zm0 0"
      />
      <path
        data-name="Trazado 224"
        d="M8.994 8.774H6.107a.5.5 0 00-.5.5v7.481a.5.5 0 00.5.5h2.887a.5.5 0 00.5-.5V9.274a.5.5 0 00-.5-.5zm-.5 7.481H6.603V9.77h1.891zm0 0"
      />
      <path
        data-name="Trazado 225"
        d="M14.35 4.722h-2.887a.5.5 0 00-.5.5v11.533a.5.5 0 00.5.5h2.887a.5.5 0 00.5-.5V5.22a.5.5 0 00-.5-.498zm-.5 11.533h-1.891V5.718h1.891zm0 0"
      />
      <path
        data-name="Trazado 226"
        d="M.782 6.513a.5.5 0 00.231-.062l5.2-2.735 2.072 1.077a.5.5 0 00.472-.007l4.613-2.574-.177.468a.5.5 0 10.931.353l.659-1.74a.517.517 0 00.031-.215.5.5 0 00-.461-.462L12.568.261a.5.5 0 00-.195.977l.515.1-4.381 2.448-2.065-1.07a.5.5 0 00-.461 0L.549 5.574a.5.5 0 00.232.939zm0 0"
      />
    </g>
  </svg>
);

export default SvgComponent;
