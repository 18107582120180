import React, {useState} from 'react';
import BaseModal from './BaseModal';
import {Col, Row, Button} from '@bootstrap-styled/v4';
import {Form, Formik} from 'formik';
import InputText from './InputText';
import * as yup from 'yup';
import styled from 'styled-components';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(40, 'El nombre es demasiado largo')
    .required('Nombre es requerido.'),
  lastName: yup
    .string()
    .max(40, 'Apellidos es demasiado largo')
    .required('Apellido es requerido.'),
  phone: yup.string().required('Telefono es requerido.'),
  comment: yup
    .string()
    .max(256, 'Maximo 256 caracteres')
    .required('Comentario es requerido.'),
  email: yup.string().required('Correo electronico es requerido.'),
});

const Wrapper = styled.div`
  width: 90%;
  max-width: 480px;
  margin: 0 auto;
  display: block;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const CommentInput = styled(InputText)`
  height: 141px;
`;

const ScheduleModal = props => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54'),
  );

  const sendData = () => {};

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const renderInput = ({
    InputProps,
    label,
    onClick,
    value,
    id,
    disabled,
    inputProps,
  }) => {
    return (
      <InputText
        label={label}
        type="text"
        onClick={InputProps.endAdornment.props.children.props.onClick}
        id={id}
        value={value}
        disabled={disabled}
        {...inputProps}
      />
    );
  };

  return (
    <BaseModal
      maxWidth="sm"
      title="Agenda una cita ahora"
      loading={loading}
      {...props}>
      <Col xs={12}>
        <Wrapper>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              comment: '',
              phone: '',
            }}
            validationSchema={schema}
            onSubmit={sendData}
            render={({
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Row>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Col md={12}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Fecha"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        TextFieldComponent={renderInput}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        TextFieldComponent={renderInput}
                        label="Hora"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Col>
                  </MuiPickersUtilsProvider>
                  <Col md={12} className="pl-0 pb-2">
                    <InputText name="firstName" hasFormik label="nombres" />
                  </Col>
                  <Col md={12} className="pl-0 pb-2">
                    <InputText name="lastName" hasFormik label="apellidos" />
                  </Col>
                  <Col md={12} className="pr-0 pb-2">
                    <InputText
                      type="email"
                      name="email"
                      hasFormik
                      label="correo electronico"
                    />
                  </Col>
                  <Col md={12} className="pl-0 pb-2">
                    <InputText name="phone" hasFormik label="teléfono" />
                  </Col>
                  <Col md={12}>
                    <CommentInput
                      name="comment"
                      hasFormik
                      type="textarea"
                      rows="20"
                      label="comentarios"
                    />
                  </Col>
                  <Col md={12} className="pt-3">
                    <SubmitButton
                      type="submit"
                      size="lg"
                      className="w-100"
                      color="success"
                      disabled={isSubmitting}>
                      Agendar cita
                    </SubmitButton>
                  </Col>
                </Row>
              </Form>
            )}
          />
        </Wrapper>
      </Col>
    </BaseModal>
  );
};

export default ScheduleModal;
