const { REACT_APP_API_ENDPOINT } = process.env;

export const BASE_URL_API = `https://${REACT_APP_API_ENDPOINT}`
export const DEFAULT_AVATAR_IMAGE = "/images/placeholder.png";
export const DEFAULT_IMAGE = "/images/placeholder.png";
export const DEFAULT_COVER = "/images/home.png";
export const DEFAULT_COVER_CONTACT = "/images/contact.png";
export const URL_S3 = "https://construcaribe.s3.amazonaws.com";
export const API_KEY_GOOGLE_MAPS = 'AIzaSyDi3hC8BQa2isaNSKwN4ZVGFnZ9VwIepeg';
export const URL_AUTHENTICATION = '/authentication';
