import React from 'react';

const SvgComponent = props => (
  <svg width="2em" height="2em" viewBox="0 0 44.34 33.71" {...props}>
    <g opacity={0.6}>
      <path
        data-name="Trazado 250"
        d="M37.493 29.104a1.034 1.034 0 01-.207-.022.967.967 0 01-.2-.059 1.077 1.077 0 01-.183-.1 1.111 1.111 0 01-.161-.133 1.04 1.04 0 01-.133-.162 1.07 1.07 0 01-.1-.182 1.108 1.108 0 01-.061-.2 1.038 1.038 0 01-.02-.208 1.024 1.024 0 01.02-.207 1.063 1.063 0 01.061-.2 1.051 1.051 0 01.1-.184 1.008 1.008 0 01.132-.161 1.026 1.026 0 01.161-.132 1.114 1.114 0 01.184-.1 1.054 1.054 0 01.613-.061 1.067 1.067 0 01.2.061 1.132 1.132 0 01.184.1 1.065 1.065 0 01.161.132 1.006 1.006 0 01.131.161 1.023 1.023 0 01.1.184 1.073 1.073 0 01.061.2 1.055 1.055 0 01.021.207 1.069 1.069 0 01-.021.208 1.108 1.108 0 01-.061.2 1.041 1.041 0 01-.1.182 1.012 1.012 0 01-.131.162 1.185 1.185 0 01-.161.133 1.134 1.134 0 01-.184.1.979.979 0 01-.2.059 1.034 1.034 0 01-.206.022zm0 0"
      />
      <path
        data-name="Trazado 251"
        d="M43.474 24.461h-1.6v-9.646l.259.19a.866.866 0 001.379-.7V9.563a.867.867 0 00-.354-.7L31.315.163a.867.867 0 00-1.026 0l-5.2 3.823-2.4-1.762a.866.866 0 00-1.026 0l-2.4 1.762-5.2-3.823a.866.866 0 00-1.025 0l-11.843 8.7a.866.866 0 00-.353.7v4.742a.866.866 0 001.379.7l.26-.191v9.646H.866a.866.866 0 00-.866.866v7.516a.866.866 0 00.866.866h42.608a.866.866 0 00.866-.866v-7.516a.866.866 0 00-.866-.865zm-3.337-10.918v10.918h-6.9v-7.586l.26.191.013.01.009.006h.005l.008.005h.006l.009.006.01.006.012.007.03.016.013.006.012.005.013.006a.867.867 0 00.631.024l.044-.017h.041l.013-.006a.866.866 0 00.475-.773v-4.742a.866.866 0 00-.353-.7l-4.754-3.492 1.026-.753zm-16 14.676h-3.93v-5.167h3.927zm.866-6.9h-5.662a.866.866 0 00-.866.866v6.032h-5.644V15.603l9.339-6.86 9.339 6.86v12.616h-5.644v-6.032a.866.866 0 00-.865-.866zm5.8-19.381l10.977 8.064v2.593L31.315 4.907a.867.867 0 00-.952-.048.92.92 0 00-.073.048l-1.98 1.455-1.765-1.3zm-8.628 2.06l10.977 8.064v2.593l-.259-.191-10.205-7.5a.83.83 0 00-.073-.048.869.869 0 00-.952.048l-10.205 7.5-.259.191v-2.593zm-19.606 6l10.977-8.064 4.253 3.124-1.765 1.3-1.976-1.451a.865.865 0 00-1.025 0L2.564 12.598zM4.2 13.544l9.339-6.861 1.026.754-4.754 3.492-.01.007a.866.866 0 00-.332.55v.05a.874.874 0 000 .09v4.742a.866.866 0 00.475.773l.014.007a.868.868 0 00.89-.082l.259-.191v7.586H4.2zm6.9 12.65v2.026H1.732v-2.026zm31.509 5.784H1.732v-2.026h33.515a.866.866 0 100-1.732h-2.006v-2.026h9.367zm0 0"
      />
      <path
        data-name="Trazado 252"
        d="M22.17 20.42a4.593 4.593 0 114.593-4.593 4.6 4.6 0 01-4.593 4.593zm0-7.063a2.47 2.47 0 102.47 2.47 2.473 2.473 0 00-2.47-2.47zm0 0"
      />
    </g>
  </svg>
);

export default SvgComponent;
