import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.p`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.7;
  line-height: 185%;
`;

const Text = ({children, ...other}) => {
  return <Wrapper {...other}>{children}</Wrapper>;
};

export default Text;
