import React, {useRef, useState} from 'react';
import {Modal, Icon, Row, Col, message} from 'antd';
import './modal.css';
import ButtonOutline from '../btn/ButtonOutline';
const MyModal = props => {
  const ref = useRef();
  const [submitMyForm, setSubmitForm] = useState(null);
  const myRef = React.createRef();
  const bindSubmitForm = submitForm => {
    setSubmitForm(submitForm);
  };
  const handleSubmitMyForm = e => {
    if (submitMyForm) {
      submitMyForm.handleSubmit();
    }
  };
  let children = props.children || props.form || props.content;
  const formBind = typeof props.form !== 'undefined';
  if (formBind) {
    let params = {
      setForm: bindSubmitForm,
      onSubmit: props.onSubmit || props.form.onSubmit,
      ref: myRef,
    };
    children = React.cloneElement(props.form, params);
  }
  return (
    <Modal
      ref={ref}
      /* destroyOnClose={true} */
      className={`my-modal ${props.className}` || 'my-modal'}
      closable={props.closable || true}
      title={
        props.title ? (
          <h3>
            {props.icon && (
              <Icon
                type={props.icon || 'schedule'}
                size="large"
                style={{
                  fontSize: '32px',
                  color: '#08c',
                  verticalAlign: 'middle',
                }}
              />
            )}{' '}
            {props.title}
          </h3>
        ) : null
      }
      centered
      cancelButtonProps={{disabled: props.cancelButtonDisabled || false}}
      footer={
        <Row gutter={8} type="flex" justify="end" align="middle">
          <Col>
            <ButtonOutline
              onClick={() => {
                ref.current.handleCancel();
              }}
              icon={<Icon type="close" />}>
              Cancelar
            </ButtonOutline>
          </Col>
          <Col>
            <ButtonOutline
              onClick={() => {
                ref.current.handleOk();
              }}>
              Guardar
            </ButtonOutline>
          </Col>
        </Row>
      }
      okButtonProps={{
        type: 'primary',
        disabled: props.okButtonDisabled || false,
      }}
      visible={props.open}
      onOk={() => {
        if (formBind) return handleSubmitMyForm();
        if (props.onOk) props.onOk();
      }}
      onCancel={() => {
        if (props.onCancel) props.onCancel();
      }}>
      {children}
    </Modal>
  );
};

export default MyModal;
