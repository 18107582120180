import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FFC942"
      d="m24.623 11.839-.866-.523a.773.773 0 0 1-.186-1.17l.668-.768a.77.77 0 0 0-.41-1.256l-.985-.225a.767.767 0 0 1-.536-1.052l.397-.932a.774.774 0 0 0-.78-1.072l-1.012.086a.778.778 0 0 1-.84-.84l.086-1.012a.774.774 0 0 0-1.072-.78l-.932.397a.768.768 0 0 1-1.052-.536l-.225-.985a.77.77 0 0 0-1.256-.41l-.768.668a.774.774 0 0 1-1.17-.186L13.16.377a.769.769 0 0 0-1.322 0l-.523.866a.773.773 0 0 1-1.17.186L9.377.76a.77.77 0 0 0-1.256.41l-.225.985a.767.767 0 0 1-1.052.536l-.932-.397a.774.774 0 0 0-1.072.78l.086 1.012a.778.778 0 0 1-.84.84l-1.005-.092a.775.775 0 0 0-.78 1.071l.396.933a.768.768 0 0 1-.535 1.051l-.986.225a.77.77 0 0 0-.41 1.257l.668.767a.774.774 0 0 1-.185 1.17l-.873.53a.769.769 0 0 0 0 1.322l.866.523c.41.245.503.807.186 1.17l-.668.768a.77.77 0 0 0 .41 1.256l.985.225c.47.106.728.615.536 1.052l-.397.932a.774.774 0 0 0 .78 1.072l1.012-.086c.476-.04.88.357.84.84l-.086 1.012a.774.774 0 0 0 1.072.78l.932-.397a.768.768 0 0 1 1.052.536l.225.985a.77.77 0 0 0 1.256.41l.768-.668a.774.774 0 0 1 1.17.186l.523.866a.769.769 0 0 0 1.322 0l.523-.866a.773.773 0 0 1 1.17-.186l.768.668a.77.77 0 0 0 1.256-.41l.225-.985a.767.767 0 0 1 1.052-.536l.932.397a.774.774 0 0 0 1.072-.78l-.086-1.012a.778.778 0 0 1 .84-.84l1.012.086a.774.774 0 0 0 .78-1.072l-.397-.932a.768.768 0 0 1 .536-1.052l.985-.225a.77.77 0 0 0 .41-1.256l-.668-.768a.774.774 0 0 1 .186-1.17l.866-.523a.769.769 0 0 0 0-1.322ZM12.5 21.349A8.849 8.849 0 0 1 3.65 12.5a8.849 8.849 0 0 1 8.85-8.85 8.849 8.849 0 0 1 8.85 8.85 8.849 8.849 0 0 1-8.85 8.85Z"
    />
    <path
      fill="#FFC942"
      d="m12.884 8.234 1.124 2.282c.06.126.185.212.324.231l2.513.364c.35.053.49.483.238.734l-1.818 1.773c-.1.099-.146.238-.126.377l.43 2.506c.06.35-.311.615-.622.45L12.7 15.767a.421.421 0 0 0-.397 0l-2.249 1.184a.427.427 0 0 1-.622-.45l.43-2.506a.402.402 0 0 0-.126-.377l-1.818-1.773a.432.432 0 0 1 .238-.734l2.513-.364a.436.436 0 0 0 .324-.231l1.124-2.282a.428.428 0 0 1 .768 0Z"
    />
  </svg>
)
export default SvgComponent
