import api from '../utils/Api';

import feathersClient from './feathers-connection';
export const projects_sales_company = feathersClient.service(
  'projects-sales-company',
);
export const projects_zones_interest = feathersClient.service(
  'projects-zones-interest',
);
export const zones_interest = feathersClient.service('zones-interest');
export const projects_common_zones = feathersClient.service(
  'projects-common-zones',
);
export const common_zones = feathersClient.service('common-zones');
export const projects_images = feathersClient.service('projects-images');
export const projects = feathersClient.service('projects');
export const getService = service => feathersClient.service(service);
