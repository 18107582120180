import React from 'react';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';

const CheckboxContainer = styled.label`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #0000001a;
  border-radius: 5px;
  opacity: 1;
  /* padding: 0.9375rem 1.25rem; */
  padding-top: 0.2375rem;
  padding-bottom: 0.2375rem;
  display: flex;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const LabelText = styled.div`
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
`;

const Checkbox = ({className, checked, children, ...props}) => (
  <CheckboxContainer>
    <Radio {...props} />
    <LabelText>{children}</LabelText>
  </CheckboxContainer>
);

export default Checkbox;
