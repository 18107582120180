import React from 'react';

const SvgComponent = props => (
  <svg width={12.6} height={18} {...props}>
    <path
      data-name="Trazado 18"
      d="M6.7.4A6.3 6.3 0 00.4 6.7c0 4.725 6.3 11.7 6.3 11.7S13 11.425 13 6.7A6.3 6.3 0 006.7.4zm0 8.55A2.251 2.251 0 014.45 6.7 2.251 2.251 0 016.7 4.45 2.251 2.251 0 018.95 6.7 2.251 2.251 0 016.7 8.95z"
      fill="none"
      stroke="#000"
      strokeWidth={0.8}
      opacity={0.6}
    />
  </svg>
);

export default SvgComponent;
