import React from 'react';
import styled from 'styled-components';



const Wrapper = styled.img`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: gray;

  object-fit: contain!important;
  background: white!important;
  box-shadow: 3px 3px 3px #cccccc75;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc40;

  margin-left:10px!important;
`;

const Avatar = props => {
  return <Wrapper {...props} />;
};

export default Avatar;
