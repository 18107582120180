import styled from 'styled-components';

export const WrapperEmpty = styled.div`
    display: flex;
    justify-content: center;
`;
export const Wrapper = styled.div`
    & .file-uploader{
        max-width:400px;
        text-align: center;
        margin-top:4px;
    }
    & .s3Button{
        display:none!important;
    }
    & .flat-button-file {
        width: auto!important;
        opacity: 1!important;
        padding: 8px 4px!important;
        margin: 4px!important;
    }
    & .image-placeholder img{
        height: 150px;
    }
    & .image-placeholder{
        margin:4px auto!important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .ant-layout-content{
        /* background:#d7d7d7de!important; */
        padding:10px;
    }
    & .ant-card-body {
        padding: 8px!important;
    }
    & .empty-item .ant-card{
        border: 2px dashed #ccc;
        height: 250px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;