/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';

import styled from 'styled-components';

import {InputGroup, InputGroupButton, Container} from '@bootstrap-styled/v4';

import {
  connectSearchBox,
  InstantSearch,
  connectMenu,
} from 'react-instantsearch-dom';

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

import Splitting from 'splitting';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import LensIcon from '../../components/icons/LensIcon';
import {searchClient} from '../../config';
import {createURL, searchStateToUrl} from '../../modules/utils';
import {navigate} from '@reach/router';
import {getService} from '../../services/services';
import {message} from 'antd';
import {URL_S3, DEFAULT_COVER} from '../../constants';
import {isMobile} from 'react-device-detect';

const ContentCenter = styled.div`
    display: flex !important;
    /* min-width: 100%; */
    width: 100%;
    justify-content: center;
    align-items: center;
`; 
const Filters = styled.div`
  height: 62px;
  background-color: #fff;
  padding: 0 !important;
  box-shadow: 0px 3px 26px #00000029;
  border-radius: 5px;

  @media(max-width: 1200px){
    height: auto;
  }
`;

const FiltersInputGroup = styled(InputGroup)`
  @media (max-width: 768px) {
    background-color: transparent !important;
    .item-search.css-butsr4-container {
      margin-bottom: 5px !important;
    }
  }
`;

const SearchButton = styled(InputGroupButton)`
  padding-left: 30px !important;
  padding-right: 30px !important;
  z-index: 0 !important;
`;

const Border = styled.div`
  border-right: 1px solid #707070;
  opacity: 0.1;

  @media (max-width: 768px) {
    margin: 0px !important;
    padding: 0px !important;
  }
`;

const NameTextField = ({currentRefinement, refine}) => {
  return (
    <InputField
      className="search-project"
      icon={<LensIcon />}
      grow={2}
      value={currentRefinement}
      maxWidth={333}
      onChange={event => refine(event.currentTarget.value)}
      placeholder="Nombre del proyecto"
    />
  );
};

const AgNameTextField = connectSearchBox(NameTextField);

const MenuSelect = ({
  className,
  items,
  currentRefinement,
  refine,
  placeholder,
}) => {

  //-----
  if(placeholder === 'Estrato'){
    items = items.filter(e => e.label !== 'true');
  }
  

  return (
    <SelectField
      className={className}
      style={{
        zIndex: 999,
        marginTop: 0,
        background: 'red',
        boxShadow: '3px 3px 3px red',
      }}
      placeholder={placeholder}
      options={items}
      value={
        currentRefinement && {
          value: currentRefinement,
          label: currentRefinement,
        }
      }
      onChange={event => refine(event.value)}
    />
  );
};

const AgMenuSelect = connectMenu(MenuSelect);

const HeadlineSearch = props => {
  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ];

  // let backgroundElem = useRef(null);
  let titleElem = useRef(null);
  let filtersElem = useRef(null);
  const [searchState, setSearchState] = useState({});
  const [images, setImages] = useState({});
  const [route, setRoute] = useState('');
  const getImage = field => {
    const service = getService('design');
    service
      .find({query: {view: 'home'}})
      .then(response => {
        setRoute(response.data[0].url);
        if (isMobile) {
          setImages({[field]: response.data[0].path_mobil});
        } else {
          setImages({[field]: response.data[0].path_desktop});
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  useEffect(() => {
    const [results] = Splitting({target: titleElem.current, by: 'lines'});

    getImage('cover', 1);
    return () => {};
  }, []);

  const onSearchStateChange = updatedSearchState => {
    setSearchState(updatedSearchState);
  };

  const goToProjects = () => {
    navigate(searchStateToUrl({pathname: '/proyectos'}, searchState));
  };

  return (
    <ContentCenter>
      <div className="projects-search" style={{ marginTop: '-1rem'}}>
        <InstantSearch
          indexName="projects"
          onSearchStateChange={onSearchStateChange}
          createURL={createURL}
          searchClient={searchClient}>
          <Filters className="projects-search" ref={filtersElem}>
            <FiltersInputGroup className="d-flex">
              <AgNameTextField className="item-search" />
              <Border />
              <AgMenuSelect
                className="item-search"
                placeholder="Ciudad"
                attribute="city.name"
              />
              <Border />
              <AgMenuSelect
                className="item-search"
                placeholder="Tipo de proyecto"
                attribute="general_use"
              />
              <Border />
              <AgMenuSelect
                className="item-search"
                placeholder="Barrio"
                attribute="neighborhood.name"
              />
              <Border />
              <AgMenuSelect
                className="item-search"
                placeholder="Estrato"
                attribute="stratus"
              />
              <SearchButton
                className="item-search"
                color="success"
                onClick={goToProjects}>
                Buscar
              </SearchButton>
            </FiltersInputGroup>
          </Filters>
        </InstantSearch>
      </div>
    </ContentCenter>
  );
};

export default HeadlineSearch;
