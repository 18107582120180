import React from 'react';
import styled from 'styled-components';
// import Women from '../../public/images/women.png';

const Wrapper = styled.div`
  width: 100%;
  height: 350px;

  display: flex;
  justify-content: center;
  align-items: center;


  font-family: sans-serif !important;
`;



const Item = styled.div`
  background: rgba(101, 169, 46, 0.2);
  border-radius: 18px;
  width: 100%;
  max-width: 68.5rem;
  height: 110px;
  display: flex;
  justify-content: end;
  align-items: center;

  .text {
    /* font-family: 'Lato'; */
    /* font-style: italic; */
    font-weight: 600;
    font-size: 32px;
    line-height: 163.5%;
    /* or 52px */
    color: #65a92e;
  }

  .imgWomen {
    position: relative;
    bottom: 3.4rem;
    left: -1rem;
  }

  @media (max-width: 1200px) {
    height: 120px;
    width: 50%;

    padding: 1rem;
    flex-direction: column;

    .text {
      font-size: 25px;
      height: 163.5%;
      text-align: center;
      /* line-height: ; */
    }
    
  .imgWomen {
    position: relative;
    bottom: 1.2rem;
    left: 0.2rem;
    }
  }

  @media (max-width:576px) {
    width: 90%
  }


`;

const ButtonAndImgContent = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 2rem;
    
  .button {
    width: 212px;
    height: 44px;
    left: 807px;
    top: 3253px;

    background: #00438d;
    border-radius: 10px;

    border: none;

    display: flex;
    justify-content: center;
    align-items: center;
  

    padding: 0.9rem;

    .textButton {
      width: 141px;
      height: 16px;
      left: 822px;
      top: 3268px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 103%;
      /* or 16px */
      color: #fff;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
  
    width: 100%;
    justify-content: center;
    padding-right: 0;
  }
`;

const Sprint = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 2rem;

  @media(max-width: 1850px){

  }

  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    order: 3;
    gap: 0;
  }
`;

const ContentText = styled.div`
  display: flex;
  justify-content: center;
  /* padding-left: 12rem; */
  width: 100%;

  @media(max-width: 900px){
     
      padding: 0;
  }
`;

export default function Contact() {
  return (
    <Wrapper>
      <Item>
        <Sprint>
          <ContentText>
            <span className="text">¿Tienes alguna duda?</span>
          </ContentText>
          <ButtonAndImgContent>
            <a href="https://wa.link/5gbj33" target="_blank" className="button" style={{textDecoration:"none"}}>
              
              <span className="textButton">¡Hablemos!</span>
              <svg
                width="31"
                height="30"
                viewBox="0 0 31 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="15.5" cy="15" rx="15.5" ry="15" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.4401 8.47067C19.8492 6.87833 17.7341 6.00071 15.4809 6C10.8378 6 7.0588 9.77825 7.05667 14.4235C7.05596 15.9082 7.44413 17.3574 8.18151 18.6352L6.98584 23L11.4512 21.8284C12.6815 22.4999 14.067 22.8534 15.4766 22.8541H15.4802C20.1226 22.8541 23.9023 19.0751 23.9044 14.4299C23.9058 12.1795 23.0303 10.0623 21.4401 8.47067ZM15.4809 21.431H15.478C14.2215 21.431 12.9897 21.0932 11.9144 20.455L11.6587 20.3034L9.00884 20.9982L9.71647 18.4142L9.55001 18.1493C8.84876 17.0344 8.47901 15.746 8.47971 14.4235C8.48113 10.5631 11.6226 7.42233 15.4844 7.42233C17.3544 7.42233 19.1125 8.15192 20.4343 9.47508C21.756 10.799 22.4835 12.5577 22.4828 14.4285C22.4806 18.2903 19.3399 21.431 15.4809 21.431ZM19.3215 16.1872C19.1111 16.0817 18.0762 15.5724 17.8828 15.5023C17.6902 15.4322 17.5499 15.3967 17.409 15.6071C17.268 15.8175 16.8657 16.2921 16.7424 16.433C16.6199 16.5733 16.4966 16.591 16.2863 16.4855C16.0759 16.3799 15.3973 16.1582 14.5933 15.4407C13.9679 14.8825 13.545 14.1933 13.4225 13.9822C13.2999 13.7711 13.4097 13.6578 13.5145 13.553C13.6095 13.4587 13.7249 13.3072 13.8305 13.1839C13.9367 13.0621 13.9714 12.9743 14.0423 12.8333C14.1124 12.693 14.0777 12.5698 14.0245 12.4643C13.9714 12.3594 13.5507 11.3231 13.3757 10.9017C13.205 10.4908 13.0315 10.5468 12.9018 10.5404C12.7793 10.534 12.639 10.5333 12.4981 10.5333C12.3578 10.5333 12.1298 10.5857 11.9371 10.7968C11.7444 11.0079 11.2004 11.5172 11.2004 12.5528C11.2004 13.5891 11.9548 14.59 12.0596 14.7302C12.1645 14.8705 13.5436 16.9969 15.6551 17.9085C16.1573 18.1252 16.5498 18.2549 16.855 18.3519C17.3594 18.512 17.8184 18.4893 18.181 18.4355C18.5855 18.3753 19.4263 17.9262 19.602 17.4346C19.7776 16.943 19.7776 16.5209 19.7245 16.4337C19.6721 16.3452 19.5318 16.2928 19.3215 16.1872Z"
                  fill="#1E5A9A"
                />
              </svg>
            </a>
          </ButtonAndImgContent>
        </Sprint>
        <img class="imgWomen" src="images/women.png" alt="Contact.svg" />
      </Item>
    </Wrapper>
  );
}
