import React from 'react';
import styled from 'styled-components';
import {FormGroup, Label} from '@bootstrap-styled/v4';
import InputField from './InputField';
import {Field as FormikField} from 'formik';
import ErrorField from './formik/ErrorField';

const Field = styled(InputField)`
  background:  ${props =>
    props.dark
      ? '#fff!important'
      : '#585E673F !important'};
  border: none !important;
  height: 40px;
  max-width: 100%;
  min-width: 100%;
  outline: 0 !important;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 10px !important;
`;

const FieldLabel = styled(Label)`
 
  color: #000 !important;
  text-transform: uppercase;
  font-size: 0.8125rem;
`;

const InputText = ({label, name, hasFormik = false, ...other}) => {
  return (
    <FormGroup className="content_accordion">
      {label && <FieldLabel>{label}</FieldLabel>}
      {hasFormik ? (
        <div>
          <FormikField component={Field} name={name} {...other} />
          <ErrorField name={name} />
        </div>
      ) : (
        <Field {...other} />
      )}
    </FormGroup>
  );
};

export {Field, FieldLabel};
export default InputText;
