import React, {useEffect, useState} from 'react';
import {Container, Row, Col, H1} from '@bootstrap-styled/v4';
import BasicCard from '../components/BasicCard';
import styled from 'styled-components';
// import {moment} from '../utils/Helper';
// import {DatePicker} from '@material-ui/pickers';
// MuiPickersUtilsProvider,
// import DateFnsUtils from '@date-io/date-fns';

import {
  BarChart,
  // Bar,
  // XAxis,
  // YAxis,
  // CartesianGrid,
  // Tooltip,
  // Legend,
} from 'recharts';
import {navigate} from '@reach/router';
import Api from '../services/api';
import BasicCardSkeleton from '../components/skeletons/BasicCardSkeleton';
import AppPagination from '../components/AppPagination';
// import {style} from '@material-ui/system';

/* SEO */
import SeoEngine from '../components/SeoEngine';
import BannerTop from '../components/commons/banner';
import Contact from '../components/Contact';
import HeaderOutSearch from '../components/HeaderOutSearch/HeaderOutSearch';
import StartIcon from '../../src/components/icons/StartIcon';
import BlogNews from '../components/BlogNews/BlogNews';

const NewsContainer = styled(Container)`
  margin-top: 6rem !important;


  /* margin-top: 95px; */
  & .ranking-col {
    padding: 0 2rem !important;
  }

  @media(max-width: 576px){
    /* margin: 1rem !important; */
    margin-top: 100px !important;
  }
`;
const ColRanking = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 1.3rem; */

  @media(max-width: 576px){
    flex-direction: row;
    padding: 1rem;
  }
`;

const MyCol = styled(Col)`
  

  @media(max-width: 576px){
    order: 3 !important;
    margin-bottom: 150px; 
  }
`; 

const Title = styled(H1)`
  font-size: 2.1rem !important;
  margin-bottom: .7rem;
  font-weight: bold;
`;

const Item = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 0.2rem 0;
`;

const List = styled.div`
  padding-bottom: 1.875rem;
`;

const HeadText = styled.h2`
  padding: 5px;

  background: #fff !important;
  width: max-content;
  font-weight: bold;
  font-size: 20px;
  color: #00438d !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

const NumberText = styled.div`
  letter-spacing: 0;
  color: #737373;
  /*  background: #1d203e; */
  background: #f6f6f6;
  font-size: 18px;
  height: 22px;

  width: 13px;
  padding: 4px 12px !important;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
`;
const Text = styled.span`
  letter-spacing: 0;
  color: #00000099;
  font-weight: bold;
  font-size: 11px;
  padding: 0px 5px 0px 5px;
  white-space: pre-wrap;
  overflow: hidden;
  display: inline-block;
  max-width: initial;
  vertical-align: middle;
  text-overflow: ellipsis;
  margin-top: 0.3rem;
  /* margin-bottom: 0.24rem; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ItemList = styled(Item)`
  border-bottom: 0px;
  /* background: #f2f2f2; */
  margin: 0.6rem 0 0.6rem 0;
  padding: 0px;
  display: block;
  overflow: hidden;

  display: flex;
  justify-content: start;
`;

const MAX_LIMIT = 999;
const MIN_LIMIT = 6000000;

const News = () => {
  const data = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
  ];

  const [blogs, setBlogs] = useState({data: []});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [$limit, setLimit] = useState(MAX_LIMIT);

//nuevo
  let otherNews = [...blogs.data].splice(4, blogs.data.length - 1);

  console.log({
    otherNews: otherNews.length,
    blogs : blogs.data.length,
  })
  const [rankings, setRankings] = useState({
    unidades_vendidas: [],
    ventas_nro_meses: [],
  });

  const getRankings = query => {
    let request = Api.companiesRanking
      .find({
        query,
      })
      .subscribe(response => {
        let rankings = {};
        let {data} = response;
        data.reduce((prev, current) => {
          let {type} = current;
          switch (type) {
            case 'Unidades vendidas':
              rankings['unidades_vendidas'] =
                rankings['unidades_vendidas'] || [];
              rankings['unidades_vendidas'].push(current);
              break;

            default:
              rankings['ventas_nro_meses'] = rankings['ventas_nro_meses'] || [];
              rankings['ventas_nro_meses'].push(current);
              break;
          }
          return current;
        }, rankings);
        setRankings(rankings);
      });
    return () => request.unsubscribe();
  };

  const getBlogs = () => {
    setLoading(true);
    let request = Api.blogs
      .find({
        query: {
          $sort: {
            createdAt: -1
          },
          $limit: 10,
          $skip: (page - 1 > 0 ? page - 1 : 0) * 10,
        },
      })
      .subscribe(data => {
        setBlogs(data);
        setLoading(false);
      });
    return () => request.unsubscribe();
  };

  const ListView = props => {
    let {dataSource = []} = props;
    return (
      <div>
        <List>
          {dataSource.map((item, index) => {
            let {position, construction_company} = item;
            return (
              <ItemList key={index}>
                <NumberText>
                  <span>{position}.</span>
                </NumberText>
                {construction_company.name && (
                  <Text>{construction_company.name.toUpperCase()}</Text>
                )}
              </ItemList>
            );
          })}
        </List>
      </div>
    );
  };

  const getData = (type_construction, type) => {
    let filter = rankings[type_construction]
      .filter(item => {
        return item.type_construction == type;
      })
      .filter((item, index) => {
        return index < $limit;
      });
    let sorts = new Array(filter.length);
    filter.reduce((prev, current, index) => {
      sorts[current.position - 1] = current;
      return current;
    }, sorts);
    return sorts;
  };

  useEffect(() => {
    getRankings({
      $limit: MAX_LIMIT,
    });
    getBlogs();

  }, [page]);

  // console.log({data: blogs.total})

  return (
    <div style={{marginTop:'4rem'}}>
      <HeaderOutSearch />
      <BannerTop path={'news'} />

      <BlogNews />

      <NewsContainer >
        <SeoEngine
          title={'Noticias - Construcaribe'}
          description={'Estadísticas, recursos y noticias del sector'}
        />
        <div>
          <Row >
            <MyCol md={9}>
              <Row>
                {loading ? (
                  <React.Fragment>
                    {[1, 2, 3, 4].map(i => (
                      <Col md={4} key={i.toString()}>
                        <BasicCardSkeleton />
                      </Col>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {otherNews.map(item => (
                      <Col md={4} key={item.id.toString()}>
                        <BasicCard
                          item={item}
                          onClick={() => navigate(`noticias/${item.id}`)}
                        />
                      </Col>
                    ))}
                  </React.Fragment>
                )}
              </Row>
              <Row className="justify-content-center">
                <AppPagination
                  page={page}
                  onChangePage={setPage}
                  total={Math.floor(parseFloat(blogs.total)/9)}
                />
              </Row>
            </MyCol>

            {/**division */}
            {/* ----------------------------------------ranking------------------------------------------ */}

            <Col>
              <Row>
                <Title style={{paddingLeft: '1.8rem'}}>Ranking</Title>
                <span style={{paddingLeft: '1.8rem', marginBottom:'0.6rem'}}>
                  Constructoras con mayor número de unidades de viviendas
                  vendidas durante el <strong>último trimestre móvil</strong>{' '}
                  del año actual
                </span>
              </Row>
              <ColRanking>
                <Row>
                  <div className="ranking-col" xs={6} md={12}>
                    <HeadText>
                      <StartIcon />
                      <span>VIS + VIP</span>
                    </HeadText>
                    <ListView dataSource={getData('unidades_vendidas', 'Vis')} />
                  </div>
                </Row>
                <Row>
                  <div className="ranking-col" xs={6} md={12}>
                    <HeadText>
                      <StartIcon />
                      <span>NO VIS</span>
                    </HeadText>
                    <ListView
                      dataSource={getData('unidades_vendidas', 'No vis')}
                    />
                  </div>
                </Row>
              </ColRanking>

            </Col>
          </Row>
        </div>
       
      </NewsContainer>
      <Contact />
    </div>
  );
};

export default News;
