import React from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components';
//import {Button} from '@bootstrap-styled/v4';

import AppButton from './AppButton';

const Wrapper = styled(AppButton)`
  border-width: ${props =>
    props.borderLarge ? '2px!important' : '1px!important'};
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  opacity: 0.6;
  font-weight: bold !important;
  padding: 0.5rem !important;
`;

const ButtonOutline = ({children, ...other}) => {
  return (
    <Wrapper color="link" {...other}>
      {children}
    </Wrapper>
  );
};

ButtonOutline.propTypes = {};

export default ButtonOutline;
