import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Headline from '../Headline';
import BasicCard from '../BasicCard';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import Api from '../../services/api';
import BasicCardSkeleton from '../skeletons/BasicCardSkeleton';
import Slider from 'react-slick';

const BlogWrapper = styled.div`
  background: #f7f7f7 0% 0% no-repeat padding-box;
  padding-top: 4.4375rem;
  padding-bottom: 3.8125rem;

  & .swiper-wrapper {
    padding-bottom: 20px;
  }
`;

const MyTitle = styled.h2`
  
    padding-left: 1.1rem !important;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1.4rem !important;

    font-size: 26px;
    font-weight: 700;

    @media(max-width: 579px){
      padding-left: 0 !important; 
    }

`;

const MyBasicCar = styled(BasicCard)`
  margin: 0 1rem 0 1rem !important;
  width: 400px !important;
  
  .font-weight-bold {
    font-size: 18px !important;
    width: 84% !important;
  }

  .imagen-slider {
    width: 84% !important;
  }

  .card-block{
    width: 84% !important;
  }

  @media (max-width: 992px) {
    margin: 0 !important;
    width: 100% !important;
    .imagen-slider {
      width: 97% !important;
    }
  }
`;

const BlogSwiper = styled(Swiper)`
  & .swiper-wrapper {
    padding-bottom: 1.5rem;
  }
`;

const BlogSlider = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    let request = Api.blogs
      .find({
        query: {
          $sort: {
            createdAt: -1
          },
          $limit: 9,
          $skip: 0,
        },
      })
      .subscribe(({data}) => {
        setBlogs(data);
        setLoading(false);
      });
    return () => request.unsubscribe();
  }, []);

  let settings = {
    dots: false,
    infinite: true,
    speed: 250,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <BlogWrapper className='content-slider'>
      <Container >
        <MyTitle>Blog recientes</MyTitle>
        {loading ? (
          <Row>
            {[1, 2, 3].map(i => (
              <Col md={4} key={i} xs={12}>
                <BasicCardSkeleton />
              </Col>
            ))}
          </Row>
        ) : (
          // {...params}
          <Slider {...settings} className='color-slider'>
            {blogs.map(item => (
              <MyBasicCar item={item} key={item.id.toString()} />
            ))}
          </Slider>
        )}
      </Container>
    </BlogWrapper>
  );
};

export default BlogSlider;
