import React from 'react';
import styled from 'styled-components';
import {
  Input,
  Button,
  Row
} from "antd";
const { Search } = Input;
export const WrapperFilters = styled(Row)`
  margin:0px 4px;
`;
export const SearchBox = styled(Search)`
  /* box-shadow: 3px 3px 3px rgba(0,0,0,0.03) !important */
  border-radius: 20px !important;
  & input {
    border-radius: 20px !important;
  }
`;

export const Wrapper = styled.div`
    margin:10px;
    & .ant-table {
       /*  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03) !important; */
        box-shadow: 0 2px 10px -1px rgba(69,90,100,0.3);
        border: 1px solid #e8e8e85e !important;
        border-radius: 8px !important;
    }
    & .box-head{
      background: var(--color-gray-ligth);
      /* box-shadow: 0px 2px 0px #0000002e; */
      border-radius: 0px 0px 4px 4px;
    }
    & .box-head h2, .box-head i{
      color:#FFF!important;
    }
    ${(box) => {
    if (box)
      return (`
            & .box-head {
              margin-bottom:0px!important;
              border-radius: 0px!important;
            }
            & .ant-table {
              border-radius: 0px 0px 8px 8px !important;
            }
        `);
  }
  }
   
`;

export const RoundedButton = styled(Button)`
    border-radius: 20px !important;
`;