import React from 'react';
import {Row, Col} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';

const Category = styled(Col)`
  color: #8dc63f;
  background: #fafafa 0% 0% no-repeat padding-box;
`;

const Card = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin-bottom: 1.25rem;
  overflow: hidden;
  height: 76px;
`;

const TypeItemsProject = ({type}) => {
  return (
    <Card>
      <Row className="h-100">
        <Category className="font-weight-bold d-flex text-center  align-items-center justify-content-center">
          {type.name}
        </Category>
        <Col className="d-flex align-items-center justify-content-center">
          {type.area_by_type} m2
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <CurrencyFormat
            value={type.last_price_in_thousands}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <div>{type.features.bedrooms} Cuartos</div>
          <div>{type.features.bathrooms} Baños</div>
        </Col>
      </Row>
    </Card>
  );
};

export default TypeItemsProject;
