import React from 'react';

export const URL_AUTHENTICATION = '/authentication';
export const URL_CAMACOL_API = 'https://api.camacolatlantico.org';

export const CEL_PHONE = '315 5775119';
export const WHATSAPP = '573155775119';
export const EMAIL = 'info@camacolatlantico.org';


export const GA_APP_KEY = 'UA-160856182-1';
export const HORARIO = (
  <>
    <h3
      style={{
        color: '#fff',
      }}>
      Horario:
    </h3>{' '}
    Lunes a viernes <strong>8:00 a.m</strong> a <strong>12:00 m</strong>
    <br /> y de <strong>1:00 p.m</strong> a <strong>5:00 p.m</strong>
  </>
);
export const IMAGE_BLANK =
  'https://construcaribe.s3.amazonaws.com/assets/utils/image-not-found/logo-company.png';
