import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row, H2, Container } from '@bootstrap-styled/v4';
import InputText from '../InputText';
// import Banner from '../components/home/Banner';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Api from '../../services/api';
import { addNotification } from '../../actions/notifications';
import { connect } from 'react-redux';
import AppButton from '../AppButton';
import { getService } from '../../services/services';
import { message } from 'antd';
// import { HORARIO, CEL_PHONE, EMAIL } from '../utils/Constants';
// import { URL_S3, DEFAULT_COVER_CONTACT } from '../constants';


const FormTitle = styled.p`
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  font-size: 26px; 
  font-weight: bold;
  text-align: left;
`;

const FormContainer = styled(Form)`
  width: 100%;
  margin: 0 auto;
  padding: 1.4rem;
  padding-bottom: 6.0625rem;


  @media(max-width: 579px) {
     padding: 1rem;
  }

`;

const ContainerBox = styled(Container)`
  box-sizing: border-box;
  padding: 0 !important;
`;

const MessageInput = styled(InputText)`
  height: 137px;
`;

const schema = yup.object().shape({
  name: yup
    .string()
    .max(40, 'Nombre es demasiado largo')
    .required('Nombre es requerido.'),
  text: yup
    .string()
    .max(100, 'Maximo 100 caracteres')
    .required('Comentario es requerido.'),
  email: yup
    .string()
    .email('Debe ser un E-Mail valido.')
    .required('Correo electronico es requerido.'),
  subject: yup
    .string()
    .max(156, 'Maximo 256 caracteres')
    .required('Asunto es requerido.'),
  phone: yup.string().required('Telefono es requerido.'),
});

const FormContact = ({ addNotification }) => {
  const [loading, setLoading] = React.useState(false);

  const sendMessage = ({ name, text, email, subject, phone }, { resetForm }) => {
    setLoading(true);
    Api.contacts
      .create({
        name,
        email,
        phone,
        subject,
        message: text,
      })
      .subscribe({
        next: resp => {
          addNotification('Mensaje enviado con exito !', 'success');
          setLoading(false);
          resetForm();
        },
        error: err => {
          addNotification('Error inesperado !', 'error');
          setLoading(false);
        },
      });
  };

  return (

    <ContainerBox>
      <Formik
        initialValues={{
          name: '',
          email: '',
          text: '',
          phone: '',
          subject: '',
        }}
        validationSchema={schema}
        onSubmit={sendMessage}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <FormContainer
            onSubmit={handleSubmit}
            onKeyPress={event => {
              event.key === 'Enter' && handleSubmit();
            }}>
            <FormTitle>Envianos un mensaje</FormTitle>
            <Row>
              <Col xs={12} md={12}>
                <InputText name="name" hasFormik placeholder="Nombre" />
              </Col>
              <Col xs={12}>
                <InputText name="phone" hasFormik placeholder="Teléfono" />
              </Col>
              <Col xs={12}>
                <InputText
                  name="email"
                  hasFormik
                  placeholder="Correo electronico"
                />
              </Col>
              <Col md={12}>
                <InputText name="subject" hasFormik placeholder="Asunto" />
              </Col>
              <Col md={12}>
                <MessageInput
                  max={50}
                  name="text"
                  type="textarea"
                  hasFormik
                  rows="20"
                  placeholder="Mensaje"
                />
              </Col>
              <AppButton
                type="submit"
                size="lg"
                loading={loading}
                disabled={isSubmitting}
              >
                Enviar
              </AppButton>
            </Row>
          </FormContainer>
        )}
      />
    </ContainerBox>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (text, type) => {
      dispatch(addNotification(text, type));
    },
  };
};

export default connect(null, mapDispatchToProps)(FormContact);
