import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from '../constants/actionTypes';

let id = 0;

export default (state = {messages: []}, {type, payload}) => {
  switch (type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        messages: [
          ...state.messages,
          {text: payload.text, type: payload.type, id: ++id},
        ],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        messages: state.messages.filter(item => item.id !== payload.id),
      };
    default:
      return state;
  }
};
