import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {Box, Tabs, Tab} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Headline from '../components/account/Headline';
import ProjectDataForm from '../components/projects/ProjectDataForm';
import Typography from '@material-ui/core/Typography';
import Api from '../services/api';
import {navigate} from '@reach/router';
import {message} from 'antd';
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ProjectRequest = () => {
  const [loading, setLoading] = useState(false);
  const [submiting, setSubmiting] = React.useState(false);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    // console.log('Render View!');
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSubmit = data => {
    setSubmiting(true);
    let request = Api.projects.create(data).subscribe(
      data => {
        let {id} = data;
        setSubmiting(false);
        navigate(`/admin/mi-cuenta/proyectos/${id}/editar`);
      },
      error => {
        message.error(error.message);
      },
    );
    return () => request.unsubscribe();
  };

  return (
    <React.Fragment>
      <Headline fullTitle title="Solicitud creación de proyecto" />
      {
        <>
          <ProjectDataForm onSubmit={handleSubmit} />
        </>
      }
    </React.Fragment>
  );
};

export default ProjectRequest;
