import React, { useEffect, useState } from 'react';
import Headline from '../components/Headline';
import { Container, Row, Col, H3 } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import ProjectsCard from '../components/projects/ProjectsCard';
import Banner from '../components/home/Banner';
import ProjectSkeleton from '../components/skeletons/ProjectSkeleton';
import Api from '../services/api';
import NoData from '../components/NoData';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

/* SEO */
import SeoEngine from '../components/SeoEngine';

const ProjectsWrapper = styled(Container)`
  padding-top: 5.4375rem;
  padding-bottom: 5rem;
`;

const Root = styled.div`
  position: relative;
`;

const LineOne = styled.div`
  background-image: url('/images/line-favorites-1.png');
  width: 528px;
  height: 588px;
  position: absolute;
  right: 0;
  top: 0;
`;
const LineTwo = styled.div`
  background-image: url('/images/line-favorites-2.png');
  width: 418px;
  height: 897px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Favorites = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setLoading(true);
    let request = fetchProjects();
    return () => request.unsubscribe();
  }, []);

  const fetchProjects = () => {
    return Api.favoriteProjects.findAll().subscribe(data => {
      setProjects(data);
      setLoading(false);
    });
  };

  const _renderProjects = () => {
    if (!projects.length) {
      return <NoData title="Aun no tienes proyectos favoritos..." />;
    }

    return (
      <React.Fragment>
        <SeoEngine
          title={'Proyectos - Construcaribe'}
          description={
            'Encuentra todos los proyectos nuevos del atlántico en un solo lugar'
          }
        />
        {projects.map(item => (
          <Col md={4}>
            <ProjectsCard
              onChangeFavorite={() => fetchProjects()}
              project={{
                stratus: item.project.stratus,
                price: item.project.types[0].last_price_in_thousands,
                area: item.project.types[0].area_by_type,
                neighborhood: item.project.neighborhood.name,
                city: item.project.city.name,
                name: item.project.name,
                image: item.project.image,
                id: item.project.id,
              }}
              key={item.id}
            />
          </Col>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Root>
      <SeoEngine
        title={'Construcaribe - Favoritos'}
        description={'Mis proyectos favoritos Construcariber'}
      />
      <LineOne />
      <LineTwo />
      <ProjectsWrapper>
        <Headline className="mb-5">Mis favoritos</Headline>
        <Row>
          {loading ? (
            <React.Fragment>
              {[1, 2, 3, 4, 5, 6].map(i => (
                <Col xs={12} md={4} key={i}>
                  <ProjectSkeleton key={i} />
                </Col>
              ))}
            </React.Fragment>
          ) : (
            _renderProjects()
          )}
        </Row>
      </ProjectsWrapper>
      {/* <Banner /> */}
    </Root>
  );
};

export default Favorites;
