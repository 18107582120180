import {
  LOGIN,
  REGISTER,
  SET_AUTHENTICATE,
  SET_CURRENT_USER,
  START_LOADING_USER,
  END_LOADING_USER,
} from '../constants/actionTypes';

const initialState = {
  isAuthenticate: !!localStorage.getItem('feathers-jwt'),
  user: {favorite_projects: []},
  loadingUser: true,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case LOGIN:
    case REGISTER:
    case START_LOADING_USER:
      return {...state, loadingUser: true};
    case END_LOADING_USER:
      return {...state, loadingUser: false};
    case SET_CURRENT_USER:
      return {...state, user: payload};
    case SET_AUTHENTICATE:
      return {...state, isAuthenticate: payload.value};
    default:
      return state;
  }
};
