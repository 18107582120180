import React from 'react';

const SvgComponent = props => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 36.02 35.899" {...props}>
    <path
      data-name="Trazado 247"
      d="M34.059 30.039V2.911a.937.937 0 00-.93-.97h-6.1V.97A.937.937 0 0026.1 0H10.208A1.006 1.006 0 009.2.97v.97H2.931a1.006 1.006 0 00-1.011.97v27.129A3.042 3.042 0 003.295 35.9h29.956a2.978 2.978 0 002.769-3.032 2.945 2.945 0 00-1.961-2.829zm-32.1 2.828a1.093 1.093 0 011.092-1.092h29.957a1.107 1.107 0 01.243 2.183H3.295c-.786 0-1.334-.449-1.334-1.091zm30.158-3.032h-5.092V3.881h5.094zM25.085 1.94v27.895H18.9v-4.973a.97.97 0 10-1.94 0v4.972h-5.823V1.94zM9.2 29.835H3.861V3.881H9.2z"
    />
  </svg>
);

export default SvgComponent;
