import React, {useState, useEffect} from 'react';
import {connectRange} from 'react-instantsearch-dom';
import RangeField from '../RangeField';
import {debounce} from 'lodash/function';

const RangeInput = ({currentRefinement, min, max, refine, ...props}) => {
  const [refinement, setRefinement] = useState({min: 0, max: 0});

  useEffect(() => {
    setRefinement({min, max});
  }, [min, max]);

  const debounceRefine = debounce(changeRefinement => {
    return refine({
      ...currentRefinement,
      ...changeRefinement,
    });
  }, 700);

  return (
    <RangeField
      {...props}
      minValue={refinement.min}
      maxValue={refinement.max}
      onChangeMin={event => {
        setRefinement({...refinement, min: event.currentTarget.value});
        if (event.currentTarget.value >= min) {
          debounceRefine({
            ...currentRefinement,
            min: event.currentTarget.value,
          });
        }
      }}
      onChangeMax={event => {
        setRefinement({...refinement, max: event.currentTarget.value});
        if (event.currentTarget.value <= max) {
          debounceRefine({
            ...currentRefinement,
            max: event.currentTarget.value,
          });
        }
      }}
    />
  );
};

export default connectRange(RangeInput);
