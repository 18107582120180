import React, {useEffect, useState} from 'react';
import {searchClient} from '../../config';
import {Container, Row} from '@bootstrap-styled/v4';
import AgProjectsHits from '../algoliaRefinements/AgProjectsHits';
import {InstantSearch, Configure} from 'react-instantsearch-dom';
import Headline from '../Headline';
import styled from 'styled-components';

const Projects = styled.div`
  background: #f7f7f7 0% 0% no-repeat padding-box;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* background-image: url('/images/line-detail-project.png'); */
  background-color: #fff;
  background-position: top right;

  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const ContainerBox = styled(Container)`
  box-sizing: border-box;
`;

const MoreProjects = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;
  grid-template-areas: '. . .';

  @media (max-width: 980px) {
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    grid-template-areas:
      '. .'
      '. .';
  }

  @media (max-width: 600px) {
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: px px;
    grid-template-areas:
      '.'
      '.'
      '.';
  }
`;

const ProjectInterest = props => {
  const [stratus, setStratus] = useState(null);
  const [city, setCity] = useState(null);
  useEffect(() => {
    if (props.project.stratus) {
      if (props.project.city) setCity(props.project.city.name);
      setStratus(`Estrato ${props.project.stratus}`);
    }
  }, [props.project.city, props.project.stratus]);
  return (
    <Projects>
      <ContainerBox>
        <InstantSearch
          id="more-projects"
          indexName="projects"
          searchClient={searchClient}>
          <p className="mb-2" style={{fontSize: '24px', fontWeight: 600}}>
            También te pueden interesar
          </p>
          <Configure
            facets={['stratus', 'city.name']}
            facetFilters={[`stratus:${stratus}`, `city.name:${city}`]}
            offset={0}
            length={3}
          />
          <MoreProjects>
            <AgProjectsHits />
          </MoreProjects>
        </InstantSearch>
      </ContainerBox>
    </Projects>
  );
};

export default ProjectInterest;
