import React, {Component} from 'react';
import {Row, Col} from '@bootstrap-styled/v4';
import AppButton from '../components/AppButton';
import PhaseForm from '../components/phases/PhaseForm';
import {navigate} from '@reach/router';
import Headline from '../components/account/Headline';
import Table from '../components/Table';
import Modal from '../components/modal/Modal';
import {Icon} from 'antd';
import Phases from '../components/phases/Phases';
import Api from '../services/api';

const dataSource = [
  {
    col: 'col1',
    col2: 'avs',
    col3: 'Esta es la Columna 3',
  },
];
class ProjectFases extends Component {
  state = {
    open_fase: false,
  };

  formRef = React.createRef();
  submitMyForm = null;
  bindSubmitForm = submitForm => {
    this.submitMyForm = submitForm;
  };
  handleSubmitMyForm = e => {
    if (this.submitMyForm) {
      this.submitMyForm.handleSubmit();
    }
  };

  render() {
    let {open_fase} = this.state;
    return (
      <React.Fragment>
        <Row /* className="justify-content-between mb-24" */>
          <Col mb={24}>
            <Headline
              title="Fases"
              action="Crear Fase"
              handleClick={() => {
                this.setState({
                  open_fase: true,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col mb={24}>
            <Phases
              loading={this.props.loading}
              data={this.props.data}
              onSubmit={this.props.onSubmit}
            />
          </Col>
        </Row>
        {
          <Modal
            ref={this.formRef}
            onCancel={() => this.setState({open_fase: false})}
            onOk={() => {
              this.handleSubmitMyForm();
            }}
            title="Crear Nueva Fase"
            open={open_fase}>
            <div>
              <PhaseForm
                setForm={this.bindSubmitForm}
                data={this.props.data}
                onSubmit={(values, actions) => {
                  if (this.props.onSubmit) this.props.onSubmit(values, actions);
                  this.setState({open_fase: false});
                }}
              />
            </div>
          </Modal>
        }
      </React.Fragment>
    );
  }
}

export default ProjectFases;
