import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, SearchBox, Hits} from 'react-instantsearch-dom';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

/* export const searchClient = algoliasearch(
  '0X6V7G3F7X',
  '0d50ed18bc93be8ac7d71c8ecc6fb9c2',
); */
export const searchClient = algoliasearch(
  'BWKSA0TBZ5',
  'fa953eb290e2a7ddf14512023e5e477c',
);
