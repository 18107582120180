import React, {useRef, useState} from 'react';
import {Formik, Form} from 'formik';
import {Box} from '@material-ui/core';
import {Row, Col} from '@bootstrap-styled/v4';
import InputText from '../../components/InputText';
import SelectLabel from '../../components/SelectLabel';
import Select from '../../components/select/Select';
import AppButton from '../../components/AppButton';
import styled from 'styled-components';
import {message} from 'antd';
const uses = [
  {
    value: 'Apartamento',
    label: 'Apartamento',
  },
  {
    value: 'Casa',
    label: 'Casa',
  },
  {
    value: 'Bodega',
    label: 'Bodega',
  },
  {
    value: 'Oficina',
    label: 'Oficina',
  },
  {
    value: 'Habitaciones',
    label: 'Habitaciones',
  },
];
const status = [
  {
    value: 'ABIERTA',
    label: 'Abierta',
  },
  {
    value: 'CERRADA',
    label: 'Cerrada',
  },
];
const purposes = [
  {
    value: 'Venta',
    label: 'Venta',
  },
  {
    value: 'Arrendar',
    label: 'Arrendar',
  },
];

const ButtonPhase = styled(AppButton)`
  max-width: 300px;
  float: right;
`;
const SelectCustom = styled(Select)`
  max-width: 100%;
`;

const PhaseForm = props => {
  let [bindSubmit, setBindSubmit] = useState(props.bindSubmit || false);
  let [readOnly, setReadOnly] = useState(props.readOnly || false);
  let [edit, setEdit] = useState(false);
  let [submitting, setSubmitting] = useState(false);
  const {setForm, onSubmit, ref, data} = props;

  const formRef = useRef();

  return (
    <Formik
      ref={formRef}
      initialValues={data}
      enableReinitialize={true}
      getForm={() => {
        return formRef;
      }}
      /* validationSchema={schema} */
      onSubmit={(data, actions) => {
        /*  let {setSubmitting, setStatus, resetForm} = actions;
        setSubmitting(false);
        setStatus({success: true});
        resetForm(); */
        if (bindSubmit) {
          setReadOnly(!readOnly);
          if (!edit) {
            return setEdit(!edit);
          }
          setEdit(!edit);
          setSubmitting(true);
          if (onSubmit)
            return onSubmit(data, actions).subscribe({
              next: response => {
                setSubmitting(false);
              },
              error: err => {
                message.error(err.message);
                setSubmitting(false);
              },
            });
        }
        if (onSubmit) onSubmit(data, actions);
      }}
      render={({
        isSubmitting,
        setFieldValue,
        values,
        initialValues,
        submitForm,
      }) => {
        if (formRef.current) {
          if (typeof setForm === 'function') setForm(formRef.current);
        }
        return (
          <Form noValidate ref={ref}>
            <Box mt={4} mb={6}>
              <Row>
                <Col xs={12} md={6}>
                  <InputText
                    disabled={readOnly}
                    name="name"
                    hasFormik
                    label="Nombre"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectCustom
                    disabled={readOnly}
                    name="use"
                    hasFormik
                    /* defaultValue={initialValues['use'] || 'Casa'} */
                    label="Uso"
                    dataSource={uses}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectCustom
                    disabled={readOnly}
                    name="status"
                    hasFormik
                    label="Estado"
                    /* defaultValue={initialValues['status'] || 'Abierta'} */
                    dataSource={status}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputText
                    disabled={readOnly}
                    name="number_of_units"
                    hasFormik
                    label="Numero Unidades"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <SelectCustom
                    disabled={readOnly}
                    name="purpose"
                    hasFormik
                    label="Proposito"
                    dataSource={purposes}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {bindSubmit && (
                    <ButtonPhase loading={submitting} type="submit">
                      {!edit ? 'Editar' : 'Guardar'}
                    </ButtonPhase>
                  )}
                </Col>
              </Row>
            </Box>
          </Form>
        );
      }}
    />
  );
};

export default PhaseForm;
