import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTheme} from '@material-ui/styles';
import {H2, Row} from '@bootstrap-styled/v4';

const CloseIconModal = styled(IconButton)`
  position: absolute !important;
  right: 20px !important;
  top: 20px !important;
  z-index: 5 !important;
`;

const DialogRow = styled(Row)`
  min-height: 493px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  /* font-family: 'HK Grotesk' !important; */
`;

const Title = styled(H2)`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.8;
  font-weight: bold;
  font-size: 24px;
  max-width: 272px;
  /* font-family: 'HK Grotesk' !important; */
  margin: 0 auto 2.625rem;
`;

const Wrapper = styled.div`
  padding: 3.75rem 0;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BaseModal = ({
  open,
  onClose,
  maxWidth = 'lg',
  title,
  loading,
  children,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <CloseIconModal onClick={onClose}>
        <CloseIcon />
      </CloseIconModal>
      {loading && <LinearProgress />}
      <Wrapper>
        {title && <Title>{title}</Title>}
        <DialogRow>{children}</DialogRow>
      </Wrapper>
    </Dialog>
  );
};

export default BaseModal;
