import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col, H5 } from '@bootstrap-styled/v4';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import { IMAGE_BLANK } from '../../utils/Constants';
import InnerImageZoom from 'react-inner-image-zoom';
import { isMobile } from 'react-device-detect'
const serverS3 = 'https://construcaribe.s3.amazonaws.com/';

const AppTab = styled(Tab)`
  display: inline-block;
  border-bottom: ${props => (props.selected ? '5px solid' : 'none')};
  border-color: #8dc63f !important;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 0.5375rem 2.1875rem;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  opacity: ${props => (props.selected ? '1' : '0.2')};

  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }
`;

const AppTabList = styled(TabList)`
  border-bottom: 1px solid rgba(170, 170, 170, 0.2) !important;
  padding: 0 !important;
`;

const BluePrints = styled.img`
  width: 100%;
  max-width: 100%;
`;
const ImageZoom = styled(InnerImageZoom)`
  min-width: 100%;
`;

const Wrapper = styled(Container)`
  padding-bottom: 6.25rem;
  
  box-sizing: border-box;

  /* @media(max-width: 579px){
    padding: 1rem;
  } */
`;

const ProjectItems = ({ items = [] }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Wrapper>
      <Tabs
        defaultFocus={true}
        forceRenderTabPanel
        selectedIndex={selectedIndex}
        onSelect={index => setSelectedIndex(index)}>
        <AppTabList>
          {items.map(item => (
            <AppTab key={item.id.toString()}>{item.name}</AppTab>
          ))}
        </AppTabList>

        {items.map(item => (
          <TabPanel key={item.id.toString()}>
            <>
              <Row>

                {item.image_path && <Col md={6}>

                  {
                    isMobile ?
                      <ImageZoom
                        src={
                          item.image_path
                            ? `${serverS3}${item.image_path}`
                            : IMAGE_BLANK
                        }
                        alt="planos"
                      /> :
                      <BluePrints
                        src={
                          item.image_path
                            ? `${serverS3}${item.image_path}`
                            : IMAGE_BLANK
                        }
                      />
                  }
                </Col>}
                <Col md={item.image_path ? 6 : 12} className="d-flex flex-column justify-content-center">
                  <H5 className="font-weight-bold">
                    Desde&nbsp;
                  <CurrencyFormat
                      value={item.last_price_in_thousands}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />

                  </H5>

                  <ul>
                    {typeof item.max_area_by_type !== 'undefined' ? (
                      <li>Área del tipo: {item.max_area_by_type} m2</li>
                    ) : null}
                    {typeof item.features.bedrooms !== 'undefined' ? (
                      <li>N° de Habitaciones: {item.features.bedrooms}</li>
                    ) : null}
                    {typeof item.features.bathrooms !== 'undefined' ? (
                      <li>Nº de Baños: {item.features.bathrooms} </li>
                    ) : null}
                    {typeof item.features.balcony_terrace !== 'undefined' ? (
                      <li>
                        Balcón: {item.features.balcony_terrace ? 'Sí' : 'No'}
                      </li>
                    ) : null}
                    {typeof item.features.additional_office !== 'undefined' && item.features.additional_office > 0 ? (
                      <li>
                        Oficina Adicional: {item.features.additional_office}
                      </li>
                    ) : null}
                    {typeof item.features.attic !== 'undefined' && item.features.attic > 0 ? (
                      <li>
                        Ático: {item.features.attic}
                      </li>
                    ) : null}
                    {typeof item.features.backyard !== 'undefined' && item.features.backyard > 0 ? (
                      <li>
                        Patio: {item.features.backyard}
                      </li>
                    ) : null}
                    {typeof item.features.bathroom_expandable !== 'undefined' && item.features.bathroom_expandable > 0 ? (
                      <li>
                        Baño expandible: {item.features.bathroom_expandable}
                      </li>
                    ) : null}
                    {typeof item.features.clothing_area !== 'undefined' && item.features.clothing_area > 0 ? (
                      <li>
                        Área de ropa: {item.features.clothing_area}
                      </li>
                    ) : null}
                    {typeof item.features.dining_room !== 'undefined' && item.features.dining_room > 0 ? (
                      <li>
                        Comedor: {item.features.dining_room}
                      </li>
                    ) : null}
                    {typeof item.features.fireplace !== 'undefined' && item.features.fireplace > 0 ? (
                      <li>
                        Chimenea: {item.features.fireplace}
                      </li>
                    ) : null}
                    {typeof item.features.kitchen !== 'undefined' && item.features.kitchen > 0 ? (
                      <li>
                        Cocina: {item.features.kitchen}
                      </li>
                    ) : null}
                    {typeof item.features.mezzanine !== 'undefined' && item.features.mezzanine > 0 ? (
                      <li>
                        Mezzanine: {item.features.mezzanine}
                      </li>
                    ) : null}
                    {typeof item.features.service_room_and_bathroom !== 'undefined' && item.features.service_room_and_bathroom > 0 ? (
                      <li>
                        Cuarto y Baño de Servicio: {item.features.service_room_and_bathroom}
                      </li>
                    ) : null}
                    {typeof item.features.study_rooms !== 'undefined' && item.features.study_rooms > 0 ? (
                      <li>
                        Sala de Estudio: {item.features.study_rooms}
                      </li>
                    ) : null}
                    {typeof item.features.warehouse !== 'undefined' && item.features.warehouse > 0 ? (
                      <li>
                        Almacén: {item.features.warehouse}
                      </li>
                    ) : null}
                  </ul>
                </Col>
              </Row>
            </>
          </TabPanel>
        ))
        }
      </Tabs>
    </Wrapper>
  );
};

export default ProjectItems;
