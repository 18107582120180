import {
  projects_sales_company,
  projects_zones_interest,
  zones_interest,
  projects_common_zones,
  common_zones,
  projects_images,
  projects,
} from './services';
import axios from '../utils/Axios';
import {URL_AUTHENTICATION} from '../utils/Constants';

export const get_token_camacol = ({email, password, strategy = 'local'}) =>
  axios.post(URL_AUTHENTICATION, {email, password, strategy});

export const getProjectSales = projects_sales_company.find();
export const getProjectZonesInterest = projects_zones_interest.find();
export const getZonesInterest = (query = {}) => zones_interest.find(query);

export const getProjectCommonZones = (query = {}) =>
  projects_common_zones.find(query);
export const getCommonZones = (query = {}) => common_zones.find(query);
export const createZoneInterst = (params = {}) => zones_interest.create(params);

export const getProjectInfo = (query = {}) => projects.find(query);
export const getProjectImages = (query = {}) => projects_images.find(query);
export const createProjectImages = (params = {}) =>
  projects_images.create(params);
export const updateProjectImages = (id, params = {}) =>
  projects_images.patch(id, params);
export const removeProjectImages = id => projects_images.remove(id);
export const createProjectImage = (params = {}) =>
  zones_interest.create(params);
