import {from, Observable, Subject} from 'rxjs';
import {catchError, filter, map, reduce} from 'rxjs/operators';
import feathersClient from './feathers-connection';

export default class FeathersService {
  static event = new Subject();

  static TYPE_CREATED = 'CREATED';
  static TYPE_UPDATED = 'UPDATED';
  static TYPE_PATCHED = 'UPDATED';
  static TYPE_REMOVED = 'DELETED';

  constructor(serviceName, watch = false) {
    this.serviceName = serviceName.trim();
    this.service = feathersClient.service(this.serviceName);

    const listeningHandler = type => (data, context) =>
      FeathersService.event.next({
        type,
        serviceName,
        data,
        context,
      });

    if (typeof watch === 'boolean' && watch) {
      this.service.on(
        'created',
        listeningHandler(FeathersService.TYPE_CREATED),
      );
      this.service.on(
        'updated',
        listeningHandler(FeathersService.TYPE_UPDATED),
      );
      this.service.on(
        'patched',
        listeningHandler(FeathersService.TYPE_PATCHED),
      );
      this.service.on(
        'removed',
        listeningHandler(FeathersService.TYPE_REMOVED),
      );
    } else if (typeof watch === 'object') {
      if (watch.created)
        this.service.on(
          'created',
          listeningHandler(FeathersService.TYPE_CREATED),
        );
      if (watch.updated)
        this.service.on(
          'updated',
          listeningHandler(FeathersService.TYPE_UPDATED),
        );
      if (watch.patched)
        this.service.on(
          'patched',
          listeningHandler(FeathersService.TYPE_PATCHED),
        );
      if (watch.removed)
        this.service.on(
          'removed',
          listeningHandler(FeathersService.TYPE_REMOVED),
        );
    }
  }

  static getEvent(serviceName, type) {
    return FeathersService.event.pipe(
      filter(it => it.serviceName === serviceName && it.type === type),
    );
  }

  static getEventByService(serviceName) {
    return FeathersService.event.pipe(
      filter(it => it.serviceName === serviceName),
    );
  }

  static getEventByType(type) {
    return FeathersService.event.pipe(filter(it => it.type === type));
  }

  raw(method, ...rest) {
    return from(this.service[method](...rest));
  }

  getEvent(
    type = [
      FeathersService.TYPE_CREATED,
      FeathersService.TYPE_UPDATED,
      FeathersService.TYPE_PATCHED,
      FeathersService.TYPE_REMOVED,
    ],
  ) {
    if (!Array.isArray(type)) {
      type = [type];
    }

    return FeathersService.event.pipe(
      filter(
        it => type.includes(it.type) && it.serviceName === this.serviceName,
      ),
      map(it => ({type: it.type, data: it.data})),
    );
  }

  get(id, query) {
    return this.raw('get', id, query);
  }

  find(query = {}) {
    const add = data => {
      let added = {};

      if (query.paginate !== false && data.skip >= data.limit)
        added.back = () =>
          this.find({
            ...query,
            ...{
              query: {
                ...query.query,
                $limit: data.limit,
                $skip: data.skip - data.limit,
              },
            },
          });
      if (query.paginate !== false && data.skip + data.limit < data.total)
        added.next = () =>
          this.find({
            ...query,
            ...{
              query: {
                ...query.query,
                $limit: data.limit,
                $skip: data.skip + data.limit,
              },
            },
          });

      return added;
    };

    return this.raw('find', query).pipe(map(it => ({...it, ...add(it)})));
  }

  findAll(query = {}) {
    delete query.paginate;

    return new Observable(subscriber => {
      const findLoop = nextQuery =>
        this.raw('find', nextQuery).pipe(
          map(it => {
            subscriber.next(it.data);
            if (it.skip + it.limit < it.total) {
              findLoop({
                ...query,
                ...{
                  query: {
                    ...query.query,
                    $limit: it.limit,
                    $skip: it.skip + it.limit,
                  },
                },
              }).subscribe();
            } else {
              subscriber.complete();
            }
            return it;
          }),
          catchError(error => subscriber.error(error)),
        );

      findLoop(query).subscribe();
    }).pipe(
      reduce((ac, values) => {
        return ac.concat(...values);
      }),
    );
  }

  create(data) {
    return this.raw('create', data);
  }

  update(id, data, query) {
    return this.raw('update', id, data, query);
  }

  patch(id, data, query) {
    return this.raw('patch', id, data, query);
  }

  remove(id, query) {
    return this.raw('remove', id, query);
  }
}
