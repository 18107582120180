import React, {useEffect, useState, useRef} from 'react';
import CRUD from '../components/com/crud/CRUD';
import {
  Col,
  Row,
  message,
  List,
  Button,
  Input,
  InputNumber,
  Slider,
  Modal,
} from 'antd';
import './projects.css';
import {
  getProjectImages,
  createProjectImages,
  updateProjectImages,
  getProjectInfo,
  removeProjectImages,
} from '../services';
import {projects_images, projects} from '../services/services';
import {S3Uploader} from '../components/com/crud/form/fields';
import Form from '../components/com/crud/form/AdvancedForm';
const {confirm} = Modal;
const FormCard = props => {
  let {id, path, priority, project_id, onChange, onRemove} = props;
  let [prioridad, setPrioridad] = useState(priority);
  return (
    <Form
      className="form-card"
      onChange={(key, value) => {
        if (id)
          return updateProjectImages(id, {
            priority,
            project_id,
            path,
            [key]: value,
          })
            .then(response => {
              /*  message.info('Registro actualizado!'); */
              onChange();
            })
            .catch(err => message.error(err.message));
        createProjectImages({
          priority,
          project_id,
          path,
          [key]: value,
        })
          .then(response => {
            /*  message.info('Registro creado!'); */
            onChange();
          })
          .catch(err => message.error(err.message));
      }}
      footer={
        <Row type="flex" justify="end">
          <Col>
            <Button icon="delete" shape="circle" onClick={() => onRemove(id)} />
          </Col>
        </Row>
      }
      className="card-form"
      autoSubmit={false}
      buttonSubmitStyle={{
        width: '100%',
      }}
      style={{
        width: '100%',
      }}>
      <div
        style={{
          width: '100%',
        }}>
        <Input name="id" type="hidden" />
        <S3Uploader
          validations={[{required: true}]}
          flex={1}
          name="path"
          initialValue={path}
          className="square-image card-image logo-container"
          path={`projects-images/${project_id}/images/`}
        />
        <Slider
          validations={[{required: true}]}
          min={1}
          max={100}
          size="large"
          flex={1}
          label={`Prioridad`}
          onChange={(key, value) => {
            prioridad = value;
          }}
          name="priority"
          initialValue={priority}
        />
      </div>
    </Form>
  );
};
const ProjectImages = ({project_id}) => {
  const [images, setImages] = useState([]);
  const getProjectsInfo = () => {
    getProjectInfo({
      project_id,
    })
      .then(response => {
        // console.log(response);
      })
      .catch(err => message.error(err.message));
  };
  const ref = useRef();
  useEffect(() => {
    getProjectImages({
      query: {
        project_id,
      },
    })
      .then(response => {
        // console.log('Data: ', response);
      })
      .catch(err => message.error(err.message));

    return () => {};
  }, []);
  return (
    <Row className="projects-container">
      <Col span={24}>
        <CRUD
          autoLoad={true}
          emptyRecord={true}
          mode="form"
          params={{
            query: {
              id: project_id,
            },
          }}
          service={projects}
          fields={[
            {
              name: 'id',
              type: 'hidden',
              initial: project_id,
            },
            {
              name: 'with_out_data',
              type: 'hidden',
              initial: true,
            },
            {
              name: 'project_id',
              type: 'hidden',
              initial: project_id,
            },
            {
              xtype: 'fileuploader',
              label: 'Logo del Proyecto',
              name: 'path_logo',
              /* className: 'logo-project square-image', */
              path: `projects-images/${project_id}/images/`,
              flex: 1,
            },
            {
              xtype: 'fileuploader',
              name: 'image',
              label: 'Imagen principal',
              className: 'square-image image-principal',
              path: `projects-images/${project_id}/images/`,
            },
            {
              xtype: 'custom',
              renderItem: function(record) {
                return (
                  <Row>
                    <Col>Agregar</Col>
                    <Col>
                      {
                        <CRUD
                          name="Galería de Imágenes"
                          ref={ref}
                          autoSubmit={false}
                          params={{
                            query: {
                              project_id,
                              $sort: {priority: -1},
                              $limit: 3000000,
                            },
                          }}
                          layout="grid"
                          grid={{
                            column: 2,
                            gutter: 8,
                          }}
                          renderItem={({item, crud}) => {
                            return (
                              <List.Item>
                                <FormCard
                                  {...item}
                                  project_id={project_id}
                                  onRemove={id => {
                                    confirm({
                                      title: 'Desea eliminar la imagen?',
                                      content:
                                        'Se eliminará la imagen del proyecto.',
                                      onOk() {
                                        removeProjectImages(id)
                                          .then(response => {
                                            message.info('Registro eliminado');
                                            crud.load();
                                          })
                                          .catch(err =>
                                            message.error(err.message),
                                          );
                                      },
                                      onCancel() {},
                                    });
                                  }}
                                />
                              </List.Item>
                            );
                          }}
                          service={projects_images}
                          mode="field"
                          autoLoad={true}
                          /* renderItemList */
                          fields={[
                            {
                              name: 'project_id',
                              type: 'hidden',
                              initial: project_id,
                            },
                            {
                              xtype: 'fileuploader',
                              name: 'path',
                              initialValue: null,
                              validations: [
                                {
                                  required: true,
                                  message: 'Este campo es requerido',
                                },
                              ],
                              className:
                                'square-image card-image logo-container',
                              path: `projects-images/${project_id}/images/`,
                            },
                            {
                              xtype: 'slider',
                              name: 'priority',
                              label: 'Prioridad',
                              initialValue: 0,
                              validations: [
                                {
                                  required: true,
                                  message: 'Este campo es requerido',
                                },
                              ],
                              placeholder: 'Prioridad',
                              flex: 1,
                            },
                            {
                              xtype: 'custom',
                              flex: 1,
                              style: {
                                textAlign: 'center',
                              },
                              renderItem: () => (
                                <Button
                                  style={{
                                    width: '50%',
                                  }}
                                  className="btn-primary"
                                  size="large"
                                  htmlType="submit"
                                  block
                                  type="primary">
                                  Guardar
                                </Button>
                              ),
                            },
                          ]}
                        />
                      }
                    </Col>
                  </Row>
                );
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default ProjectImages;
