/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import {
  H1,
  InputGroup,
  InputGroupButton,
  Container,
} from '@bootstrap-styled/v4';

import {
  connectSearchBox,
  InstantSearch,
  connectMenu,
} from 'react-instantsearch-dom';

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

import Splitting from 'splitting';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import LensIcon from '../../components/icons/LensIcon';
import { searchClient } from '../../config';
import { createURL, searchStateToUrl } from '../../modules/utils';
import { navigate } from '@reach/router';
import { getService } from '../../services/services';
import { message } from 'antd';
import { URL_S3, DEFAULT_COVER } from '../../constants';
import { isMobile } from 'react-device-detect';
import { he } from 'date-fns/locale';

const Headline = styled.section`
  height:530px; 
  margin-top: 4.5rem;
  position: relative;
`;

const Title = styled(H1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 852px;
  z-index: 1;
  text-transform: uppercase;

  & span {
    backface-visibility: hidden;
    will-change: transform, opacity;
  }

  @media (max-width: 768px) {
    font-size: 1.675rem;
  }
`;

const WrapperBackground = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 414px) {
    /* height:400px!important; */
  }
`;

const Background = styled.div`
  width: 100%;
  /*background-image: url('/images/home.png'); */
  background-color: #f2f2f2;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  object-fit: cover;

  & h1,
  h2,
  h3 {
    color: #fff !important;
  }
`;


const FiltersInputGroup = styled(InputGroup)`

  @media (max-width: 768px) {
    background-color: transparent !important;
    .item-search.css-butsr4-container {
      margin-bottom: 5px !important;
    }
  }
`;

const SearchButton = styled(InputGroupButton)`
  padding-left: 30px !important;
  padding-right: 30px !important;
  z-index: 0 !important;
`;

const Border = styled.div`
  border-right: 1px solid #707070;
  opacity: 0.1;

  @media (max-width: 768px) {
    margin: 0px !important;
    padding: 0px !important;
  }
`;

const NameTextField = ({ currentRefinement, refine }) => {
  return (
    <InputField
      className="search-project"
      icon={<LensIcon />}
      grow={2}
      value={currentRefinement}
      maxWidth={333}
      onChange={event => refine(event.currentTarget.value)}
      placeholder="Nombre del proyecto"
    />
  );
};

const AgNameTextField = connectSearchBox(NameTextField);

const MenuSelect = ({
  className,
  items,
  currentRefinement,
  refine,
  placeholder,
}) => {
  return (
    <SelectField
      className={className}
      style={{
        zIndex: 999,
        marginTop: 0,
        background: 'red',
        boxShadow: '3px 3px 3px red',
      }}
      placeholder={placeholder}
      options={items}
      value={
        currentRefinement && {
          value: currentRefinement,
          label: currentRefinement,
        }
      }
      onChange={event => refine(event.value)}
    />
  );
};

const AgMenuSelect = connectMenu(MenuSelect);

const HeaderOutSearch = props => {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  let backgroundElem = useRef(null);
  let titleElem = useRef(null);
  let filtersElem = useRef(null);
  const [searchState, setSearchState] = useState({});
  const [images, setImages] = useState({});
  const [route, setRoute] = useState('');
  const getImage = field => {
    const service = getService('design');
    service
      .find({ query: { view: 'home' } })
      .then(response => {
        setRoute(response.data[0].url);
        if (isMobile) {
          setImages({ [field]: response.data[0].path_mobil });
        } else {
          setImages({ [field]: response.data[0].path_desktop });
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  useEffect(() => {
    const [results] = Splitting({ target: titleElem.current, by: 'lines' });

    getImage('cover', 1);
    return () => { };
  }, []);

  const onSearchStateChange = updatedSearchState => {
    setSearchState(updatedSearchState);
  };

  const goToProjects = () => {
    navigate(searchStateToUrl({ pathname: '/proyectos' }, searchState));
  };

  return (
      <a className='row' href={route} target="_blank">
        <img src={`${URL_S3}/${images["cover"]}`} />
      </a>
  );
};

export default HeaderOutSearch;
