import React from 'react';
import {Pagination, PaginationItem, PaginationLink} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { connectPagination } from 'react-instantsearch-dom';

const Wrapper = styled(Pagination)`
  & * {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Link = styled(PaginationLink)`
  color: rgba(0, 0, 0, 0.7) !important;
  border: none !important;
`;

const AppPagination = ({page, onChangePage, total}) => {
  
  const handleNext = () => {
    let current = page;
    if (page < total) {
      onChangePage(current + 1);
    }
  };

  const handlePrev = () => {
    
    let current = page;
    if (page > 1) {
      onChangePage(current - 1);
    }
  };

  return (
    <Wrapper>
      <PaginationItem>
        <Link previous onClick={handlePrev} />
      </PaginationItem>
      <PaginationItem>
        <Link>
          Página <span className="font-weight-bold">{page}</span> de
          <span className="font-weight-bold pl-1">{Math.ceil(total) || 1}</span>
        </Link>
      </PaginationItem>
      <PaginationItem>
        <Link next onClick={handleNext} />
      </PaginationItem>
    </Wrapper>
  );
};

export const AlgoliaPagination = connectPagination(({ currentRefinement, nbPages, refine }) => {

  const handleNext = () => {
    if (currentRefinement < nbPages) {
      refine(currentRefinement + 1)
      document.getElementById('wrapper-projects').scrollTo({ top: 0 })
      window.scrollTo({ top: 0 })
    }
  };

  const handlePrev = () => {
    if (currentRefinement > 1) {
      refine(currentRefinement - 1)
      document.getElementById('wrapper-projects').scrollTo({ top: 0 })
      window.scrollTo({ top: 0 })
    }
  };

  return (
    <Wrapper>
      <PaginationItem>
        <Link previous onClick={() => handlePrev()} />
      </PaginationItem>
      <PaginationItem>
        <Link>
          Página <span className="font-weight-bold">{currentRefinement}</span> de
          <span className="font-weight-bold pl-1">{nbPages}</span>
        </Link>
      </PaginationItem>
      <PaginationItem>
        <Link next onClick={() => handleNext()} />
      </PaginationItem>
    </Wrapper>
  );
})

export default AppPagination;
