import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import { getService } from '../../../services/services';
import { message } from 'antd';
import { URL_S3, DEFAULT_COVER } from '../../../constants';
import { isMobile } from 'react-device-detect'

const Headline = styled.section`
    height: 362px;
    margin-bottom:8px;
    position: relative;
`

const WrapperBackground = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
`

const Background = styled.img`
    width: 100%;
    max-height: 360px;
    background-color: #f2f2f2;
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    object-fit: cover;
    & h1, h2,h3{
        color:#FFF!important;
    }
`

const BannerTop = props => {

    const { path, position } = props
    let backgroundElem = useRef(null);

    console.log(path)

    //custom state
    const [images, setImages] = useState({});
    const [route, setRoute] = useState("");

    //function
    const getImage = (field) => {
        const service = getService("design");
        console.log(props)
        service.find({ query: {view: path, position: position}})
        .then(response => {
            console.log(response)
            if (!response.data.length) return
            setRoute(response.data[0].url)
            if( isMobile ){
                setImages({ [field]: response.data[0].path_mobil });
            }
            else {
                setImages({ [field]: response.data[0].path_desktop });   
            }
        })
        .catch(err => {
            message.error(err.message);
        });
    }
    useEffect(() => {
    getImage("cover", 1);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

return (
    images.cover ?
        <Headline>
        <WrapperBackground>
        <a href={route} target="_blank">
        {<Background
            src={images["cover"] ? `${URL_S3}/${images["cover"]}` : DEFAULT_COVER}
            ref={backgroundElem} />}
            </a>
        </WrapperBackground>
    </Headline>
        : null
    );
};

export default BannerTop;
