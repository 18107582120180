import React from 'react';
import styled from 'styled-components';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import {BASE_BUCKET_URL} from '../../constants/global';
import {ProjectImage} from '../com';
import AppButton from "../AppButton";
import { useState, useEffect } from 'react';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'cover gallery';
  height: 740px;
  grid-gap: 5px;

  @media (max-width: 768px) {
    height: 400px;
    /*grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;*/
  }
`;

const GalleryCover = styled.div`
  grid-area: cover;
`;

const GalleryContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-area: gallery;
  width: 100%;
  height: 100%;
  grid-gap: 5px;

`;

const GalleryItem = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const GalleryImage = styled(ProjectImage)`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #f2f2f2;
  object-fit: cover;
`;

const ButtonContact = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
`;

const Gallery = ({cover, gallery = []}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const otherImages = [...gallery].splice(0, 4);
    const allImages = [
        {src: `${BASE_BUCKET_URL}${cover}`, w: 800, h: 800},
        ...gallery.map(item => ({
            src: `${BASE_BUCKET_URL}${item.path}`,
            w: 800,
            h: 800,
        })),
    ];

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => { };
      }, []);

    const openGallery = () => {
        const pswpElement = document.querySelectorAll('.pswp')[0];

        const options = {
            index: 0
        };

        let gallery = new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            allImages,
            options,
        );
        gallery.init();
    };

    return (
        <Wrapper className="gallery-wrapper">
            <GalleryCover md={6} className="py-0">
                <GalleryItem>
                    <GalleryImage
                        onClick={openGallery}
                        size="large"
                        src={cover}
                        alt="imagen del proyecto"
                    />
                    {
                        screenWidth <= 900 && (
                            <ButtonContact>
                            <AppButton onClick={() => {
                                const el = document.getElementById('formcontacto');
                                el.scrollIntoView({ behavior: "smooth" })
                                // window.scrollTo({top: el.offsetTop, behavior: "smooth"});
                            }}>
                                Dejanos tus Datos
                            </AppButton>
                        </ButtonContact>
                        )
                    }
                   
                </GalleryItem>
            </GalleryCover>
            <div>
                <GalleryContent className="gallery-content">
                    {otherImages.map(image => (
                        <GalleryItem xs={6} md={6} key={image.id} onClick={openGallery}>
                            <GalleryImage
                                size="large"
                                src={image.path}
                                alt="imagen del proyecto"
                            />
                        </GalleryItem>
                    ))}
                </GalleryContent>
            </div>
        </Wrapper>
    );
};

export default Gallery;
