import React from 'react';
import HeadlineSearch from '../components/home/HeadlineSearch';
import Banner from '../components/home/Banner';
import HomeMap from '../components/home/HomeMap';
import BlogSlider from '../components/home/BlogSlider';
import MostVisitedProjects from '../components/home/MostVisitedProjects';
import {isMobile} from 'react-device-detect';
import {Col, Row} from 'react-bootstrap'; 
import Contact from '../components/Contact';


/* SEO */
import SeoEngine from '../components/SeoEngine';

const Home = () => {

  return (
    <Col className='content-context'>
        <SeoEngine
        title={'Proyectos - Construcaribe'}
        description={
          'Encuentra todos los proyectos nuevos del atlántico en un solo lugar'
        }
      />
       <Banner/>
      <Row>
        <HeadlineSearch />
      </Row>
      <div className='row-title-home'>
        {/* Cambio de h1 a h3 */}
        <h1 className='row-title-home-text'>Proyectos de Vivienda en Barranquilla</h1>
      </div>
      <div className='wrapper-context'>
        <MostVisitedProjects />
        {!isMobile && <HomeMap />}
        <BlogSlider />
        <Contact/>
      </div>
    </Col>
  );
};

export default Home;
