import React from 'react';
import {H4} from '@bootstrap-styled/v4';
import BluePinIcon from '../icons/BluePinIcon';
import GreenPinIcon from '../icons/GreenPinIcon';
import styled from 'styled-components';

const AreasOfInterest = styled.div`
  padding-top: 2.875rem;
`;

const Title = styled.div`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.7;
  font-size: 20px;
  padding-left: 0.60625rem;
`;

const Time = styled.div`
  letter-spacing: 0;
  color: #000000;
  opacity: 0.2;
  font-size: 20px;
`;

const AreasOfInterestList = ({items = []}) => {
  return (
    <AreasOfInterest>
      <H4 className="font-weight-bold">Zonas de interés</H4>
      {items.map(item => (
        <div
          key={item.id.toString()}
          className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <BluePinIcon />
            <Title>{item.zone_interest.name}</Title>
          </div>
          {/* <Time>a {item.journey_time} min</Time> */}
        </div>
      ))}
    </AreasOfInterest>
  );
};

export default AreasOfInterestList;
