import styled from 'styled-components';
import { Button, H2 } from '@bootstrap-styled/v4';

export const SpacingButton = styled(Button)`
    margin-top: 2.4375rem;
`;
//Aqui un h2 !!!
export const Title = styled.h2`
    letter-spacing: 0;
    color: #000000;
    opacity: 0.8;
    font-weight: bold;
    font-size: 24px;
    max-width: 272px;
    margin-bottom: 2.625rem;
`;