import React from 'react';

const SvgComponent = props => (
  <svg
    width="0.6875rem"
    height="0.9375rem"
    viewBox="0 0 10.824 15.464"
    {...props}>
    <defs>
      <linearGradient
        id="prefix__bluepin"
        x1={0.066}
        x2={1.287}
        y2={1.284}
        gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#0092d0" />
        <stop offset={1} stopColor="#734dff" />
      </linearGradient>
    </defs>
    <path
      data-name="Trazado 147"
      d="M15.412-44A5.413 5.413 0 0010-38.588c0 4.059 5.412 10.051 5.412 10.051s5.412-5.992 5.412-10.051A5.413 5.413 0 0015.412-44zm0 7.837a2.514 2.514 0 01-2.425-2.425 2.549 2.549 0 012.425-2.425 2.561 2.561 0 012.425 2.425 2.613 2.613 0 01-2.425 2.425z"
      transform="translate(-10 44)"
      fill="url(#prefix__bluepin)"
    />
  </svg>
);

export default SvgComponent;
