import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Moment from "moment";
import "moment/locale/es"; // without this line it didn't work
Moment.locale("es");

export const moment = Moment;
export const currency = require("currency-formatter");

export const money = (value, decimalSeparator) => {
  return currency.format(value, {
    code: "USD",
    decimalDigits: 0,
    precision: 0,
    decimalSeparator: "."
    //typeof decimalSeparator !== "undefined" ? decimalSeparator : ","
  });
};

export const decimal = (value, config = {}) => {
  let {
    decimal = ".",
    precision = 2,
    format = "%v %s",
    symbol = "",
    name = ""
  } = config;
  if (Number(value) === 0 || typeof value == "undefined") precision = 0;
  return currency.format(value, {
    symbol,
    decimal,
    precision,
    format // %s is the symbol and %v is the value
  });
};

export const print = id => {
  const el = document.getElementById(id);

  var doc = new jsPDF("p", "pt", "a4", true);
  doc.setFontSize(16);
  doc.setTextColor(80, 77, 78);
  doc.text(15, 2, "should be an image under here");

  html2canvas(el, {
    allowTaint: false,
    logging: true,
    useCORS: true,
    onrendered: function(canvas) {
      /* var imgData = canvas.toDataURL("image/jpeg");

      doc.addImage(imgData, "JPEG", 15, 0, 34, 37);
      console.log(imgData);
      el.append(canvas);
      doc.save("Spec_Sheet.pdf"); */
    }
  }).then(function(canvas) {
    document.body.appendChild(canvas);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 0, 0);
    pdf.save("download.pdf");
  });
};
