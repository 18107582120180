import React, {useEffect, useState} from 'react';
import {Select} from 'antd';
import {Divider, Icon} from 'antd';
import debounce from 'lodash/debounce';
const {Option} = Select;
const SelectField = props => {
  let [initialValue, setInitialValue] = useState(props.initialValue);
  const [initialized, setInitialized] = useState(false);
  let {
    onSearch = value => console.log(value),
    onChange,
    placeholder,
    style,
    dataSource = [],
    displayField = 'name',
    valueField = 'id',
    label,
    name,
    mode,
    size = 'large',
    initial,
    loading = false,
    allowClear = true,
    allowSelectAll = false,
    refresh = false,
    selectAllText = 'Seleccionar Todos',
    defaultSelectValue = 'all',
    textRefresh,
  } = props;
  const handleOnChange = debounce(onChange, 500);
  const handleOnSearch = debounce(onSearch, 500);

  const Search = (input, option) => {
    let children = option.props.children || '';
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const handleFilter = debounce(Search, 500);

  const onRefresh = () => {
    if (props.onRefresh) props.onRefresh();
  };
  useEffect(() => {
    setInitialized(dataSource.length > 0);
    if (initialized) {
      //console.log("Initialized: ", dataSource.length > 100);
    }
    return () => {};
  });
  return (
    <Select
      name={name}
      size={size}
      allowClear={allowClear}
      mode={mode}
      style={style || {width: 200}}
      showSearch
      label={label}
      loading={loading || !initialized}
      placeholder={placeholder}
      defaultValue={initialValue || initial}
      optionFilterProp="children"
      onSearch={handleOnSearch}
      onChange={handleOnChange}
      dropdownRender={menu =>
        refresh && (
          <div>
            {menu}
            <Divider style={{margin: '4px 0'}} />
            <div
              style={{padding: '4px 8px', cursor: 'pointer'}}
              onMouseDown={e => e.preventDefault()}
              onClick={onRefresh}>
              <Icon type="sync" />
              {textRefresh ? textRefresh : 'Cargar más...'}
            </div>
          </div>
        )
      }
      filterOption={(input, option) => {
        let children = option.props.children || '';
        if (typeof children !== 'string') {
          children = String(children);
        }
        return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}>
      {allowSelectAll && (
        <Option key={-1} value={defaultSelectValue}>
          {selectAllText}
        </Option>
      )}
      {dataSource.map((item, index) => (
        <Option key={index} value={item[valueField]}>
          {item[displayField]}
        </Option>
      ))}
    </Select>
  );
};

export default SelectField;
