import React, {useState, useEffect} from 'react';
//ES6
import ReactMapboxGl, {
  Layer,
  Feature,
  GeoJSONLayer,
  Marker,
  Cluster,
} from 'react-mapbox-gl';
// ES5
import styled from 'styled-components';

const themes = require('./styles.json');
const geojson = require('./geojson.json');
const Mark = styled.div`
  background-color: #e74c3c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 4px solid #eaa29b;
`;

const clusterMarker = coordinates => (
  <Marker coordinates={coordinates} /* style={styles.clusterMarker} */>
    C
  </Marker>
);
const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1Ijoib2tpbG9jbzIiLCJhIjoiY2o2eHRuZW9kMWtzajM0cGc5YzJzZDdtMyJ9.CUKmVGnNcpmjsTD0R3ov2A',
});

const PUBLIC_TOKEN =
  'pk.eyJ1Ijoib2tpbG9jbzIiLCJhIjoiY2o2eHRuZW9kMWtzajM0cGc5YzJzZDdtMyJ9.CUKmVGnNcpmjsTD0R3ov2A';

const mapStyle = {
  width: '100%',
  height: '100%',
};
const symbolLayout = {
  'text-field': '{place}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0.6],
  'text-anchor': 'top',
};
const circlePaint = {
  'circle-color': 'red',
};
const circleLayout = {visibility: 'visible'};

/* Map */
const MapBox = props => {
  /*  lat: 10.97591637
  lng: -74.7881658 */
  let {type = 'simple', data = []} = props;
  let {onClick, onMarkClick} = props;
  const [center, setCenter] = useState([-74.7881658, 10.97591637]);
  const [initialized, setInitialized] = useState(false);
  const onStyleLoad = map => {
    const {onStyleLoad} = props;
    return onStyleLoad && onStyleLoad(map);
  };

  useEffect(() => {
    if (data.length > 0 && !initialized) {
      let item = data[0];
      let {lng, lat} = item._geoloc;
      if (lng) setCenter([lng, lat]);
      setInitialized(true);
    }
    return () => {};
  });
  return (
    <div
      style={
        props.style || {
          height: '100vh',
          width: '100%',
        }
      }>
      <Map
        style={themes.basic}
        center={center}
        onClick={onClick}
        /* zoom={[props.zoom || 100]}
        minZoom={0}
        maxZoom={120} */
        containerStyle={mapStyle}
        onStyleLoad={onStyleLoad}>
        {type === 'geojson' && (
          <GeoJSONLayer
            circleLayout={circleLayout}
            circlePaint={circlePaint}
            symbolLayout={symbolLayout}
            circleOnClick={onMarkClick}
            data={geojson}
          />
        )}
        {
          <>
            {data.map((item, index) => {
              let {lng, lat} = item._geoloc;
              return (
                <Marker
                  key={index}
                  coordinates={[lng, lat]}
                  onClick={e => onMarkClick(item)}>
                  {props.renderMarker ? (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}>
                      {props.renderMarker(item, index)}
                    </div>
                  ) : (
                    <Mark />
                  )}
                </Marker>
              );
            })}
          </>
        }
      </Map>
    </div>
  );
};
export default MapBox;
