import React from 'react';
import { Col } from '@bootstrap-styled/v4';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import ProjectSkeleton from '../skeletons/ProjectSkeleton';
import ProjectsCard from '../projects/ProjectsCard';

const StateResults = ({ searching }) => {
  return (
    searching && (
      <React.Fragment>
        {[1, 2, 3, 4, 5, 6, 7, 9].map(i => (
          <Col xs={12} sm={6} md={4} key={i}>
            <ProjectSkeleton />
          </Col>
        ))}
      </React.Fragment>
    )
  );
};

const ProjectStateResults = connectStateResults(StateResults);
const DEFAULT_IMAGE = 'assets/utils/image-not-found/logo-company.png'; //assets/utils/image-not-found/image%20%288%29.png';
const Hits = ({ hits = [], favoriteProjects }) => {
  return (
    <React.Fragment>
      <ProjectStateResults />
      {hits.map(hit => {

      if (hit.status === "Activo") {
          {/*console.log({hit})*/}
         return  (
            <div key={hit.objectID}>
              {
                <ProjectsCard
                  project={{
                    stratus: hit.stratus,
                    price: hit.min_last_price_in_thousands || 0,
                    area: hit.min_area_by_type,
                    neighborhood: hit.neighborhood ? hit.neighborhood.name : '',
                    city: typeof hit.city !== 'undefined' ? hit.city.name : '',
                    name: hit.name,
                    image: hit.image != null ? hit.image : DEFAULT_IMAGE,
                    slug: hit.title_slug,
                    id: hit.id,
                  }}
                  compactBlock
                />
              }
            </div>
          )
        }
      }
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    favoriteProjects: state.auth.user.favorite_projects,
    isAuthenticate: state.auth.isAuthenticate,
  };
};

export default connect(mapStateToProps, null)(connectHits(Hits));
