import React from 'react';
import Select from 'react-select';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'capitalize',
  }),
  indicatorSeparator: () => ({}),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 15,
    fontWeight: 'lighter',
  }),
};

const SelectField = props => {
  // console.log({values: {...props}})
  return <Select styles={customStyles} {...props} />;
};

export default SelectField;
