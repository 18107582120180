import React, {useState, useEffect} from 'react';
import {Thead, Tr, Th, Tbody, Td} from '@bootstrap-styled/v4';
import styled from 'styled-components';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import BaseTable from '../account/BaseTable';
import {navigate} from '@reach/router';
import {BASE_BUCKET_URL} from '../../constants/global';
import {Table, Pagination} from 'antd';
import {IMAGE_BLANK} from '../../utils/Constants';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Foto',
    dataIndex: 'image',
    key: 'image',
    render: value => (
      <Image
        src={value ? `${BASE_BUCKET_URL}${value}` : IMAGE_BLANK}
        alt="proyecto"
      />
    ),
  },
  {
    title: 'Codigo',
    dataIndex: 'ref_project_code',
    key: 'ref_project_code',
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Editar',
    key: 'action',
    render: (text, record) => (
      <IconButton
        onClick={() =>
          (window.location.href = `/admin/mi-cuenta/proyectos/${record.id}/editar`)
        }
        aria-label="edit">
        <CreateIcon />
      </IconButton>
    ),
  },
];
const TdImage = styled(Td)``;

const Image = styled.img`
  width: 90px;
  min-width: 90px;
  height: 90px;
  min-height: 90px;
  object-fit: cover;
  background-color: #f2f2f2;
`;
const Mytable = styled(Table)`
  th {
    color: #8dc63f !important;
  }
  .ant-table-content {
    color: #8dc63f !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08);
  }
`;
const config = {
  pagination: {
    pageSizeOptions: ['30', '40'],
    showSizeChanger: true,
  },
};
const CustomPaginator = styled(Pagination)``;
const ProjectsTable = ({
  total,
  pagination = {},
  onChange,
  projects = [],
  ...props
}) => {
  const [current, setCurrent] = useState(props.current);
  return (
    <>
      <Mytable
        
        bordered={false}
        size="small"
        columns={columns}
        onChange={page => {
          onChange(page);
          setCurrent(page);
        }}
        defaultCurrent={1}
        pagination={pagination}
        dataSource={projects}
      />
    </>
  );
};

export default ProjectsTable;
