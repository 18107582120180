import React from 'react';

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 12.684 12.684" {...props}>
    <defs>
      <linearGradient
        id="prefix__a"
        x2={1}
        y2={0.785}
        gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#8dc63f" />
        <stop offset={1} stopColor="#2ab47f" />
      </linearGradient>
    </defs>
    <g data-name="Grupo 5">
      <path
        data-name="Trazado 4"
        d="M-2.965-14.082a3.887 3.887 0 013.883-3.883A3.887 3.887 0 014.8-14.082 3.887 3.887 0 01.918-10.2a3.887 3.887 0 01-3.883-3.883M8.684-7.414L4.9-11.2a4.892 4.892 0 00.938-2.882A4.918 4.918 0 00.918-19 4.918 4.918 0 00-4-14.082 4.918 4.918 0 00.918-9.163 4.89 4.89 0 003.8-10.1l3.786 3.784z"
        transform="translate(4 19)"
        fill="url(#prefix__a)"
      />
    </g>
  </svg>
);

export default SvgComponent;
