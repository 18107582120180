import React, {useEffect, useState} from 'react';
import ProjectsTable from '../components/account/ProjectsTable';
import Headline from '../components/account/Headline';
import Api from '../services/api';
import {navigate} from '@reach/router';
import {getService} from '../services/services';

const MyProjectsAccount = () => {
  const [projects, setProjects] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const onChange = paginator => {
    let {current, pageSize} = paginator;

    //alert(">> " + pageSize + ":" + current)
    setPagination({
      $limit: pageSize,
      $skip: (current - 1) * pageSize,
    });
    getData({
      $limit: pageSize,
      $skip: (current - 1) * pageSize,
    });
  };
  const getData = async paginator => {
    paginator = paginator || pagination;
    setLoading(true);
    const service = getService('projects');
    return service
      .find({
        query: {
          $client: {
            my_projects: 'true',
          },
          ...paginator,
        },
      })
      .then(response => {
        let {data, ...pagination} = response;
        setPagination(pagination);
        setProjects(data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    getData(
      getData({
        $limit: 10,
        $skip: 0,
      }),
    );
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Headline
        title="Proyectos"
        action="Solicitar creación de proyecto"
        handleClick={() => navigate('/admin/mi-cuenta/proyectos/solicitar')}
      />
      <ProjectsTable
        onChange={onChange}
        loading={loading}
        projects={projects}
        pagination={pagination}
      />
    </React.Fragment>
  );
};

export default MyProjectsAccount;
